import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { wsEvents } from "../../../constants";
import { on } from "../../../constants/events";
import { useAppContext } from "../../../context/AuthContext";
import { Lecture, Notification, RemoteSocket, SignalSocket } from "../../../controller";
import LectureDto from "../../../interfaces/lecture/LectureDto";
import EnumSignal from "../../../utils/enum/EnumSignal";
import Routes from "../../../utils/Routes";
import UserRole from "../../../utils/UserRole";
import { EVENT_UPDATE_NOTIFICATIONS, useEffectOnce } from "../../../utils/Utils";

interface HeaderServiceType {
  anchorEl: null | HTMLElement;
  openNotificationMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeNotificationMenu: (event?: React.MouseEvent<HTMLElement>) => void;
  liveUnreadNotifications: number;
  handleTabRoute: (tab: number) => void;
  openFeedback: boolean;
  handleOpenFeedback: () => void;
  handleCloseFeedback: () => void;
  liveLecture: LectureDto | undefined;
  handleLiveLecture: any;
}

export default function HeaderService(): HeaderServiceType {
  const { selectedCourse } = useAppContext();
  const history = useHistory();
  const [liveUnreadNotifications, setLiveUnreadNotifications] = useState<number>(0);
  const [liveLecture, setLiveLecture] = useState<LectureDto>();
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const refLiveUnreadNotifications = useRef<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isTeacher } = UserRole();

  useEffectOnce(() => {
    if (selectedCourse) {
      getAllNotifications();
      on(EVENT_UPDATE_NOTIFICATIONS, getAllNotifications);
    }
    SignalSocket.connect();
    SignalSocket.on("connected", (data: any) => console.log(data));
    SignalSocket.on(wsEvents.recv.SIGNAL, handleSignal);
    SignalSocket.on(wsEvents.recv.NOTIFICATION, handleNotification);

    return () => {
      SignalSocket.off("connected", (data: any) => console.log(data));
      SignalSocket.off(wsEvents.recv.SIGNAL, handleSignal);
      SignalSocket.off(wsEvents.recv.NOTIFICATION, handleNotification);
      RemoteSocket.disconnect();
      SignalSocket.disconnect();
    };
  });

  useEffect(() => {
    if (selectedCourse) {
      getLiveLectureAttending(selectedCourse.id);
    } else {
      setLiveLecture(undefined);
    }
  }, [selectedCourse]);

  const updateSocketChange = (newValue: any) => {
    setLiveUnreadNotifications(newValue);
    refLiveUnreadNotifications.current = newValue;
  };

  const getAllNotifications = () => {
    Notification.getAllNotAcknowledged()
      .then(({ data }) => {
        updateSocketChange(data.data.length);
      })
      .catch((error) => console.log(error));
  };

  const getLiveLectureAttending = (courseId: number) => {
    Lecture.getLiveLectureAttending(courseId)
      .then(({ data }) => {
        setLiveLecture(data.data);
      })
      .catch((error) => console.log(error));
  };

  const handleLiveLecture = () => {
    const lectureNum = liveLecture?.lectureNum;
    history.push({
      pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
      state: { lectureNum: lectureNum },
    });
  };

  const handleSignal = (signal: any) => {
    if (EnumSignal.LECTURE_ENDED === signal) {
      setLiveLecture(undefined);
      RemoteSocket.disconnect();
    }
    if (EnumSignal.LECTURE_STARTED === signal && selectedCourse) {
      getLiveLectureAttending(selectedCourse.id);
    }
  };

  const handleNotification = () => updateSocketChange(refLiveUnreadNotifications.current + 1);

  const openNotificationMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeNotificationMenu = (event?: React.MouseEvent<HTMLElement>) => setAnchorEl(null);

  const handleTabRoute = (tab: number) => {
    closeNotificationMenu();
    history.push({
      pathname: isTeacher ? Routes.TEACHER_NOTIFICATIONS : Routes.STUDENT_NOTIFICATIONS,
      state: { tab: tab },
    });
  };

  const handleOpenFeedback = () => setOpenFeedback(true);
  const handleCloseFeedback = () => setOpenFeedback(false);

  return {
    liveUnreadNotifications,
    openNotificationMenu,
    closeNotificationMenu,
    handleTabRoute,
    anchorEl,
    openFeedback,
    handleOpenFeedback,
    handleCloseFeedback,
    liveLecture,
    handleLiveLecture,
  };
}
export type { HeaderServiceType };
