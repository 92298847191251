import { Grid } from "@mui/material";

export default function FreeLayout({ children }: any) {
  return (
    <div className="free-layout">
      <Grid container justifyContent="center" alignItems="flex-start" className="free-layout-main">
        {children}
      </Grid>
    </div>
  );
}
