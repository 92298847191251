import SvgIcon from "@mui/material/SvgIcon";

const AnonymousRedCheekIcon = ({ className = "" }: any) => (
  <SvgIcon viewBox="-10 0 80 80" xmlns="http://www.w3.org/2000/svg" className={className}>
    <title>Statistics</title>
    <g>
      <rect x="-1" y="-1" width="62.99" height="78.46" id="canvas_background" fill="none" />
    </g>
    <g>
      <path
        style={{ fill: "white" }}
        d="m61,11.41a3.15,3.15 0 0 0 -1.51,-3a49.29,49.29 0 0 0 -22.34,-8a57.3,57.3 0 0 0 -6.65,-0.41a57.17,57.17 0 0 0 -6.64,0.41a49.29,49.29 0 0 0 -22.35,8a3.15,3.15 0 0 0 -1.51,3c0.06,9.33 0.1,18.66 0,28a28.18,28.18 0 0 0 5.17,17c6.42,9.11 15.06,15.1 24.49,19.92a1.69,1.69 0 0 0 0.83,0.15a1.71,1.71 0 0 0 0.83,-0.15c9.44,-4.84 18.08,-10.83 24.5,-19.94a28.24,28.24 0 0 0 5.18,-16.99c-0.11,-9.33 -0.07,-18.66 0,-27.99zm-53.46,12.31c0.78,-3 3.33,-4.91 6.84,-5.34a11.71,11.71 0 0 1 11,4.31c1.05,1.23 1.05,2.41 -0.07,3.13s-2.55,0.39 -3.73,-0.84s-4.72,-5.38 -10.28,-2.98c-1.66,0.75 -3.21,1.36 -3.76,1.72zm16.54,7.68c-3.65,4.45 -9.29,4.39 -12.52,0c2.96,-4.52 9.12,-4.56 12.52,0zm9.88,35a9.12,9.12 0 0 0 -0.66,1.22c-0.15,0.4 -0.3,0.8 -0.48,1.21l-0.57,1.21a25.23,25.23 0 0 1 -1.4,2.43l-0.7,0a23.29,23.29 0 0 1 -1.4,-2.43l-0.58,-1.21c-0.17,-0.41 -0.33,-0.81 -0.47,-1.21a8.2,8.2 0 0 0 -0.7,-1.18a1.22,1.22 0 0 1 0,-1.21a3.74,3.74 0 0 1 1,-1.23a7.44,7.44 0 0 1 0.86,-0.61l0.59,-0.3l0.29,-0.15l0.37,-0.15l0.7,0l0.36,0.15l0.3,0.15l0.58,0.3a7.63,7.63 0 0 1 0.95,0.61a3.74,3.74 0 0 1 1,1.21a1.19,1.19 0 0 1 0,1.23l-0.04,-0.04zm2.63,-8.07c-2.79,-0.14 -5.31,-0.57 -6.09,-2c-0.78,1.46 -3.3,1.89 -6.1,2c-9.93,0.47 -12.16,-8.53 -13.3,-11.08c1.35,1 10.65,7.76 18.83,1.89a0.89,0.89 0 0 1 0.37,-0.14a0.63,0.63 0 0 1 0.2,0a0.61,0.61 0 0 1 0.19,0a0.93,0.93 0 0 1 0.38,0.17c8.17,5.87 17.47,-0.88 18.83,-1.89c-1.14,2.56 -3.38,11.56 -13.31,11.09l0,-0.04zm0.32,-27c3.41,-4.56 9.57,-4.52 12.52,0c-3.22,4.46 -8.86,4.52 -12.52,0.07l0,-0.07zm12.79,-9.4c-5.57,-2.42 -9.05,1.65 -10.32,3s-2.54,1.61 -3.73,0.84s-1.11,-1.9 -0.06,-3.13a11.7,11.7 0 0 1 11,-4.31c3.51,0.43 6.07,2.38 6.84,5.34c-0.52,-0.31 -2.07,-0.92 -3.73,-1.67l0,-0.07z"
        id="svg_1"
      />
      <ellipse stroke="#ff0000" opacity="0.6" fill="#ff0000" strokeWidth="1.5" cx="8.466107" cy="41.069673" id="svg_4" rx="4.130421" ry="4.302521" />
      <ellipse stroke="#ff0000" opacity="0.6" fill="#ff0000" strokeWidth="1.5" cx="52.179837" cy="41.241774" id="svg_2" rx="4.130421" ry="4.302521" />
    </g>
  </SvgIcon>
);
export default AnonymousRedCheekIcon;
