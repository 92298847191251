const PSEUDONYMS = {
  text: "Pseudonyms issued in this lecture",
  tooltipText: ` This is the number of students present in the class. Please check if
  the number of students matches the number of pseudonyms. If it
  doesn't, please click the RESET button.`,
};
const ACC_LECTURE = {
  text: "New accounts issued in this lecture",
  tooltipText: `This is the number of AnonIDs issued in this lecture. The new AnonIDs
  should be issued only to students that have not attended any of the
  previous lectures in this course. If the number of the new students
  doesn't match the number of new accounts, please click the RESET
  button.`,
};
const ACC_COURSE = {
  text: "New accounts issued in this course",
  tooltipText: `This is the number of AnonIDs issued in all the lectures of this
  course. Before allowing new accounts, please check if any student has
  already received an AnonID in any of the previous lectures. If they
  did, they should use it to register to this course.`,
};
const REG_STUDENTS = {
  text: "Registered students in this course",
  tooltipText: `This is the number of students whose AnonID is registered for this
  course. Note that a student can receive an AnonID in any course and
  use it to register for this course. Before allowing new accounts,
  please check if any student has already received an AnonID.`,
};

export { PSEUDONYMS, ACC_LECTURE, ACC_COURSE, REG_STUDENTS };
