import { Button, Grid } from "@mui/material";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import Colors from "../../../utils/Colors";

type MoreLessTextProps = {
  text: string;
  color?: string;
};

function MoreLessText({ text, color = Colors.BLUE_DIANNE }: MoreLessTextProps) {
  const [clamped, setClamped] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(true);

  const handleClick = () => setClamped(!clamped);
  const containerRef: any = useRef(null);

  useEffect(() => {
    const hasClamping = (el: any) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  const textComponent = (
    <div ref={containerRef} style={{ color: color }} className={`${clamped ? "long-text clamp" : "long-text"}`}>
      {text}
    </div>
  );

  return (
    <Grid item xs={12} zeroMinWidth className="more-less-text" mt="4px">
      {!showButton && textComponent}
      {showButton && (
        <Button className="button-text" variant="text" onClick={handleClick}>
          {textComponent}
        </Button>
      )}
    </Grid>
  );
}

export default MoreLessText;
