import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { wsEvents } from "../../../../../constants";
import { trigger } from "../../../../../constants/events";
import { useAppContext } from "../../../../../context/AuthContext";
import { Lecture, WaitingRoomSocket } from "../../../../../controller";
import ConfirmFunctionAndParametersType from "../../../../../interfaces/dialog/ConfirmFunctionAndParametersType";
import DialogYesNoDataType from "../../../../../interfaces/dialog/DialogYesNoDataType";
import MessageType from "../../../../../utils/MessageType";
import { EVENT_UPDATE_CLASS_INFO } from "../../../../../utils/Utils";

interface ClassWaitingRoomServiceType {
  stateWaitingRoom: StateWaitingRoomType;
  handleAdmitAccountsClick: () => void;
  handleRejectAccountsClick: () => void;
  handleAdmitPseudonymsClick: () => void;
  handleRejectPseudonymsClick: () => void;
  handleOpenResetDialog: any;
  warningRejectActionFunc: any;
  warningConfirmActionFunc: ConfirmFunctionAndParametersType | undefined;
  showWarningDialog: DialogYesNoDataType;
  setShowWarningDialog: (val: DialogYesNoDataType) => void;
  resetConfirmActionFunc: ConfirmFunctionAndParametersType | undefined;
  showResetDialog: DialogYesNoDataType;
  setShowResetDialog: (val: DialogYesNoDataType) => void;
  showStartLectureDialog: DialogYesNoDataType;
  setShowStartLectureDialog: (val: DialogYesNoDataType) => void;
}

interface StateWaitingRoomType {
  newConnected: number;
  notRegisteredConnected: number;
  registeredConnected: number;
}
interface StateWaitingRoomPropsType {
  lectureNum?: number;
  newConnectionWarning?: boolean;
  closeWarningStartLecture?: () => void;
}

const initState = { newConnected: 0, notRegisteredConnected: 0, registeredConnected: 0 } as StateWaitingRoomType;

export default function ClassWaitingRoomService({ lectureNum, newConnectionWarning, closeWarningStartLecture }: StateWaitingRoomPropsType): ClassWaitingRoomServiceType {
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse.id;
  const [stateWaitingRoom, setStateWaitingRoom] = useState<StateWaitingRoomType>(initState);
  const refStateWaitingRoom = useRef<StateWaitingRoomType>(initState);

  const dialogInit = { open: false, title: "", content: "" } as DialogYesNoDataType;
  const [showWarningDialog, setShowWarningDialog] = useState<DialogYesNoDataType>(dialogInit);
  const [warningConfirmActionFunc, setWarningConfirmActionFunc] = useState<ConfirmFunctionAndParametersType>();
  const [warningRejectActionFunc, setWarningRejectActionFunc] = useState<ConfirmFunctionAndParametersType>();
  const [showResetDialog, setShowResetDialog] = useState<DialogYesNoDataType>(dialogInit);
  const [resetConfirmActionFunc, setResetConfirmActionFunc] = useState<ConfirmFunctionAndParametersType>();
  const [showStartLectureDialog, setShowStartLectureDialog] = useState<DialogYesNoDataType>(dialogInit);

  useEffect(() => {
    if (lectureNum) {
      WaitingRoomSocket.connect({ query: { courseId, lectureNum } });

      // Check for data that wasn't catched
      triggerTotalsReceived();

      // connecting to the socket events
      // TODO handle errors
      WaitingRoomSocket.on(wsEvents.recv.ERROR_ACCESS_DENIED, handleAccessDenied);
      WaitingRoomSocket.on(wsEvents.recv.ERROR_INVALID_LECTURE_DETAILS, handleInvalidLectureDetails);
      WaitingRoomSocket.on(wsEvents.recv.CONNECTED_TO_WAITING_ROOM, handleConnectedToWaitingRoom);

      // students connecting to the waiting room
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_NEW_CONNECTED, handleNewConnected);
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_NEW_DISCONNECTED, handleNewDisconnected);
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_NOT_REGISTERED_CONNECTED, handleNotRegisteredConnected);
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_NOT_REGISTERED_DISCONNECTED, handleNotRegisteredDisconnected);
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_REGISTERED_CONNECTED, handleRegisteredConnected);
      WaitingRoomSocket.on(wsEvents.recv.WAITING_ROOM_REGISTERED_DISCONNECTED, handleRegisteredDisconnected);

      WaitingRoomSocket.on(wsEvents.recv.TOTALS_RECEIVED, handleTotalsReceived);

      WaitingRoomSocket.on(wsEvents.recv.NON_REGISTERED_STUDENTS_ADMITTED, handleNonRegisteredStudentsAdmitted);
      WaitingRoomSocket.on(wsEvents.recv.REGISTERED_STUDENTS_ADMITTED, handleRegisteredStudentsAdmitted);

      // handle events after kicking
      WaitingRoomSocket.on(wsEvents.recv.KICKED_NEW_STUDENTS, handleKickedNewStudents);
      WaitingRoomSocket.on(wsEvents.recv.KICKED_NON_REGISTERED_STUDENTS, handleKickedNonRegisteredStudents);
      WaitingRoomSocket.on(wsEvents.recv.KICKED_REGISTERED_STUDENTS, handleKickedRegisteredStudents);
    }

    return () => {
      WaitingRoomSocket.disconnect();
      WaitingRoomSocket.off(wsEvents.recv.ERROR_ACCESS_DENIED, handleAccessDenied);
      WaitingRoomSocket.off(wsEvents.recv.ERROR_INVALID_LECTURE_DETAILS, handleInvalidLectureDetails);
      WaitingRoomSocket.off(wsEvents.recv.CONNECTED_TO_WAITING_ROOM, handleConnectedToWaitingRoom);

      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_NEW_CONNECTED, handleNewConnected);
      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_NEW_DISCONNECTED, handleNewDisconnected);
      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_NOT_REGISTERED_CONNECTED, handleNotRegisteredConnected);
      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_NOT_REGISTERED_DISCONNECTED, handleNotRegisteredDisconnected);
      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_REGISTERED_CONNECTED, handleRegisteredConnected);
      WaitingRoomSocket.off(wsEvents.recv.WAITING_ROOM_REGISTERED_DISCONNECTED, handleRegisteredDisconnected);

      WaitingRoomSocket.off(wsEvents.recv.NON_REGISTERED_STUDENTS_ADMITTED, handleNonRegisteredStudentsAdmitted);
      WaitingRoomSocket.off(wsEvents.recv.REGISTERED_STUDENTS_ADMITTED, handleRegisteredStudentsAdmitted);

      // events after kicking
      WaitingRoomSocket.off(wsEvents.recv.KICKED_NEW_STUDENTS, handleKickedNewStudents);
      WaitingRoomSocket.off(wsEvents.recv.KICKED_NON_REGISTERED_STUDENTS, handleKickedNonRegisteredStudents);
      WaitingRoomSocket.off(wsEvents.recv.KICKED_REGISTERED_STUDENTS, handleKickedRegisteredStudents);

      WaitingRoomSocket.off(wsEvents.recv.TOTALS_RECEIVED, handleTotalsReceived);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureNum]);

  useEffect(() => {
    if (newConnectionWarning) {
      const showWarning = checkStudentsInWaitingRoom();
      if (showWarning) {
        setShowWarningDialog({ title: "Waiting Room Update! There are some new accounts and pseudonyms not admitted yet.", content: <Typography>Do you want to accept them?</Typography>, open: true });
        setWarningConfirmActionFunc({
          function: () => {
            handleAdmitAccountsClick();
            handleAdmitPseudonymsClick();
            closeWarningStartLecture?.();
          },
        });
        setWarningRejectActionFunc({
          function: () => {
            handleRejectAccountsClick();
            handleRejectPseudonymsClick();
            closeWarningStartLecture?.();
          },
        });
      } else {
        closeWarningStartLecture?.();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConnectionWarning]);

  const handleAccessDenied = () => console.log("access denied");
  const handleInvalidLectureDetails = () => console.log("invalid lecture details");
  const handleConnectedToWaitingRoom = () => console.log("connected to waiting room");
  const handleNewConnected = (data: any) => triggerTotalsReceived();
  const handleNewDisconnected = (data: any) => triggerTotalsReceived();
  const handleNotRegisteredConnected = (data: any) => triggerTotalsReceived();
  const handleNotRegisteredDisconnected = (data: any) => triggerTotalsReceived();
  const handleRegisteredConnected = (data: any) => triggerTotalsReceived();
  const handleRegisteredDisconnected = (data: any) => triggerTotalsReceived();

  // Do nothing, students will get disconnected from the waiting room
  const handleAdmitAccountsClick = () => {
    WaitingRoomSocket.emit(wsEvents.send.ADMIT_NEW_STUDENTS);
    WaitingRoomSocket.emit(wsEvents.send.ADMIT_NON_REGISTERED_STUDENTS);
  };
  const handleAdmitPseudonymsClick = () => {
    WaitingRoomSocket.emit(wsEvents.send.ADMIT_REGISTERED_STUDENTS);
  };
  const handleRejectAccountsClick = () => {
    WaitingRoomSocket.emit(wsEvents.send.KICK_NEW_STUDENTS);
    WaitingRoomSocket.emit(wsEvents.send.KICK_NON_REGISTERED_STUDENTS);
  };
  const handleRejectPseudonymsClick = () => {
    WaitingRoomSocket.emit(wsEvents.send.KICK_REGISTERED_STUDENTS);
  };
  const handleNonRegisteredStudentsAdmitted = () => triggerTotalsReceived();
  const handleRegisteredStudentsAdmitted = () => triggerTotalsReceived();
  const handleKickedNewStudents = () => triggerTotalsReceived();
  const handleKickedNonRegisteredStudents = () => triggerTotalsReceived();
  const handleKickedRegisteredStudents = () => triggerTotalsReceived();

  const handleDelete = async (deleteType: any) => {
    try {
      if (deleteType === "account") {
        // TODO: Delete account
        await Lecture.resetNonRegistered(courseId, lectureNum);
        await Lecture.resetNewAccounts(courseId, lectureNum);
      } else {
        // TODO: Delete pseudo
        await Lecture.resetPseudonyms(courseId, lectureNum);
      }
      triggerTotalsReceived();
    } catch (err: any) {
      showMessage(err.response?.data, MessageType.ERROR);
    }
    // Emit the event updateClassInfo to the root
    // ClassInfo component is listening for this event
    // to update itself
    trigger(EVENT_UPDATE_CLASS_INFO);
  };

  const checkStudentsInWaitingRoom = () => {
    return refStateWaitingRoom.current.newConnected + refStateWaitingRoom.current.notRegisteredConnected + refStateWaitingRoom.current.registeredConnected > 0;
  };

  const triggerTotalsReceived = () => {
    // TODO: Implement, doesn't seem to work right now
    WaitingRoomSocket.emit(wsEvents.send.GET_CURRENT_TOTALS, null);
  };

  const handleTotalsReceived = (data: any) => {
    const { noReg, reg } = data;
    const params = { newConnected: data.new, notRegisteredConnected: noReg, registeredConnected: reg };
    setStateWaitingRoom({ ...stateWaitingRoom, ...params });
    refStateWaitingRoom.current = { ...refStateWaitingRoom.current, ...params };
  };

  const handleOpenResetDialog = (type: string) => {
    if (type === "account") {
      setShowResetDialog({ title: "Are you sure that you want to delete all created accounts in this lecture?", open: true });
      setResetConfirmActionFunc({ function: handleDelete, parameters: type });
    } else {
      setShowResetDialog({ title: "Are you sure that you want to delete all created pseudonyms in this lecture?", open: true });
      setResetConfirmActionFunc({ function: handleDelete, parameters: type });
    }
  };

  return {
    stateWaitingRoom,
    handleAdmitAccountsClick,
    handleRejectAccountsClick,
    handleAdmitPseudonymsClick,
    handleRejectPseudonymsClick,
    handleOpenResetDialog,
    showResetDialog,
    resetConfirmActionFunc,
    showStartLectureDialog,
    setShowStartLectureDialog,
    setShowResetDialog,
    warningConfirmActionFunc,
    warningRejectActionFunc,
    showWarningDialog,
    setShowWarningDialog,
  };
}
export type { ClassWaitingRoomServiceType };
