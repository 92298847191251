import Colors from "../../../utils/Colors";

const FFIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.0333" height="50.0333" viewBox="0 0 63.233 63.233">
    <defs>
      <linearGradient id="linear-gradient-ff" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#a17fff" />
        <stop offset="1" stopColor={Colors.PINK_REACT} />
      </linearGradient>
    </defs>
    <g id="Group_121" data-name="Group 121" transform="translate(-2909.585 -5279.519)">
      <g id="Group_120" data-name="Group 120">
        <path id="Path_73" d="M2972.818 5311.136a31.617 31.617 0 1 1-31.617-31.617 31.617 31.617 0 0 1 31.617 31.617z" style={{ fill: "url(#linear-gradient-ff)" }} data-name="Path 73" />
      </g>
      <path
        id="Path_74"
        d="M2958.442 5310.086l-15.2-8.777a.994.994 0 0 0-1.491.86v6.526l-12.792-7.386a.994.994 0 0 0-1.491.86v17.554a.994.994 0 0 0 1.491.86l12.792-7.385v6.525a.994.994 0 0 0 1.491.86l15.2-8.776a.994.994 0 0 0 0-1.721z"
        style={{ fill: "none", stroke: "#231f20", strokeMiterlimit: 10, strokeWidth: "1.888px" }}
        data-name="Path 74"
      />
    </g>
  </svg>
);
export default FFIcon;
