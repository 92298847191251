import { SvgIcon } from "@mui/material";
import Colors from "../../../../utils/Colors";

const LiveIconOn = ({ fill = Colors.WHITE }) => (
  <SvgIcon version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18 18">
    <path
      fill={fill}
      d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S13.9,0,9,0z M4.7,11.2H1.3l0.9-5.4h1.1l-0.8,4.5h2.3L4.7,11.2z M6.5,11.2H5.4
	l0.9-5.4h1.1L6.5,11.2z M8.9,11.2l-1-5.4h1.2l0.5,4l1.8-4h1.3L10,11.2H8.9z M16.1,8l-0.2,0.9h-2.1l-0.3,1.4h2.4l-0.2,0.9h-3.5
	l0.9-5.4h3.5l-0.2,0.9H14L13.8,8H16.1z"
    />
  </SvgIcon>
);

export default LiveIconOn;
