import { mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline } from "@mdi/js";
import { Grid, IconButton, MenuItem, TextField, styled } from "@mui/material";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import NotificationType from "../../../../interfaces/notification/NotificationType";
import Colors from "../../../../utils/Colors";
import UserRole from "../../../../utils/UserRole";
import EnumNotificationTab from "../../../../utils/enum/EnumNotificationTab";
import { AntTab, AntTabs, a11yProps } from "../../../shared/my-tabs-custom/MyTabsCustom";
import { TabPanel } from "../../../shared/my-tabs-custom/MyTabsCustomNotification";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import NotificationList from "./NotificationList";
import NotificationsService from "./NotificationsService";

interface CustomSelectPropsNew {
  componentColor?: string;
}
interface TabListType {
  index: number;
  label: string;
  data: NotificationType[];
}

const arrayNotForwardProp: Array<string> = ["componentColor"];
const CustomSelectInput = styled(TextField, {
  shouldForwardProp: (prop) => !arrayNotForwardProp.includes(prop.toString()),
})<CustomSelectPropsNew>(({ componentColor = Colors.FILTER_BLUE }) => ({
  "& .MuiInputBase-input": {
    color: componentColor,
    backgroundColor: "transparent",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
  },
  "& .MuiInputLabel-root ": {
    color: componentColor,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: componentColor,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: componentColor,
    },
    "&:hover fieldset": {
      borderColor: componentColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: componentColor,
    },
  },
  "& .MuiSelect-icon": {
    color: componentColor,
  },
}));

export default function Notifications() {
  const { allNotifications, acknowledgeById, unreadNotifications, acknowledgeAllByEvents, tabValue, handleTabChange, visibleReadAll, handleNotificationClick } = NotificationsService();
  const { questionsUnread, answerUnread, discussionsUnread, flaggedQUnread, flaggedAUnread } = unreadNotifications;
  const { questions, answers, discussions, flaggedQ, flaggedA } = allNotifications;
  const { isMobile } = useQueryMedia();
  const { isStudent } = UserRole();

  const custonSelectInputStyle = {
    "& label": {
      fontSize: "0.8rem",
    },
  };

  const valueStyle = (val: number) => (val ? `(${val})` : "");

  const tabList: TabListType[] = [
    { index: EnumNotificationTab.QUESTIONS, label: "Questions", data: questions },
    { index: EnumNotificationTab.ANSWERS, label: "Answers", data: answers },
    { index: EnumNotificationTab.DISCUSSIONS, label: "Discussions", data: discussions },
  ];

  if (isStudent) {
    tabList.push({ index: EnumNotificationTab.FLAGGED_Q, label: "Flagged Questions", data: flaggedQ });
    tabList.push({ index: EnumNotificationTab.FLAGGED_A, label: "Flagged Answers", data: flaggedA });
  }

  const renderTabsWidth = isStudent ? 20 : 33.33;

  return (
    <Grid container paddingY={1}>
      <Grid item xs={12} paddingBottom={1}>
        <Grid container alignItems="center">
          <Grid item xs={isMobile ? 11 : 11.6}>
            {isMobile ? (
              <>
                <CustomSelectInput
                  sx={custonSelectInputStyle}
                  id="simple-select"
                  size="small"
                  select
                  fullWidth
                  label="Notification group"
                  value={tabValue}
                  onChange={(e: any) => handleTabChange(e, e.target.value)}
                >
                  {tabList.map(({ label, index }: TabListType) => (
                    <MenuItem sx={{ fontSize: "0.75rem" }} key={index} value={index}>
                      {label}
                    </MenuItem>
                  ))}
                </CustomSelectInput>
              </>
            ) : (
              <>
                <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant tabs" TabIndicatorProps={{ style: { display: "none" } }}>
                  <AntTab label={`Questions ${valueStyle(questionsUnread)}`} {...a11yProps(EnumNotificationTab.QUESTIONS)} width={renderTabsWidth} sx={{ fontSize: "0.76rem" }} />
                  <AntTab label={`Answers ${valueStyle(answerUnread)}`} {...a11yProps(EnumNotificationTab.ANSWERS)} width={renderTabsWidth} sx={{ fontSize: "0.76rem" }} />
                  <AntTab label={`Discussions ${valueStyle(discussionsUnread)}`} {...a11yProps(EnumNotificationTab.DISCUSSIONS)} width={renderTabsWidth} sx={{ fontSize: "0.76rem" }} />
                  {isStudent && <AntTab label={`Flagged Q ${valueStyle(flaggedQUnread)}`} {...a11yProps(EnumNotificationTab.FLAGGED_Q)} width={renderTabsWidth} sx={{ fontSize: "0.76rem" }} />}
                  {isStudent && <AntTab label={`Flagged A ${valueStyle(flaggedAUnread)}`} {...a11yProps(EnumNotificationTab.FLAGGED_A)} width={renderTabsWidth} sx={{ fontSize: "0.76rem" }} />}
                </AntTabs>
              </>
            )}
          </Grid>
          <Grid item xs={0.4}>
            <IconButton sx={{ color: `${Colors.WHITE} !important` }} onClick={acknowledgeAllByEvents} disabled={!visibleReadAll}>
              <SvgIconWrapper icon={visibleReadAll ? mdiCheckboxBlankOutline : mdiCheckboxMarkedOutline} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: "0 auto" }} justifyContent="center">
        {tabList.map(({ index, data }: TabListType) => (
          <TabPanel key={index} value={tabValue} index={index}>
            <NotificationList notificationList={data} acknowledgeById={acknowledgeById} handleNotificationClick={handleNotificationClick} />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
