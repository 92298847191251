import { Grid, Typography } from "@mui/material";
import React from "react";
import OutlinedInputCustom from "../../../../shared/outlined-input-custom/OutlinedInputCustom";
import TooltipStyled from "../../../../shared/tooltip/TooltipStyled";
import { ACC_COURSE, ACC_LECTURE, PSEUDONYMS, REG_STUDENTS } from "../config";
import ClassInfoService, { ClassInfoServiceExport } from "./ClassInfoService";

interface ClassInfoDataProps {
  value: number;
  tooltipText: string;
  text: string;
}

export default function ClassInfo({ lectureNum }: { lectureNum: number }) {
  const { classInfoObj }: ClassInfoServiceExport = ClassInfoService({ lectureNum });
  const classInfoData: ClassInfoDataProps[] = [
    { value: classInfoObj.pseudonyms, ...PSEUDONYMS },
    { value: classInfoObj.accountsInLecture, ...ACC_LECTURE },
    { value: classInfoObj.accountsInCourse, ...ACC_COURSE },
    { value: classInfoObj.registeredStudents, ...REG_STUDENTS },
  ];

  return (
    <Grid container rowSpacing={4.5}>
      {classInfoData.map(({ text, value, tooltipText }: ClassInfoDataProps, i: number) => (
        <React.Fragment key={i}>
          <Grid item xs={10}>
            <TooltipStyled tooltipText={tooltipText}>
              <Typography sx={{ cursor: "pointer" }} style={{ textAlign: "left" }} variant="subtitle2">
                &#8226; {text}
              </Typography>
            </TooltipStyled>
          </Grid>
          <Grid item xs={2}>
            <OutlinedInputCustom className="text-field" inputProps={{ style: { textAlign: "center" } }} readOnly={true} value={value} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
