const Labels = {
  ENGLISH: "English",
  USERNAME: "Username",
  ANON_CLASS: "Anon class",
  HELP: "Help",
  SUPLIER_QUESTIONS: "Suplier questions",
  GENERIC_QUESTIONS: "Generic questions",
  ROLE_ADMIN: "Admin",
  ROLE_ADVANCE: "Advance",
  ROLE_BASIC: "Basic",
  SUCCESSFULLY: "Successfully",
  USERS: "Users",
  PROFILE: "Profile",
  LOGOUT: "Logout",
  LOGIN: "LOGIN",
  PASSWORD: "Password",
  CANCEL: "Cancel",
  SAVE: "Save",
  USERNAME_REQUIRED: "Username is required",
  ROLE_REQUIRED: "Role is required",
  SUCCESSFULLY_ADDED: "Successfully added",
  SUCCESSFULLY_DELETED: "Successfully deleted",
  SUCCESSFULLY_UPDATED: "Successfully updated",
};
export default Labels;
