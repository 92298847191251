import { useState } from "react";
import { useHistory } from "react-router-dom";
import { trigger } from "../../../../constants/events";
import { useAppContext } from "../../../../context/AuthContext";
import { Answer, Lecture, Question } from "../../../../controller";
import useAckNotificationDelay from "../../../../hooks/useAckNotificationDelay";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import QuestionType from "../../../../interfaces/question/QuestionType";
import { VoteType } from "../../../../interfaces/Types";
import EnumNotificationEvent from "../../../../utils/enum/EnumNotificationEvent";
import EnumPropName from "../../../../utils/enum/EnumPropName";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import LectureNumParams from "../../../../utils/LectureNumParams";
import MessageType from "../../../../utils/MessageType";
import QuestionIdParams from "../../../../utils/QuestionIdParams";
import Routes from "../../../../utils/Routes";
import UserRole from "../../../../utils/UserRole";
import { EVENT_UPDATE_USER_INFO, updateStateHelper, useEffectOnce } from "../../../../utils/Utils";

const questionInit: any = {
  pseudonym: "",
  createdAt: "",
  selfPosted: true,
  selfFlagged: false,
  text: "",
  numUpVotes: 0,
  numDownVotes: 0,
  respondable: true,
  flagged: false,
  removedFromClass: false,
  teacherQuestion: false,
};

interface LoadingType {
  voteUp: boolean;
  voteDown: boolean;
  flag: boolean;
}

interface QuestionAnswersQuestionServiceProps {
  setQuestionPosted: any;
  locationState: any;
}
interface QuestionAnswersQuestionServiceExport {
  question: QuestionType;
  loading: LoadingType;
  voteQuestion: (vote: VoteType) => void;
  toggleFlag: () => void;
  handleGoBack: () => void;
  handleSendAnswer: (teacherAsStudent: boolean) => void;
  answerInput: string;
  setAnswerInput: (val: string) => void;
  disabledInput: boolean;
}
export default function QuestionAnswersQuestionService({ setQuestionPosted, locationState }: QuestionAnswersQuestionServiceProps): QuestionAnswersQuestionServiceExport {
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse?.id;
  const lectureNum = LectureNumParams();
  const questionId = QuestionIdParams();

  const [question, setQuestion] = useState<QuestionType>(questionInit);
  const history = useHistory();
  const { isTeacher } = UserRole();
  const [answerInput, setAnswerInput] = useState<string>("");
  const [disabledInput, setDisabledInput] = useState<boolean>(false);

  const [loading, setLoading] = useState<LoadingType>({ voteUp: false, voteDown: false, flag: false });
  const { acknowledgeNotificationDelay } = useAckNotificationDelay();

  useEffectOnce(() => {
    fetchQuestion();
    fetchLecture();
    return () => {
      if (history.action === "POP") {
        handleGoBack();
      }
    };
  });

  const fetchLecture = () => {
    Lecture.getLecture(courseId, lectureNum)
      .then((lecture: LectureDto) => setDisabledInput(!lecture.allowQuestionsDuringLecture))
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const fetchQuestion = () => {
    Question.getQuestion(courseId, lectureNum, questionId)
      .then((res: any) => {
        const questionData: QuestionType = res.data.data;
        setQuestion(questionData);
        acknowledgeNotificationDelay([EnumNotificationEvent.QUESTION_POSTED], [questionData.id], () => {
          const temp = { ...questionData };
          if (temp.notificationId) {
            temp.notificationId = undefined;
          }
          setQuestion(temp);
          trigger(EVENT_UPDATE_USER_INFO, { courseId, lectureNum });
        });
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const callbackUpdateState = (propName: string, res: any) => {
    if (propName && res) {
      const questionToUpdate: QuestionType = { ...question };
      const questionUpdated = updateStateHelper(questionToUpdate, propName, res);
      setQuestion(questionUpdated);
    } else {
      fetchQuestion();
    }
  };

  const isSelfPosted = () => {
    question.selfPosted && showMessage("Can't interact with your question!", MessageType.INFO);
    return question.selfPosted;
  };

  const toggleFlag = () => {
    if (isSelfPosted()) return;
    setLoading({ ...loading, flag: true });
    Question.toggleQuestionFlag(courseId, lectureNum, questionId)
      .then(({ data }: any) => {
        callbackUpdateState(EnumPropName.SELF_FLAGGED, data);
        setLoading({ ...loading, flag: false });
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const voteQuestion = (vote: VoteType) => {
    if (isSelfPosted()) return;
    const VOTE_UP = vote === 1;
    setLoading({ ...loading, ...(VOTE_UP ? { voteUp: true } : { voteDown: true }) });
    Question.voteQuestion(courseId, lectureNum, questionId, vote)
      .then(({ data }: any) => {
        callbackUpdateState(EnumPropName.VOTE, data as { numUpVote: string; numDownVote: string });
        setLoading({ ...loading, ...(VOTE_UP ? { voteUp: false } : { voteDown: false }) });
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const handleGoBack = () => {
    const pathname = locationState
      ? locationState.prevLocation
      : isTeacher
      ? Routes.TEACHER_QUESTIONS.replace(":lectureNum", String(lectureNum))
      : Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum));
    const tab = locationState ? locationState.tabValue : EnumTabs.Q_A;
    history.replace({
      pathname: pathname,
      state: { lectureNum: lectureNum, goToTab: tab, locationState: locationState },
    });
  };

  const handleSendAnswer = (teacherAsStudent: boolean) => {
    const teacherAnswer = isTeacher && !teacherAsStudent;
    Answer.createAnswer(courseId, lectureNum, questionId, answerInput, teacherAnswer, isTeacher)
      .then((res: any) => {
        setQuestionPosted((prev: boolean) => !prev);
        setAnswerInput("");
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  return { question, loading, voteQuestion, toggleFlag, handleGoBack, handleSendAnswer, answerInput, setAnswerInput, disabledInput };
}
export type { QuestionAnswersQuestionServiceProps, QuestionAnswersQuestionServiceExport };
