import styled from "@emotion/styled";
import { CardContent } from "@mui/material";

export const NotificationCardContent = styled(CardContent)`
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline: 8px;
  &:last-child {
    padding-bottom: 8px;
  }
`;
