import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/_index.scss";
import Store from "./utils/Store";

const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(
  <SnackbarProvider maxSnack={3}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ConfirmProvider>
        <Store>
          <App />
        </Store>
      </ConfirmProvider>
    </BrowserRouter>
  </SnackbarProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
