import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../context/AuthContext";
import { Answer } from "../../../../controller";
import AnswerType from "../../../../interfaces/answer/AnswerType";
import LectureNumParams from "../../../../utils/LectureNumParams";
import QuestionIdParams from "../../../../utils/QuestionIdParams";
import { sortListByItem } from "../../../../utils/Utils";
import AnswerCard from "../../../shared/answer-card/AnswerCard";

export default function QuestionAnswersAnswer({ questionPosted, sortBy }: any) {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse?.id;
  const lectureNum = LectureNumParams();
  const questionId = QuestionIdParams();
  const [answers, setAnswers] = useState<AnswerType[]>([]);

  useEffect(() => {
    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionPosted]);

  const fetchAnswers = async () => {
    Answer.getAnswers(courseId, lectureNum, questionId)
      .then(({ data }) => {
        const notFlagged = data.data.filter((a: AnswerType) => !a.flagged);
        setAnswers(notFlagged);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {sortListByItem(answers, sortBy).map((answer: AnswerType) => (
        <Grid mb={1} key={answer.id}>
          <AnswerCard answer={answer} questionId={questionId} callbackRefresh={fetchAnswers} showInlineButtons showEditable />
        </Grid>
      ))}
    </>
  );
}
