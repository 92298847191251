import * as yup from "yup";
import ChangePasswordType from "../../../../interfaces/change-password/ChangePasswordType";

//at least 8 characters, at least one lower and uppercase letter, and at least one number.
const PASSWORD_REGIX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const MESSAGE_REGIX = "Weak password. Match requirement from above.";

export const validationSchema = yup.object().shape({
  password: yup.string().required("Current password is required."),
  newPassword: yup.string().required("New password is required.").matches(PASSWORD_REGIX, MESSAGE_REGIX),
  newPasswordConfirm: yup
    .mixed()
    .test("match", "Password do not match", function (passwordConfirm) {
      return passwordConfirm === this.parent.newPassword;
    })
    .required("Confirm password is required"),
});

export const INIT_VALUES: ChangePasswordType = { password: "", newPassword: "", newPasswordConfirm: "" };
