import { useCallback, useState } from "react";
import { wsEvents } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AuthContext";
import { Question, SignalSocket } from "../../../../../controller";
import QuestionType from "../../../../../interfaces/question/QuestionType";
import EnumNotificationEvent from "../../../../../utils/enum/EnumNotificationEvent";
import EnumSignal from "../../../../../utils/enum/EnumSignal";
import LectureNumParams from "../../../../../utils/LectureNumParams";
import { updateStateHelper, useEffectOnce } from "../../../../../utils/Utils";

interface LiveLectureQuestionServiceExport {
  questions: QuestionType[];
  flagged: QuestionType[];
  text: string;
  setText: (val: string) => void;
  handleSendQuestion: (teacherAsStudent: boolean) => void;
  callbackUpdateState: (questionId: number, propName: string, response: any) => void;
  showRefreshComponent: boolean;
  fetchData: () => void;
  expand: boolean;
  handleExpand: () => void;
}

interface LiveLectureQuestionServiceProps {
  currentSlide: number;
}

export default function LiveLectureQuestionService({ currentSlide }: LiveLectureQuestionServiceProps): LiveLectureQuestionServiceExport {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse?.id;
  const lectureNum = LectureNumParams();
  const [text, setText] = useState<string>("");
  const [showRefreshComponent, setShowRefreshComponent] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [flagged, setFlagged] = useState<QuestionType[]>([]);
  const [expand, setExpand] = useState<boolean>(false);

  useEffectOnce(() => {
    fetchData();
    SignalSocket.on("connected", (data: any) => console.log(data));
    SignalSocket.on(wsEvents.recv.SIGNAL, handleSignal);
    SignalSocket.on(wsEvents.recv.NOTIFICATION, handleNotification);
  });

  const handleSignal = (signal: any) => {
    if (signal === EnumSignal.QUESTION_FLAGGED) {
      setShowRefreshComponent(true);
    }
  };

  const handleNotification = (notification: string) => {
    const showRefreshEvents: string[] = [EnumNotificationEvent.QUESTION_POSTED, EnumNotificationEvent.QUESTION_ANSWERED];
    if (showRefreshEvents.includes(notification)) {
      setShowRefreshComponent(true);
    }
  };

  const handleSendQuestion = () => {
    Question.postQuestion(courseId, lectureNum, text, currentSlide, false)
      .then((res: any) => {
        fetchData();
        setText("");
      })
      .catch((err: any) => console.log(err));
  };

  const fetchData = useCallback(() => {
    Question.getQuestionsFromLecture(courseId, lectureNum)
      .then((res: any) => {
        const temp = res.data.data;
        setQuestions(temp.filter((q: QuestionType) => !q.flagged));
        setFlagged(temp.filter((q: QuestionType) => q.flagged));
        setShowRefreshComponent(false);
      })
      .catch((err: any) => console.log(err));
  }, [courseId, lectureNum]);

  const callbackUpdateState = (questionId: number, propName: string, response: any) => {
    if (questionId && propName && response) {
      const tabQuestions: QuestionType[] = [...questions];
      const index = tabQuestions.findIndex((q: QuestionType) => q.id === questionId);
      const questionToUpdate: QuestionType = { ...tabQuestions[index] };
      const questionUpdated = updateStateHelper(questionToUpdate, propName, response);
      tabQuestions[index] = questionUpdated;
      setQuestions(tabQuestions);
    } else {
      fetchData();
    }
  };

  const handleExpand = () => setExpand(!expand);

  return {
    questions,
    flagged,
    text,
    setText,
    handleSendQuestion,
    callbackUpdateState,
    showRefreshComponent,
    fetchData,
    expand,
    handleExpand,
  };
}
export type { LiveLectureQuestionServiceExport };
