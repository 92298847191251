import { useState } from "react";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import { useEffectOnce } from "../../../../utils/Utils";

interface MyLecturesServiceType {
  lectures: Array<LectureDto>;
  openMenuLecture: number | null;
  handleOpenMenu: (id: number) => void;
  handleCloseMenu: () => void;
  anchorEl: any;
  setAnchorEl: any;
}

export default function MyLecturesService(): MyLecturesServiceType {
  const { selectedCourse } = useAppContext();
  const [lectures, setLectures] = useState<LectureDto[]>([]);
  const [openMenuLecture, setOpenMenuLecture] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>();

  useEffectOnce(() => {
    getLectures();
  });

  const getLectures = () => {
    Lecture.getCourseLectures(selectedCourse.id)
      .then(({ data }: any) => {
        if (data?.message) {
          setLectures(data.data);
        } else {
          console.error(data);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleOpenMenu = (id: number) => setOpenMenuLecture(id);

  const handleCloseMenu = () => {
    setOpenMenuLecture(null);
    setAnchorEl(null);
  };

  return { lectures, openMenuLecture, handleOpenMenu, handleCloseMenu, anchorEl, setAnchorEl };
}

export type { MyLecturesServiceType };
