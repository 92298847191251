import { mdiTuneVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import Colors from "../../../../utils/Colors";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import UserRole from "../../../../utils/UserRole";
import { sortListByItem } from "../../../../utils/Utils";
import MultiselectCustom from "../../../shared/multiselect-custom/MultiselectCustom";
import { a11yProps, AntTab, AntTabs, TabPanel } from "../../../shared/my-tabs-custom/MyTabsCustom";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import SwitchIOS from "../../../shared/switch-ios/SwitchIOS";
import { optionsSortBy } from "./config";
import MyAnswersService, { MyAnswersServiceType } from "./MyAnswersService";
import MyAnswerTab from "./MyAnswerTab";

export default function MyAnswersTabs() {
  const {
    tabValue,
    handleTabChange,
    sortBy,
    handleSortBy,
    courses,
    lectures,
    handleCoursesChange,
    handleLecturesChange,
    selectedCourses,
    setSelectedCourses,
    selectedLectures,
    setSelectedLectures,
    callbackUpdateState,
    callbackUpdateQuestionState,
    showFilter,
    toggleFilter,
    answers,
    hideAllAnswers,
    toogleHideAllAnswer,
    mobileHeight,
  }: MyAnswersServiceType = MyAnswersService();
  const { isTeacher, isStudent } = UserRole();
  const { isMobile } = useQueryMedia();
  const { my, all, flagged } = answers;

  const createMultiselectRow = (
    <>
      <Grid container item xs={6} justifyContent="center">
        <MultiselectCustom title="Course" value={selectedCourses} handleChange={handleCoursesChange} options={courses} setValue={setSelectedCourses} showAll={true} showNone={true} />
      </Grid>
      <Grid container item xs={6} justifyContent="center">
        <MultiselectCustom
          title="Lecture"
          value={selectedLectures}
          handleChange={handleLecturesChange}
          options={lectures}
          setValue={setSelectedLectures}
          showAll={true}
          showNone={true}
          disabled={selectedCourses.length === 0}
        />
      </Grid>
    </>
  );

  const renderTabsWidth = isTeacher ? 50 : 33.33;

  return (
    <Grid container justifyContent="center" alignItems="center" className="custom-tabs-scrollbar" sx={{ pt: 1 }}>
      {isMobile && (
        <Grid container justifyContent="center" alignItems="center">
          <div className="student-layout__subtext">Answers</div>
        </Grid>
      )}
      <Grid sx={{ display: isMobile && showFilter ? "flex" : "none" }} container item xs={12} justifyContent="center">
        <Grid container item xs={6} justifyContent="center">
          <MultiselectCustom title="Course" value={selectedCourses} handleChange={handleCoursesChange} options={courses} setValue={setSelectedCourses} showAll={true} showNone={true} />
        </Grid>
        <Grid container item xs={6} justifyContent="center">
          <MultiselectCustom
            title="Lecture"
            value={selectedLectures}
            handleChange={handleLecturesChange}
            options={lectures}
            setValue={setSelectedLectures}
            showAll={true}
            showNone={true}
            disabled={selectedCourses.length === 0}
          />
        </Grid>
      </Grid>
      <Grid sx={{ display: isMobile ? "flex" : "none" }} item xs={2} sm={2} md={4} lg={4} xl={4} justifyContent="center">
        <IconButton color="info" onClick={toggleFilter}>
          <Icon path={mdiTuneVariant} size={1} />
        </IconButton>
      </Grid>
      <Grid sx={{ display: !isMobile ? "flex" : "none" }} container item xs={3} sm={3} md={4} lg={4} xl={4} justifyContent="flex-start">
        {createMultiselectRow}
      </Grid>
      <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
        <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant tabs" TabIndicatorProps={{ style: { display: "none" } }}>
          <AntTab label="My" {...a11yProps(EnumTabs.A_MY)} width={renderTabsWidth} />
          <AntTab label="All" {...a11yProps(EnumTabs.A_ALL)} width={renderTabsWidth} />
          {isStudent && <AntTab label="Doghouse " {...a11yProps(EnumTabs.A_FLAG)} width={renderTabsWidth} />}
        </AntTabs>
      </Grid>

      <Grid container item xs={2} sm={2} md={4} lg={4} xl={4} justifyContent="flex-end" alignItems="center">
        {!isMobile && (
          <>
            <Typography variant="subtitle2" sx={{ fontSize: "10px", alignSelf: "center", width: "52px" }}>
              Show/Hide Questions
            </Typography>
            <SwitchIOS value={hideAllAnswers} onChange={toogleHideAllAnswer} activeColor={Colors.ANSWER} deactiveColor={Colors.QUESTION} />
          </>
        )}

        <SelectCustom title="Sort by" value={sortBy} handleChange={handleSortBy} options={optionsSortBy} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, height: mobileHeight(), overflowY: "auto" }}>
        <TabPanel value={tabValue} index={EnumTabs.A_MY}>
          <MyAnswerTab
            answers={sortListByItem(my, sortBy)}
            callbackRefresh={callbackUpdateState}
            callbackQuestionRefresh={callbackUpdateQuestionState}
            selectedCourses={selectedCourses}
            selectedLectures={selectedLectures}
            tabValue={tabValue}
            showAllAnswers={!hideAllAnswers}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.A_ALL}>
          <MyAnswerTab
            answers={sortListByItem(all, sortBy)}
            callbackRefresh={callbackUpdateState}
            callbackQuestionRefresh={callbackUpdateQuestionState}
            selectedCourses={selectedCourses}
            selectedLectures={selectedLectures}
            tabValue={tabValue}
            showAllAnswers={!hideAllAnswers}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.A_FLAG}>
          <MyAnswerTab
            answers={sortListByItem(flagged, sortBy)}
            callbackRefresh={callbackUpdateState}
            callbackQuestionRefresh={callbackUpdateQuestionState}
            selectedCourses={selectedCourses}
            selectedLectures={selectedLectures}
            tabValue={tabValue}
            showAllAnswers={!hideAllAnswers}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
