type DividerLineType = {
  color: string;
};

export default function DividerLine(props: DividerLineType) {
  const { color } = props;
  return (
    <svg height={3} width={"100%"}>
      <line x1="0" y1="0" x2="100%" y2="0" strokeWidth={2} stroke={color} />
    </svg>
  );
}
