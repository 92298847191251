import { Grid, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { User } from "../../../../controller";
import EnumRole from "../../../../utils/enum/EnumRole";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import Routes from "../../../../utils/Routes";
import { IS_SAFARI } from "../../../../utils/Utils";
import ButtonCustom from "../../../shared/button-custom/ButtonCustom";
import DownloadIcon from "../../../shared/icons/DownloadIcon";

interface LocationType {
  state: { anonId: string; password: string; lectureNum: number };
}

export const DownloadPassword = () => {
  const { updateAccessToken, updateRole } = useAppContext();
  const baseClass = "download-password";
  const history = useHistory();
  const location: LocationType = useLocation();
  const [clickedDownload, setClickedDownload] = useState<boolean>(false);
  const { anonId, password, lectureNum } = location?.state ?? {};

  const handleLogin = () => {
    User.userLogin(anonId, password)
      .then((res: any) => {
        const data = res?.data;
        if (data?.message) {
          updateAccessToken(data.data);
          updateRole(EnumRole.STUDENT);
          history.push({
            pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
            state: { lectureNum: lectureNum, goToTab: EnumTabs.REMOTE },
          });
        }
      })
      .catch((err: any) => console.log(err));
  };

  const handleDownloadButton = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = 400;
    canvas.height = 400;
    const ctx: any = canvas.getContext("2d");

    ctx.fillStyle = "#FFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#000";
    ctx.textAlign = "center";
    ctx.font = "24px serif";
    ctx.fillText(`AnonId: ${anonId}`, canvas.width / 2, canvas.height / 2 - 10);
    ctx.fillText(`Password: ${password}`, canvas.width / 2, canvas.height / 2 + 10);

    // https://github.com/eligrey/FileSaver.js/issues/12#issuecomment-47247096 - problem saveAs,problem with application/octet-stream
    if (IS_SAFARI) {
      canvas.toBlob(async (blob: any) => {
        setClickedDownload(true);
        const url = window.URL.createObjectURL(blob);
        saveAs(url, "anonId.png");
      }, "text/plain");
    } else {
      canvas.toBlob((blob: any) => {
        saveAs(blob, "anonId.png");
        handleLogin();
      }, "image/png");
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" className={`${baseClass}`}>
      <Grid item mb={5}>
        <Typography className={`${baseClass}_main-text`}>Congratulations, you have successfully joined the class!</Typography>
      </Grid>
      <Grid item mb={5}>
        <Typography className={`${baseClass}_second-text`}>Please take a moment to download your Anon-ID and your password.</Typography>
      </Grid>
      <Grid item mb={5}>
        <Typography className={`${baseClass}_second-text`}>REMINDER: If you are on Safari, please download instead of view file after clicking the below download button.</Typography>
      </Grid>
      <Grid item sx={{ display: "flex" }}>
        <ButtonCustom gradient="green" label="Download" textColor="black" fontWeight="800" onClick={handleDownloadButton} endIcon={<DownloadIcon />} />
        {IS_SAFARI && <ButtonCustom onClick={handleLogin} disabled={!clickedDownload} label="Join class" className={`${baseClass}_join-class`} whiteBorder={true} gradient="dark-purple" />}
      </Grid>
    </Grid>
  );
};
