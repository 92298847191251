import { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { trigger } from "../../../constants/events";
import { useAppContext } from "../../../context/AuthContext";
import { Notification, Question } from "../../../controller";
import { VoteType } from "../../../interfaces/Types";
import EnumPropName from "../../../utils/enum/EnumPropName";
import MessageType from "../../../utils/MessageType";
import Routes from "../../../utils/Routes";
import { EVENT_UPDATE_NOTIFICATIONS } from "../../../utils/Utils";

interface QuestionCardServiceType {
  toggleFlag: () => void;
  riseUpQuestion: () => void;
  voteQuestion: (val: VoteType) => void;
  handleAnswerClick: (questionId: number, location: string) => void;
  handleEditIcon: () => void;
  handleEditQuestion: (questionText: string) => void;
  readNotification: (notificationId: number) => void;
  showEditField: boolean;
  editQuestion: string;
  openHistoryDialog: boolean;
  setOpenHistoryDialog: Dispatch<SetStateAction<boolean>>;
}

interface QuestionCardServiceProps {
  id: number;
  text: string;
  selfPosted: boolean;
  courseId: number;
  lectureNum: number;
  callbackRefresh: any;
  selectedCourses: any;
  selectedLectures: any;
  tabValue: number;
}

export default function QuestionCardService({
  id,
  text,
  selfPosted,
  courseId,
  lectureNum,
  callbackRefresh,
  selectedCourses,
  selectedLectures,
  tabValue,
}: QuestionCardServiceProps): QuestionCardServiceType {
  const { showMessage } = useAppContext();
  const [showEditField, setShowEditField] = useState<boolean>(false);
  const [editQuestion, setEditQuestion] = useState<string>("");
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);

  const history = useHistory();

  const isSelfPosted = () => {
    selfPosted && showMessage("Can't interact with your question!", MessageType.INFO);
    return selfPosted;
  };

  const toggleFlag = () => {
    if (isSelfPosted()) return;
    Question.toggleQuestionFlag(courseId, lectureNum, id)
      .then(({ data }) => {
        // callbackRefresh(id, EnumPropName.SELF_FLAGGED, data);
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const riseUpQuestion = () => {
    if (isSelfPosted()) return;
    Question.riseUpQuestion(courseId, lectureNum, id)
      .then(({ data }) => {
        // callbackRefresh(id, EnumPropName.RISE_UP, data);
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const voteQuestion = (vote: VoteType) => {
    if (isSelfPosted()) return;
    Question.voteQuestion(courseId, lectureNum, id, vote)
      .then(({ data }: any) => {
        // callbackRefresh(id, EnumPropName.VOTE, data as { numUpVote: string; numDownVote: string });
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const handleEditIcon = () => {
    setEditQuestion(text);
    setShowEditField(!showEditField);
  };

  const handleEditQuestion = async (questionText: string) => {
    Question.updateQuestion(courseId, lectureNum, id, questionText, false)
      .then(({ data }) => {
        const { text, editable, hasHistory } = data?.data ?? {};
        callbackRefresh(id, EnumPropName.EDIT, { text, editable, hasHistory });
        setShowEditField(false);
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const readNotification = (notificationId: number) => {
    Notification.acknowledgeById(notificationId)
      .then((res: any) => {
        callbackRefresh(id, EnumPropName.MARK_AS_READ, undefined);
        trigger(EVENT_UPDATE_NOTIFICATIONS);
      })
      .catch((err: any) => console.log(err));
  };

  const handleAnswerClick = (questionId: number, location: string) => {
    const state = selectedCourses && selectedLectures ? { prevSelectedCourses: selectedCourses, prevSelectedLectures: selectedLectures, prevLocation: location, tabValue: tabValue } : null;
    history.replace({
      pathname: Routes.PAGE_QUESTION_ANSWERS.replace(":lectureNum", String(lectureNum)).replace(":questionId", questionId.toString()),
      state: state,
    });
  };

  return {
    toggleFlag,
    riseUpQuestion,
    voteQuestion,
    handleEditIcon,
    handleAnswerClick,
    handleEditQuestion,
    readNotification,
    showEditField,
    editQuestion,
    openHistoryDialog,
    setOpenHistoryDialog,
  };
}
export type { QuestionCardServiceType };
