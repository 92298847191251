import SvgIcon from "@mui/material/SvgIcon";

const AddIcon = ({ className = "" }: any) => (
  <SvgIcon width="20.743" height="20.743" viewBox="0 0 20.743 20.743" className={className}>
    <g id="Group_1505" data-name="Group 1505" transform="translate(-2178.803 -5378.143)">
      <circle
        id="Ellipse_384"
        data-name="Ellipse 384"
        style={{ strokeMiterlimit: 10, strokeWidth: "1.366px", stroke: "white", fill: "none" }}
        cx="9.688"
        cy="9.688"
        r="9.688"
        transform="translate(2179.487 5378.826)"
      ></circle>
      <line id="Line_834" data-name="Line 834" style={{ strokeMiterlimit: 10, strokeWidth: "1.366px", stroke: "white", fill: "none" }} y2="8.88" transform="translate(2189.175 5384.075)"></line>
      <line id="Line_835" data-name="Line 835" style={{ strokeMiterlimit: 10, strokeWidth: "1.366px", stroke: "white", fill: "none" }} x1="8.88" transform="translate(2184.735 5388.515)"></line>
    </g>
  </SvgIcon>
);
export default AddIcon;
