import { useState } from "react";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import { useEffectOnce } from "../../../../utils/Utils";

interface MyLecturesServiceType {
  lectures: Array<LectureDto>;
}

export default function MyLecturesService(): MyLecturesServiceType {
  const { selectedCourse } = useAppContext();
  const [lectures, setLectures] = useState<Array<LectureDto>>([]);

  useEffectOnce(() => {
    getLectures();
  });

  const getLectures = () => {
    Lecture.getCourseLectures(selectedCourse.id)
      .then(({ data }: any) => {
        if (data?.message) {
          setLectures(data.data);
        } else {
          console.error(data);
        }
      })
      .catch((err) => console.error(err));
  };

  return { lectures };
}

export type { MyLecturesServiceType };
