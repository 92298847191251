import { Card, CardContent, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import Routes from "../../../utils/Routes";
import AnonymousRedCheekIcon from "../icons/AnonymousRedCheekIcon";

export default function AnonCard(props: any) {
  const { classId } = props;
  const baseClass = "anon-card";
  const history = useHistory();

  return (
    <Grid
      item
      container
      width={{ xs: "100px", sm: "100px", md: "140px", lg: "140px", xl: "140px" }}
      height={{ xs: "90px", sm: "90px", md: "140px", lg: "140px", xl: "140px" }}
      onClick={() => history.push({ pathname: Routes.STUDENT_WAITING_ROOM, state: { classId: classId } })}
    >
      <Card className={`${baseClass}`}>
        <CardContent style={{ padding: 0 }}>
          <Grid container item justifyContent="center" alignItems="center">
            <AnonymousRedCheekIcon className={`${baseClass}-icon`} />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
