import { useEffect, useState } from "react";
import { useAppContext } from "../../../../../context/AuthContext";
import { Lecture } from "../../../../../controller";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import MessageType from "../../../../../utils/MessageType";

interface ILectureStatistics {
  lecture?: LectureDto;
}

export const useLectureStatistics = ({ lecture }: ILectureStatistics) => {
  const { selectedCourse, showMessage } = useAppContext();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (lecture) {
      Lecture.lectureStatistics(selectedCourse.id, lecture?.lectureNum)
        .then(({ data }) => setData(data))
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecture]);

  return { data };
};
