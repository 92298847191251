import { useState } from "react";
import { useAppContext } from "../../../context/AuthContext";
import { Feedback } from "../../../controller/Feedback";
import MessageType from "../../../utils/MessageType";
import UserRole from "../../../utils/UserRole";

interface FeedbackPageServiceExport {
  handleMessageChange: any;
  createFeedback: any;
  message: string;
  loading: boolean;
}

export function FeedbackPageService() {
  const { showMessage, selectedCourse } = useAppContext();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { isTeacher } = UserRole();

  const createFeedback = () => {
    setLoading(true);
    const body = { courseId: selectedCourse?.id, teacher: isTeacher, message: message };
    Feedback.createFeedback(body)
      .then(() => {
        showMessage("Thank you for your feedback.", MessageType.SUCCESS);
        setMessage("");
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR))
      .finally(() => setLoading(false));
  };

  const handleMessageChange = (e: any) => setMessage(e.target.value);

  return { handleMessageChange, createFeedback, message, loading, setLoading };
}
export type { FeedbackPageServiceExport };
