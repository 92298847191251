import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import SendInputMode from "../../../constants/SendInputMode";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import QuestionType from "../../../interfaces/question/QuestionType";
import Colors from "../../../utils/Colors";
import { getTitleQAD, makeRestrictions } from "../../../utils/Utils";
import AnswerButton from "../button/AnswerButton";
import DislikeButton from "../button/DislikeButton";
import LikeButton from "../button/LikeButton";
import DialogHistory from "../dialog-history/DialogHistory";
import DividerLine from "../divider-line/DividerLine";
import FlagComponent from "../flag-component/FlagComponent";
import EditIcon from "../icons/EditIcon";
import MoreLessText from "../more-less-text/MoreLessText";
import MultilineQuestionAnswer from "../multiline-question-answer/MultilineQuestionAnswer";
import PadlockComponent from "../padlock-component/PadlockComponent";
import RemovedFromClass from "../removed-from-class/RemovedFromClass";
import RiseUpComponent from "../rise-up-component/RiseUpComponent";
import QuestionCardService, { QuestionCardServiceType } from "./QuestionCardService";

interface QuestionCardType {
  question: QuestionType;
  callbackRefresh: (questionId: number, propName: string, response: any) => void;
  selectedCourses?: any;
  selectedLectures?: any;
  tabValue: number;
  showEditable?: boolean;
}

const QuestionCard = ({ question, callbackRefresh, selectedCourses, selectedLectures, tabValue, showEditable }: QuestionCardType) => {
  const {
    id,
    text,
    createdAt,
    pseudonym,
    courseId,
    lectureNum,
    selfFlagged,
    answerCount,
    numDownVotes,
    numUpVotes,
    showRiseUp,
    hasRiseUp,
    selfPosted,
    editable,
    respondable,
    answerNotifCount,
    removedFromClass,
    notificationId,
    teacherQuestion,
    hasHistory,
  } = question;

  const {
    voteQuestion,
    toggleFlag,
    riseUpQuestion,
    readNotification,
    handleAnswerClick,
    handleEditIcon,
    handleEditQuestion,
    showEditField,
    editQuestion,
    openHistoryDialog,
    setOpenHistoryDialog,
  }: QuestionCardServiceType = QuestionCardService({
    id,
    text,
    selfPosted,
    courseId,
    lectureNum,
    callbackRefresh,
    selectedCourses,
    selectedLectures,
    tabValue,
  });

  const location = useLocation();
  const { isMobile } = useQueryMedia();

  const actionButtons = (
    <>
      <LikeButton onClick={() => voteQuestion(1)} disabled={selfPosted || !respondable} label={numUpVotes} />
      <DislikeButton onClick={() => voteQuestion(-1)} disabled={selfPosted || !respondable} label={numDownVotes} />
      <AnswerButton onClick={() => handleAnswerClick(id, location?.pathname)} label={answerCount} hasNotification={Boolean(answerNotifCount)} />
    </>
  );
  return (
    <div className="question-card">
      <Grid container padding={1} className={`question-card-container ${notificationId && "question-card-container-not-read"}`} onClick={() => notificationId && readNotification(notificationId)}>
        <Grid container justifyContent="center" alignItems="start">
          <Grid container item flexDirection="column" xs={isMobile ? 12 : 9} sm={isMobile ? 12 : 9} md={isMobile ? 12 : 9} lg={isMobile ? 12 : 9.2} xl={isMobile ? 12 : 9.7}>
            <Grid item xs={12}>
              <>
                <Grid display="flex" alignItems="center" mb="4px">
                  <Typography color="white" fontSize="10px" variant="caption">
                    {getTitleQAD(pseudonym, teacherQuestion, createdAt)}
                  </Typography>
                  {showEditable && editable && selfPosted && (
                    <Grid item style={{ marginLeft: 10, cursor: "pointer" }} onClick={handleEditIcon}>
                      <EditIcon width="12px" height="12px" sx={{ color: "white", mb: 0.3 }} />
                    </Grid>
                  )}
                  {removedFromClass && <RemovedFromClass />}
                  <PadlockComponent respondable={respondable} />
                  {!selfPosted && !teacherQuestion && <FlagComponent disabled={!respondable} selfFlagged={selfFlagged} handleToggleFlag={toggleFlag} />}
                  {showRiseUp && <RiseUpComponent type={SendInputMode.Q} disabled={!respondable} handleRiseUp={riseUpQuestion} hasRiseUp={hasRiseUp} />}
                </Grid>
                <DividerLine color="white" />
              </>
            </Grid>
            {showEditField ? (
              <Grid container item justifyContent="center" mt="4px">
                <MultilineQuestionAnswer
                  placeholder="Edit a question"
                  onSubmit={handleEditQuestion}
                  initValue={editQuestion}
                  chars={makeRestrictions(SendInputMode.Q).chars}
                  words={makeRestrictions(SendInputMode.Q).words}
                />
              </Grid>
            ) : (
              <>
                <MoreLessText text={text} color={Colors.WHITE} />
                {hasHistory && (
                  <>
                    <Button className="question-card-history" variant="text" onClick={() => setOpenHistoryDialog(true)}>
                      [Edited]
                    </Button>
                    <DialogHistory questionId={id} lectureNum={lectureNum} open={openHistoryDialog} type={SendInputMode.Q} onClose={() => setOpenHistoryDialog(false)} />
                  </>
                )}
              </>
            )}
          </Grid>
          {isMobile && (
            <Grid item xs={12} textAlign="left" sx={{ mt: 2 }}>
              {actionButtons}
            </Grid>
          )}

          {!isMobile && (
            <Grid item xs={3} sm={3} md={3} lg={2.8} xl={2.3} textAlign="right">
              {actionButtons}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionCard;
