import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import MenuItemDto from "../../../interfaces/menu-item/MenuItem";
import Colors from "../../../utils/Colors";
import EnumSidemenuIcon from "../../../utils/enum/EnumSidemenuIcon";
import AnswerIcon from "../../shared/icons/AnswerIcon";
import QuestionIcon from "../../shared/icons/QuestionIcon";
import { studentItems1, studentItems2, studentItems3, studentItems4 } from "./config";

export default function StudentSidebar() {
  const { selectedCourse, deleteAccessToken } = useAppContext();

  const userLogout = async () => {
    //TODOs
    // const isLogout = await User.userLogout();
    deleteAccessToken();
    // if (!isLogout) console.error("Error with logout!");
  };

  const routeOnClick = (id: number) => {
    switch (id) {
      case EnumSidemenuIcon.LOGOUT:
        userLogout();
        break;
      default:
        break;
    }
  };

  const createOneItem = ({ id, icon, label, to }: MenuItemDto, i: number) => {
    let isActive = false;
    return (
      <ListItem key={label} disablePadding>
        <NavLink
          key={i}
          isActive={(match, location) => {
            isActive = match?.url === location.pathname;
            return isActive;
          }}
          to={to}
          onClick={() => routeOnClick(id)}
          className={(isActive) => "student-sidebar__link" + (isActive ? "__selected" : "")}
        >
          <ListItemButton>
            <div className="student-sidebar__card__icon">{renderIcon(id, icon, isActive)}</div>
            <div className="student-sidebar__card__label">{label}</div>
          </ListItemButton>
        </NavLink>
      </ListItem>
    );
  };

  const createListItem = (itemList: Array<MenuItemDto>) => (
    <div className="student-sidebar__card">
      <List>{itemList.filter(({ id }: MenuItemDto) => !(id === EnumSidemenuIcon.LECTURES && !selectedCourse)).map((item: any, i: number) => createOneItem(item, i))}</List>
    </div>
  );

  const renderIcon = (id: number, icon: any, isActive: boolean) => {
    switch (id) {
      case EnumSidemenuIcon.MY_QUESTIONS:
        return <QuestionIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      case EnumSidemenuIcon.MY_ANSWERS:
        return <AnswerIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      default:
        return icon;
    }
  };

  return (
    <div className="student-sidebar">
      {createListItem(studentItems1)}
      {selectedCourse && createListItem(studentItems2)}
      {createListItem(studentItems3)}
      {createListItem(studentItems4)}
    </div>
  );
}
