import { Button, Dialog, DialogActions, DialogContent, Divider, List, ListItem, ListItemText } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SendInputMode from "../../../constants/SendInputMode";
import { useAppContext } from "../../../context/AuthContext";
import { Answer, Question } from "../../../controller";
import { SendInputType } from "../../../interfaces/Types";
import Colors from "../../../utils/Colors";
import EnumSortItem from "../../../utils/enum/EnumSortItem";
import MessageType from "../../../utils/MessageType";
import { DATE_TIME_FORMAT_2, sortListByItem } from "../../../utils/Utils";
import DialogTitleAnon from "../dialog-title/DialogTitleAnon";

interface DialogDialogHistoryType {
  questionId: number;
  answerId?: number;
  lectureNum: number;
  type?: SendInputType;
  open: boolean;
  onClose: () => void;
}

interface HistoryType {
  id: number;
  text: string;
  createdAt: Date;
  latestUpdate: boolean;
}

const DialogHistory = ({ questionId, answerId, lectureNum, open, type = SendInputMode.Q, onClose }: DialogDialogHistoryType) => {
  const { selectedCourse, showMessage } = useAppContext();
  const [history, setHistory] = useState<HistoryType[]>([]);
  const isQuestion = type === SendInputMode.Q;
  const title = `${isQuestion ? "Question" : "Answer"} history`;

  useEffect(() => {
    open && getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getHistory = () => {
    if (isQuestion) {
      Question.getHistory(selectedCourse.id, lectureNum, questionId)
        .then(({ data }: any) => setHistory(data.data))
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    } else {
      Answer.getHistory(selectedCourse.id, lectureNum, questionId, answerId)
        .then(({ data }: any) => setHistory(data.data))
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
  };

  return (
    <Dialog open={open} fullWidth sx={{ textAlign: "center" }}>
      <DialogTitleAnon color={Colors.BLUE_DIANNE} onClose={onClose}>
        {title}
      </DialogTitleAnon>
      <DialogContent>
        <List component="nav" aria-label={title}>
          {sortListByItem(history, EnumSortItem.TIME).map(({ id, text, createdAt, latestUpdate }: HistoryType) => (
            <React.Fragment key={id}>
              <ListItem>
                <ListItemText primaryTypographyProps={{ color: Colors.BLUE_DIANNE }} primary={text} secondary={!latestUpdate ? moment(createdAt).format(DATE_TIME_FORMAT_2) : ""} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogHistory;
