const QuestionMarkIcon = () => (
  <svg width="50.0333" height="50.0333" viewBox="0 0 56.633 56.633" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.632 28.3159C56.632 33.9163 54.9714 39.3908 51.86 44.0474C48.7486 48.7039 44.3263 52.3334 39.1523 54.4766C33.9782 56.6197 28.2847 57.1805 22.7919 56.0879C17.2991 54.9953 12.2537 52.2984 8.29361 48.3384C4.33355 44.3783 1.63668 39.3331 0.544101 33.8403C-0.548479 28.3476 0.0122578 22.6541 2.15543 17.48C4.2986 12.3059 7.92809 7.88337 12.5846 4.77197C17.2412 1.66057 22.7157 0 28.3161 0C35.8259 0 43.0283 2.98318 48.3385 8.29346C53.6488 13.6037 56.632 20.806 56.632 28.3159Z"
      fill="url(#paint0_linear_2014_3)"
    />
    <path
      d="M26.0238 36.3513H29.7917V40.1192H26.0238V36.3513ZM28.5358 15C35.2552 15.2763 38.1815 22.0585 34.1876 27.1451C33.1451 28.4011 31.4622 29.23 30.6332 30.285C29.7917 31.3275 29.7917 32.5834 29.7917 33.8394H26.0238C26.0238 31.742 26.0238 29.9711 26.8653 28.7151C27.6943 27.4591 29.3773 26.7181 30.4197 25.8892C33.4591 23.0758 32.7056 19.0944 28.5358 18.7679C27.5365 18.7679 26.5781 19.1649 25.8715 19.8715C25.1649 20.5781 24.7679 21.5365 24.7679 22.5358H21C21 20.5372 21.7939 18.6204 23.2072 17.2072C24.6204 15.7939 26.5372 15 28.5358 15Z"
      fill="#1F3C52"
    />
    <defs>
      <linearGradient id="paint0_linear_2014_3" x1="0" y1="28.316" x2="56.632" y2="28.316" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0EE15" />
        <stop offset="1" stopColor="#FFD000" />
      </linearGradient>
    </defs>
  </svg>
);
export default QuestionMarkIcon;
