import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs, Link } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import Routes from "../../../utils/Routes";

export default function StudentBreadcrumbs() {
  const history = useHistory();
  const { selectedCourse, selectedLecture } = useAppContext();
  const location = useLocation();

  const breadcrumbRef: any = useRef();

  const [showPath, setShowPath] = useState(false);
  const showLectureName =
    location.pathname.includes(Routes.TEACHER_MY_LECTURES_EDIT.substring(0, Routes.TEACHER_MY_LECTURES_EDIT.lastIndexOf("/"))) ||
    (location.pathname.includes("lecture") && location.pathname.includes("stats")) ||
    location.pathname.endsWith("/live") ||
    location.pathname.includes("slide-stats");

  const handleMouseOver = () => setShowPath(true);
  const handleMouseOut = () => setShowPath(false);
  const routeToHome = () => history.push(Routes.STUDENT_MY_COURSE);

  useEffect(() => {
    const node = breadcrumbRef.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, []);

  return (
    <Breadcrumbs aria-label="breadcrumb" itemsBeforeCollapse={1} itemsAfterCollapse={0} maxItems={showPath ? 10 : 1} ref={breadcrumbRef} sx={{ color: "white" }}>
      <Link underline="hover" sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} color="primary.contrastText" onClick={routeToHome}>
        <HomeIcon sx={{ mr: 0.5 }} />
      </Link>
      {location.pathname !== Routes.STUDENT_MY_COURSE && selectedCourse && (
        <Link underline="hover" sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} color="primary.contrastText" onClick={() => history.push(Routes.STUDENT_MY_LECTURES)}>
          {selectedCourse.shortName ?? selectedCourse.name}
        </Link>
      )}
      {showLectureName && (
        <Link underline="none" sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} color="primary.contrastText">
          {selectedLecture.name}
        </Link>
      )}
    </Breadcrumbs>
  );
}
