import { Button } from "@mui/material";
import DiscussonIcon from "../icons/DiscussonIcon";
import NotificationBadge from "../notification-badge/NotificationBadge";

interface DiscussionButtonProps {
  onClick: () => void;
  disabled?: boolean;
  label?: string | number;
  height?: string;
  dark?: boolean;
  hasBorder?: boolean;
  hasNotification?: boolean;
}
const DiscussionButton = ({ onClick, disabled, label, height, dark, hasBorder, hasNotification }: DiscussionButtonProps) => (
  <Button
    variant="outlined"
    onClick={onClick}
    disabled={disabled}
    className="button-reaction-button button-reaction-button__discussions"
    size="small"
    startIcon={<DiscussonIcon />}
    sx={{ minHeight: height ?? "26px", borderColor: hasBorder ? "#264c65 !important" : "inherit" }}
  >
    <NotificationBadge hasNotification={hasNotification} />
    {`Discussions (${label})`}
  </Button>
);

export default DiscussionButton;
