import { SvgIcon } from "@mui/material";

interface SvgIconWrapperType {
  icon: any;
  svgStyle?: Object;
}

const SvgIconWrapper = ({ svgStyle = {}, icon }: SvgIconWrapperType) => (
  <SvgIcon sx={svgStyle}>
    <path d={icon} />
  </SvgIcon>
);

export default SvgIconWrapper;
