import { mdiHumanMaleBoard, mdiMessageCheckOutline } from "@mdi/js";
import Icon from "@mdi/react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsIcon from "@mui/icons-material/Settings";
import EnumSidemenuIcon from "../../../utils/enum/EnumSidemenuIcon";
import Routes from "../../../utils/Routes";
import AnswerIcon from "../../shared/icons/AnswerIcon";
import LiveIconOn from "../../shared/icons/live-icon/LiveIconOn";
import QuestionIcon from "../../shared/icons/QuestionIcon";

export const teacherItems1 = [
  { icon: <HomeIcon />, label: "My Courses", to: Routes.TEACHER_MY_COURSE, id: EnumSidemenuIcon.COURSES },
  { icon: <Icon path={mdiHumanMaleBoard} size={1} />, label: "My Lectures", to: Routes.TEACHER_MY_LECTURES, id: EnumSidemenuIcon.LECTURES },
];
export const teacherItems2 = [
  { icon: <LiveIconOn />, label: "Class Status", to: Routes.TEACHER_CLASS_STATUS, id: EnumSidemenuIcon.LIVE_LECTURE },
  { icon: <InsertChartIcon />, label: "Statistics admin", to: Routes.ADMIN_STATS, id: EnumSidemenuIcon.ADMIN_STATS },
  { icon: <InsertChartIcon />, label: "Statistics", to: Routes.TEACHER_STATS, id: EnumSidemenuIcon.STATS },
  { icon: <QuestionIcon />, label: "Questions", to: Routes.TEACHER_MY_QUESTIONS, id: EnumSidemenuIcon.MY_QUESTIONS },
  { icon: <AnswerIcon />, label: "Answers", to: Routes.TEACHER_MY_ANSWERS, id: EnumSidemenuIcon.MY_ANSWERS },
  { icon: <QuestionAnswerIcon />, label: "Discussions", to: Routes.TEACHER_MY_DISCUSSIONS, id: EnumSidemenuIcon.MY_DISCUSSIONS },
  { icon: <NotificationsIcon />, label: "Notifications", to: Routes.TEACHER_NOTIFICATIONS, id: EnumSidemenuIcon.NOTIFICATIONS },
];
export const teacherItems3 = [
  { icon: <SettingsIcon />, label: "My Settings", to: Routes.TEACHER_SETTINGS, id: EnumSidemenuIcon.SETTINGS },
  { icon: <HelpIcon />, label: "How it Works", to: Routes.TEACHER_HOW_IT_WORKS, id: EnumSidemenuIcon.HELP },
  { icon: <ExitToAppIcon />, label: "Log out", to: Routes.ABOUT, id: EnumSidemenuIcon.LOGOUT },
];

export const teacherItems4 = [{ icon: <Icon path={mdiMessageCheckOutline} size={1} />, label: "Feedback", to: Routes.FEEDBACK, id: EnumSidemenuIcon.FEEDBACK }];
