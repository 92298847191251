// NEW BACKEND
import { http } from "../controller/http";

export const Lecture = {
  // Get all lectures for a course.
  getCourseLectures(courseId) {
    return http.get(`/course/${courseId}/lectures`);
  },

  // Get all lectures for a coursesId.
  getLecturesByCoursesId(coursesId) {
    return http.post(`/courses/lectures`, { coursesId });
  },

  // Create a new lecture
  createLecture(courseId, name, startTime, endTime, allowQuestionsDuringLecture = false, allowDiscussionsDuringLecture = false, urlYoutube) {
    return http.post(`/course/${courseId}/lecture`, {
      name,
      startTime,
      endTime,
      allowQuestionsDuringLecture,
      allowDiscussionsDuringLecture,
      urlYoutube,
    });
  },

  // Get a single lecture by courseId and lectureNum
  async getLecture(courseId, lectureNum) {
    const { data } = await http.get(`/course/${courseId}/lecture/${lectureNum}`);
    return data.data;
  },

  // Update a lecture
  updateLecture(courseId, lectureNum, name, startTime, endTime, allowQuestionsDuringLecture, allowDiscussionsDuringLecture, urlYoutube) {
    return http.put(`/course/${courseId}/lecture/${lectureNum}`, {
      courseId,
      lectureNum,
      name,
      startTime,
      endTime,
      allowQuestionsDuringLecture,
      allowDiscussionsDuringLecture,
      urlYoutube,
    });
  },
  // Extend a lecture
  extendLecture(courseId, lectureNum) {
    return http.put(`/course/${courseId}/lecture/${lectureNum}/extend`);
  },

  classStatusInfo: (courseId, lectureNum) => http.get(`/course/${courseId}/lecture/${lectureNum}/info`),

  lectureStatistics: (courseId, lectureNum) => http.get(`/course/${courseId}/lecture/${lectureNum}/statistics`),

  lecturePseudonym: (courseId, lectureNum) => http.get(`/course/${courseId}/lecture/${lectureNum}/pseudonym`),

  // Delete a Lecture
  deleteLecture(courseId, lectureNum) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}`);
  },

  // Reset pseudonyms given in lecture
  async resetPseudonyms(courseId, lectureNum) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}/reset/reg`);
  },

  //CALL BOTH OF THE BELOW METHODS ON ACCOUNT RESET
  // Reset accounts given in lecture
  async resetNonRegistered(courseId, lectureNum) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}/reset/noreg`);
  },

  // Reset new accounts
  async resetNewAccounts(courseId, lectureNum) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}/reset/new`);
  },
  //upload Presentation
  async uploadPresentation(courseId, lectureNum, presentation) {
    let fd = new FormData();
    fd.append("presentation", presentation);
    return http.post(`/course/${courseId}/lecture/${lectureNum}/presentation`, fd);
  },

  //download Presentation
  // axiosProps are needed for canceltoken
  async downloadPresentation(courseId, lectureNum) {
    return http
      .get(`/course/${courseId}/lecture/${lectureNum}/presentation`, {
        responseType: "blob",
      })
      .then(({ data }) => data)
      .catch((err) => {
        console.log("Presentation not found/not uploaded");
      });
  },

  //delete Presentation
  deletePresentation(courseId, lectureNum) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}/presentation`);
  },

  // Start a lecture
  async startLecture(courseId, lectureNum) {
    return http.get(`/course/${courseId}/lecture/${lectureNum}/start`).then(({ data }) => data);
  },

  // End a lecture
  async endLecture(courseId, lectureNum) {
    return http.get(`/course/${courseId}/lecture/${lectureNum}/end`);
  },
  //gets data uploaded date and title about presentation
  async getMetaPresentation(courseId, lectureNum) {
    return http.get(`/course/${courseId}/lecture/${lectureNum}/presentation/meta`).then(({ data }) => data);
  },

  async getLiveLectureAttending(courseId) {
    return http.get(`/course/${courseId}/lecture/liveAttending`);
  },

  getExpressionsForSlide: (courseId, lectureId, slideId) => http.get(`/course/${courseId}/lecture/${lectureId}/expression/stat/${slideId}`).then(({ data }) => data),

  // Add expression for video
  addSlideInteractionsVideo: (courseId, lectureNum, endTimeSeconds) =>
    http.post(`/course/${courseId}/lecture/${lectureNum}/video/slideInteraction`, {
      endTimeSeconds,
    }),

  // Add expression for video
  addExpressionVideo: (courseId, lectureNum, expressionTag, time, endTime) =>
    http.post(`/course/${courseId}/lecture/${lectureNum}/video/expression`, {
      expressionTag,
      time,
      endTime,
    }),

  getLastExpressionVideo: (courseId, lectureNum) => http.get(`/course/${courseId}/lecture/${lectureNum}/video/lastExpression`),
};
