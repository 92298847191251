const Routes = Object.freeze({
  ABOUT: "/about",

  //lecture
  LECTURE_STATS: "/lecture/:lectureNum/stats",
  LECTURE_PRESENTATION: "/lecture/:lectureNum/presentation",
  LECTURE_LIVE: "/lecture/:lectureNum/live",
  LECTURE_VIDEO: "/lecture/:lectureNum/video",
  PAGE_QUESTION_ANSWERS: "/lecture/:lectureNum/questions/:questionId",
  // teacher
  TEACHER_LOGIN: "/teacher-login",
  REGISTER_TEACHER: "/register-teacher",
  TEACHER_MY_COURSE: "/teacher/my-courses",
  TEACHER_MY_LECTURES: "/teacher/my-lectures",
  TEACHER_NOTIFICATIONS: "/teacher/notifications",
  TEACHER_MY_COURSES_ADD: "/teacher/my-courses/add",
  TEACHER_MY_COURSES_EDIT: "/teacher/my-courses/edit/:id",
  TEACHER_MY_LECTURES_ADD: "/teacher/my-lectures/add",
  TEACHER_MY_LECTURES_EDIT: "/teacher/my-lectures/edit/:lectureNum",
  TEACHER_CLASS_STATUS: "/teacher/class-status",
  TEACHER_STATS: "/teacher/stats",
  ADMIN_STATS: "/teacher/admin-stats",
  TEACHER_SLIDE_STATS: "/teacher/slide-stats",
  TEACHER_SETTINGS: "/teacher/settings",
  TEACHER_HOW_IT_WORKS: "/teacher/how-it-works",
  TEACHER_REMOTE: "/teacher/:lectureNum/remote",
  TEACHER_MY_QUESTIONS: "/teacher/my-questions",
  TEACHER_MY_ANSWERS: "/teacher/my-answers",
  TEACHER_MY_DISCUSSIONS: "/teacher/my-discussions",
  TEACHER_QUESTIONS: "/lecture/:lectureNum/questions",

  // student
  REGISTER_STUDENT: "/register-student",
  OLD_OR_NEW_STUDENT: "/old-or-new-student",
  STUDENT_MY_COURSE: "/student/my-courses",
  STUDENT_MY_COURSES_ADD: "/student/my-courses/add",
  STUDENT_MY_LECTURES: "/student/my-lectures",
  PICK_CARD: "/pick-card",
  STUDENT_WAITING_ROOM: "/waiting-room",
  STUDENT_DOWNLOAD_PASSWORD: "/download-password",
  STUDENT_DOWNLOAD_PASSWORD_MOBILE: "/download-password-mobile",
  STUDENT_MY_QUESTIONS: "/student/my-questions",
  STUDENT_MY_ANSWERS: "/student/my-answers",
  STUDENT_MY_DISCUSSIONS: "/student/my-discussions",
  STUDENT_NOTIFICATIONS: "/student/notifications",
  STUDENT_SETTINGS: "/student/settings",
  STUDENT_HOW_IT_WORKS: "/student/how-it-works",

  // shared
  FEEDBACK: "/feedback",
});

export default Routes;
