import { useState } from "react";
import { useHistory } from "react-router-dom";
import { wsEvents } from "../../../constants";
import { SignalSocket } from "../../../controller";
import EnumNotificationEvent from "../../../utils/enum/EnumNotificationEvent";
import LectureNumParams from "../../../utils/LectureNumParams";
import Routes from "../../../utils/Routes";
import { useEffectOnce } from "../../../utils/Utils";

interface TeacherBadgeNotificationServiceType {
  newNotification: boolean;
  openQuestions: any;
}

export default function TeacherBadgeNotificationService(): TeacherBadgeNotificationServiceType {
  const lectureNum = LectureNumParams();
  const history = useHistory();
  const [newNotification, setNewNotification] = useState<boolean>(false);

  useEffectOnce(() => {
    SignalSocket.connect();
    SignalSocket.on(wsEvents.recv.NOTIFICATION, handleNotification);
    return () => {
      setNewNotification(false);
      SignalSocket.off(wsEvents.recv.NOTIFICATION, handleNotification);
    };
  });

  const handleNotification = (notification: string) => {
    if (EnumNotificationEvent.QUESTION_POSTED === notification) {
      setNewNotification(true);
    }
  };

  const openQuestions = () => {
    setNewNotification(false);
    history.push(Routes.TEACHER_QUESTIONS.replace(":lectureNum", String(lectureNum)));
  };

  return {
    newNotification,
    openQuestions,
  };
}
export type { TeacherBadgeNotificationServiceType };
