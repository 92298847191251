import { useState } from "react";
import { remoteEvent } from "../../../constants";
import { RemoteSocket } from "../../../controller";
import ExpressionDto from "../../../interfaces/expression/ExpressionDto";
import EnumExpressionsText from "../../../utils/enum/EnumExpressionsText";
import { useEffectOnce } from "../../../utils/Utils";
import { mapExpressions } from "../../../utilsVue";

export default function SlidesStatusBar() {
  const [expressionData, setExpressionData] = useState<ExpressionDto[]>([]);

  useEffectOnce(() => {
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
    return () => {
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
    };
  });

  const handleExpressionData = (data: any) => {
    const arrayOfExp = mapExpressions(data);
    const parseData = arrayOfExp.map((item: any) => {
      return { id: item[0], ...item[1] };
    });
    setExpressionData(parseData);
  };

  const mapExpressionToIcon = (e: any) => {
    if (e === EnumExpressionsText.LOST) return "lost";
    if (e === EnumExpressionsText.PAUSE) return "pause";
    if (e === EnumExpressionsText.GREAT) return "great";
    if (e === EnumExpressionsText.FF) return "ff";
    if (e === EnumExpressionsText.REW) return "rew";
  };

  return (
    <div style={{ color: "white", textAlign: "center" }}>
      {/* TODO expressions in row */}
      <div className="slide-stats-bar ">
        {expressionData.map(({ id, count, percentCount }) => (
          <div key={id} className={`slide-stats-bar__item slide-stats-bar__item--${mapExpressionToIcon(id)}`}>
            <div className="slide-stats-bar__item__bar">
              <span>{count}</span>
              <div className="slide-stats-bar__item__bar__progress" style={{ width: percentCount > 0 ? `${percentCount}%` : "0%" }}></div>
            </div>
            <div className="slide-stats-bar__item__text">{mapExpressionToIcon(id)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
