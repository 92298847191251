import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import Colors from "../../../utils/Colors";
import { FeedbackPageService } from "./FeedbackPageService";
import { Containter, FieldText, Title } from "./styled";

export function FeedbackPage() {
  const { createFeedback, handleMessageChange, message, loading } = FeedbackPageService();
  const block = loading || !message;
  return (
    <Containter>
      <Title>We value your feedback.</Title>
      <Grid>
        <Title>If you have any suggestions, recommendations, questions, complaints or compliments, please let us know. </Title>
        <Grid mt={2}>
          <FieldText
            inputProps={{ style: { color: Colors.WHITE } }}
            autoFocus
            rows={4}
            multiline
            value={message}
            onChange={handleMessageChange}
            placeholder="Feedback"
            id="feedback"
            variant="outlined"
            fullWidth
            InputProps={{ readOnly: loading }}
          />
        </Grid>
      </Grid>
      <Grid mt={2} display="flex" justifyContent="end">
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          disabled={block}
          sx={{ ...(block && { backgroundColor: "#004fa7 !important", color: "#b4c1cb !important" }), width: "110px" }}
          variant="contained"
          onClick={createFeedback}
        >
          Send
        </LoadingButton>
      </Grid>
    </Containter>
  );
}
