// NEW BACKEND

//TODO: fix Course.delete routes
import { http } from "../controller/http";

export const Course = {
  // COURSE ROUTES

  // Get all course numbers and names.
  getAllCourses() {
    return http.get("/courses");
  },

  // Create a new course.
  // Course will be associated with the teacher that created it.
  createCourse(name, shortName, startDate) {
    return http.post("/course", { name, shortName, startDate });
  },

  // Get info for a course
  getCourse(courseId) {
    return http.get(`/course/${courseId}`);
  },

  // Update a course
  updateCourse(courseId, name, shortName, startDate) {
    return http.put(`/course/${courseId}`, { name, shortName, startDate });
  },

  // Delete a course
  deleteCourse(courseId) {
    return http.delete(`/course/${courseId}`);
  },
};

/*
// Methods replaced

getAll -> getAllCourses
get -> getCourse
add -> createCourse
update -> updateCourse
delete -> deleteCourse


*/

//OLD BACKEND - FILE NOT USED
// import { http } from '@/services/http';

// export const Course = {
//   getAll() {
//     return http.get('/course');
//   },
//   get(id) {
//     return http.get(`/course/${id}`);
//   },
//   add(name) {
//     return http.post('/course', { name });
//   },
//   update(id, name) {
//     return http.put(`/course/${id}`, { name });
//   },
//   delete(id) {
//     return http.delete(`/course/${id}`);
//   },
// };
