import Colors from "../../../utils/Colors";

const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.0333" height="50.0333" viewBox="0 0 56.633 56.632">
    <defs>
      <linearGradient id="linear-gradient-pause" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#f0ee15" />
        <stop offset="1" stopColor={Colors.YELLOW_REACT} />
      </linearGradient>
    </defs>
    <g id="Group_1020" data-name="Group 1020" transform="translate(-1810.798 -5380.688)">
      <g id="Group_717" data-name="Group 717">
        <g id="Group_716" data-name="Group 716">
          <path id="Path_520" d="M1867.431 5409a28.317 28.317 0 1 1-28.317-28.316 28.317 28.317 0 0 1 28.317 28.316z" style={{ fill: "url(#linear-gradient-pause)" }} data-name="Path 520" />
        </g>
      </g>
      <path
        id="Line_447"
        d="M0 0v17.999"
        style={{ fill: "none", stroke: "#231f20", strokeLinecap: "round", strokeMiterlimit: 10, strokeWidth: "6.776px" }}
        data-name="Line 447"
        transform="translate(1831.658 5400.481)"
      />
      <path
        id="Line_448"
        d="M0 0v17.999"
        style={{ fill: "none", stroke: "#231f20", strokeLinecap: "round", strokeMiterlimit: 10, strokeWidth: "6.776px" }}
        data-name="Line 448"
        transform="translate(1846.48 5400.481)"
      />
    </g>
  </svg>
);
export default PauseIcon;
