import { useEffect } from "react";
import { trigger } from "../constants/events";
import { Notification } from "../controller";
import { DISCUSSION_EVENTS, EVENT_UPDATE_NOTIFICATIONS } from "../utils/Utils";

type NonEmptyArr<T> = [T, ...T[]];

let interval: any;
export default function useAckNotificationDelay() {
  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const acknowledgeNotificationDelay = (events: NonEmptyArr<string>, arrayId: number[], callback?: any) => {
    if (!arrayId.length) {
      return;
    }
    interval = setTimeout(() => {
      const arrayEquals = events.every((event) => DISCUSSION_EVENTS.includes(event));
      if (arrayEquals) {
        acknowledgeNotificationByDiscussionId(events, arrayId, callback);
      } else {
        acknowledgeNotificationByQuestionsId(events, arrayId, callback);
      }
    }, 1000);
  };

  const acknowledgeNotificationByQuestionsId = (events: NonEmptyArr<string>, arrayId: number[], callback?: any) => {
    Notification.acknowledgeByEventsAndQuestionsId(events, arrayId)
      .then(() => {
        trigger(EVENT_UPDATE_NOTIFICATIONS);
        callback?.();
      })
      .catch((error: any) => console.log(error));
  };
  const acknowledgeNotificationByDiscussionId = (events: NonEmptyArr<string>, arrayId: number[], callback?: any) => {
    Notification.acknowledgeByEventsAndDiscussionsId(events, arrayId)
      .then(() => {
        trigger(EVENT_UPDATE_NOTIFICATIONS);
        callback?.();
      })
      .catch((error: any) => console.log(error));
  };

  return { acknowledgeNotificationDelay };
}
