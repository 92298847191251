import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogYesNoDataType from "../../../interfaces/dialog/DialogYesNoDataType";
import Colors from "../../../utils/Colors";

interface DialogYesNoType {
  dialogData: DialogYesNoDataType;
  setDialogData: any;
  confirmAction?: any;
  rejectAction?: any;
}

const DialogYesNo = (props: DialogYesNoType) => {
  const { dialogData, setDialogData, rejectAction, confirmAction } = props;
  const { open, title, content } = dialogData;

  const resetDialog = () => setDialogData({ open: false, title: "", content: "" });

  return (
    <Dialog open={open} fullWidth sx={{ textAlign: "center" }}>
      <DialogTitle color={Colors.BLACK}>{title}</DialogTitle>
      {content && <DialogContent color={Colors.BLACK}>{content}</DialogContent>}
      <DialogActions>
        <Button
          sx={{ color: Colors.BLACK }}
          onClick={() => {
            rejectAction && rejectAction.function();
            resetDialog();
          }}
        >
          No
        </Button>
        <Button
          sx={{
            backgroundColor: Colors.RED_LIGHT,
            "&:hover": { backgroundColor: Colors.RED_LIGHT, opacity: 0.8 },
          }}
          onClick={() => {
            confirmAction && confirmAction.function(confirmAction?.parameters);
            resetDialog();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogYesNo;
