import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

interface RefreshComponentProps {
  refreshAction: () => void;
}

export function RefreshComponent({ refreshAction }: RefreshComponentProps) {
  return (
    <div className="refresh">
      <div className="refresh--title">This is not the latest version of the page.</div>
      <IconButton className="refresh--button" onClick={refreshAction}>
        <RefreshIcon className="refresh--button-icon" />
      </IconButton>
    </div>
  );
}
