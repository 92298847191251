import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Colors from "../../../utils/Colors";
import { countCharacters, countWords } from "../../../utils/Utils";
import SendIcon from "../icons/SendIcon";

interface MultilineQuestionAnswerType {
  onSubmit: (value: string, callback: Function) => void;
  placeholder?: string;
  initValue?: string;
  chars?: number;
  words?: number;
  rows?: number;
}

export default function MultilineQuestionAnswer({ onSubmit, initValue = "", placeholder = "", chars = 250, words = 50, rows = 3 }: MultilineQuestionAnswerType) {
  const [text, setText] = useState<string>(initValue);
  const initQuestionValidation = { text: `0 / ${chars} characters | 0 / ${words} words`, validated: false };
  const [showSendData, setShowSendData] = useState<boolean>(false);
  const [questionValidationState, setQuestionValidationState] = useState<any>(initQuestionValidation);

  const handleQuestionInput = (e: any) => {
    setQuestionValidationState(questionValidation(e.target.value));
    setText(e.target.value);
  };

  const questionValidation = (question: string) => {
    const numOfCharacters = countCharacters(question);
    const numOfWords = countWords(question);

    let returnObj = { text: `${numOfCharacters} / ${chars} characters | ${numOfWords} / ${words} words`, validated: false };

    if (numOfCharacters >= 6 && numOfCharacters <= chars && numOfWords <= words) {
      returnObj = { ...returnObj, validated: true };
    }
    return returnObj;
  };

  const clearForm = () => {
    setText("");
    setQuestionValidationState(initQuestionValidation);
  };

  return (
    <Grid container>
      <Grid item xs={12} pb={1}>
        <TextField
          size="small"
          className="multiline-text-field"
          placeholder={placeholder}
          fullWidth
          multiline
          variant="outlined"
          rows={rows}
          value={text}
          inputProps={{ maxLength: chars }}
          onChange={handleQuestionInput}
          onFocus={() => setShowSendData(true)}
          onBlur={(e: any) =>
            setTimeout(() => {
              setShowSendData(false);
            }, 200)
          }
        />
      </Grid>
      {showSendData && (
        <>
          <Grid item xs={6} color={questionValidationState?.validated ? "white" : "yellow"}>
            <Typography variant="caption">{questionValidationState?.text}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right" pb={1}>
            <Button
              onClick={() => onSubmit(text, clearForm)}
              disabled={!questionValidationState?.validated}
              endIcon={<SendIcon size="small" fill={questionValidationState?.validated ? Colors.DARK_BLUE_HEADER : "grey"} />}
              className="multiline-post-btn"
            >
              Post
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
