import { http } from "../controller/http";

export const Public = {
  getHello() {
    return http.get("/v1/test");
  },
  checkClassId(lectureCode) {
    return http.get(`/classId/${lectureCode}`);
  },
};
