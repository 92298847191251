import { Grid } from "@mui/material";

const LoginLayout = ({ children }: any) => (
  // <div className="login-layout">
  //   <div className="login-layout__bg"></div>
  //   <div className="login-layout__bg-overlay">{children}</div>
  // </div>
  <Grid container item xs={12} className="login-layout" justifyContent="center">
    <Grid container item xs={12} className="login-layout__overlay" justifyContent="center">
      {children}
    </Grid>
  </Grid>
);
export default LoginLayout;
