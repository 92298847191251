const fieldMessage = {
  NAME: "Name is required.",
  DATE: "Date is required.",
  START: "Start time is required.",
  END: "End time is required.",
};

const fieldType = {
  NAME: "title",
  DATE: "date",
  START: "start",
  END: "end",
};

export { fieldMessage, fieldType };
