import { Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import { DATE_FORMAT_STYLE_2 } from "../../../../../utils/Utils";
import EnumLectureState from "../../../../../utils/enum/EnumLectureState";
import { useLectureStatistics } from "./useLectureStatistics";

const TypographyColumnTitle = ({ content }: any) => <Typography fontSize={12}>{content}</Typography>;
const TypographyColumnData = ({ content }: any) => <Typography fontSize={12}>{content}</Typography>;
const baseClass = "statistics-admin";

interface ILectureStatistics {
  lecture?: LectureDto;
}

export const LectureStatistics = ({ lecture }: ILectureStatistics) => {
  const { data } = useLectureStatistics({ lecture });

  if (!lecture || !data) {
    return null;
  }

  return (
    <div className={`${baseClass}`} style={{ width: "94%", marginLeft: "24px" }}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={1} columnSpacing={1} pr={1} mb={2}>
        <Grid container item xs={12} key={lecture.id}>
          <Card className={`${baseClass}__card`}>
            <Grid container item xs={12} flexDirection="row">
              <Grid container item xs={12} flexDirection="column" borderBottom={"1px solid white"} borderRight={"1px solid white"} alignItems={"center"}>
                <TypographyColumnTitle content="Lecture info" />
              </Grid>
              <Grid container item xs={6} flexDirection="row" p={1}>
                <Grid container item xs={6} flexDirection="column" alignItems="flex-start">
                  <TypographyColumnTitle content="Lecture id" />
                  <TypographyColumnTitle content="Lecture num" />
                  <TypographyColumnTitle content="Pseudonym created" />
                  <TypographyColumnTitle content="New accounts" />
                  <TypographyColumnTitle content="Start time" />
                  <TypographyColumnTitle content="End time" />
                  <TypographyColumnTitle content="Lecture ended?" />
                  <TypographyColumnTitle content="Question/Answer enabled?" />
                  <TypographyColumnTitle content="Discussions enabled?" />
                </Grid>
                <Grid container item xs={6} flexDirection="column" alignItems={"flex-start"}>
                  <TypographyColumnData content={lecture.id} />
                  <TypographyColumnData content={lecture.lectureNum} />
                  <TypographyColumnData content={data?.pseudonymCreated ?? 0} />
                  <TypographyColumnData content={data?.newUsers ?? 0} />
                  <TypographyColumnData content={moment(lecture.startTime).format(DATE_FORMAT_STYLE_2)} />
                  <TypographyColumnData content={moment(lecture.endTime).format(DATE_FORMAT_STYLE_2)} />
                  <TypographyColumnData content={lecture.state === EnumLectureState.ENDED ? "Yes" : "No"} />
                  <TypographyColumnData content={lecture.allowQuestionsDuringLecture ? "Yes" : "No"} />
                  <TypographyColumnData content={lecture.allowDiscussionsDuringLecture ? "Yes" : "No"} />
                </Grid>
              </Grid>
              <Grid container item xs={6} flexDirection="row" p={1}>
                <Grid container item xs={6} flexDirection="column" alignItems={"flex-start"}>
                  <TypographyColumnTitle content="Questions posted" />
                  <TypographyColumnTitle content="Questions liked by students" />
                  <TypographyColumnTitle content="Questions liked by teacher" />
                  <TypographyColumnTitle content="Students never posted question" />
                  <TypographyColumnTitle content="Answers posted" />
                  <TypographyColumnTitle content="Answers liked by students" />
                  <TypographyColumnTitle content="Answers liked by teacher" />
                  <TypographyColumnTitle content="Students never posted answer" />
                  <TypographyColumnTitle content="Discussions" />
                </Grid>
                <Grid container item xs={6} flexDirection="column" alignItems={"flex-start"}>
                  <TypographyColumnData content={data?.questions ?? 0} />
                  <TypographyColumnData content={data?.questionsLikedStudents ?? 0} />
                  <TypographyColumnData content={data?.questionsLikedTeacher ?? 0} />
                  <TypographyColumnData content={data?.studentsNeverPostQ ?? 0} />
                  <TypographyColumnData content={data?.answers ?? 0} />
                  <TypographyColumnData content={data?.answerLikedStudents ?? 0} />
                  <TypographyColumnData content={data?.answerLikedTeacher ?? 0} />
                  <TypographyColumnData content={data?.studentsNeverPostA ?? 0} />
                  <TypographyColumnData content={data?.discussions ?? 0} />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
