// eslint-disable-next-line no-unused-vars
const notifEvents = {
  CONNECTION: "connection",
  DISCONNECTED: "disconnect",
  // ?
  SUCCESS: "success",
  ERROR: "error",
  // CHANGE_SLIDE: 'CHANGE_SLIDE', // profa -> emit, server -> on, emit
  // GET_SLIDE: 'GET_SLIDE',
  // LIVE_USERS: 'LIVE_USERS', // koliko ima live user-a,
  // USER_TOKEN: 'USER_TOKEN',
  // CONNECT_TO_COURSE: 'CONNECT_TO_COURSE',
  // ?
  NOTIFICATION: "NOTIFICATION",
};

const recvEvents = {
  // OLD
  CONNECT_TO_COURSE: "CONNECT_TO_COURSE",
  LIVE_USERS: "LIVE_USERS", // koliko ima live user-a,
  CHANGE_SLIDE: "CHANGE_SLIDE", // profa -> emit, server -> on, emit
  GET_SLIDE: "GET_SLIDE",
  LIVE_NOTIFICATION: "LIVE_NOTIFICATION",
  EXPRESSION_STAT: "EXPRESSION_STAT",

  // NEW
  ERROR_ACCESS_DENIED: "error_access_denied",
  NEW_CLASS_ID: "new_class_id",
  ACCESS_GRANTED: "access_granted",
  ERROR_INVALID_CLASS_ID: "error_invalid_classid",
  ENTERED_WAITING_ROOM: "entered_waiting_room",
  CONNECT_ERROR: "connect_error",
  ACCESS_DENIED: "access_denied",
  CONNECTED_TO_WAITING_ROOM: "connected_to_waiting_room",
  ERROR_INVALID_LECTURE_DETAILS: "error_invalid_lecture_details",
  WAITING_ROOM_NEW_CONNECTED: "WR_NEW:+",
  WAITING_ROOM_NEW_DISCONNECTED: "WR_NEW:-",
  WAITING_ROOM_NOT_REGISTERED_CONNECTED: "WR_NOREG:+",
  WAITING_ROOM_NOT_REGISTERED_DISCONNECTED: "WR_NOREG:-",
  WAITING_ROOM_REGISTERED_CONNECTED: "WR_REG:+",
  WAITING_ROOM_REGISTERED_DISCONNECTED: "WR_REG:-",
  NEW_STUDENTS_ADMITTED: "new_students_admitted",
  NON_REGISTERED_STUDENTS_ADMITTED: "non_registered_students_admitted",
  REGISTERED_STUDENTS_ADMITTED: "registered_students_admitted",
  LEFT_WAITING_ROOM: "left_waiting_room",
  KICKED_NEW_STUDENTS: "kicked_new_students",
  KICKED_NON_REGISTERED_STUDENTS: "kicked_non_registered_students",
  KICKED_REGISTERED_STUDENTS: "kicked_registered_students",
  TOTALS_RECEIVED: "len_result",
  CURRENT_CLASS_ID_RECEIVED: "current_class_id",
  ERROR_COURSE_BUSY: "error_course_busy",
  CLASS_ALREADY_ATTENDING: "already_attending",
  SIGNAL: "signal",
  NOTIFICATION: "notification",
};

const sendEvents = {
  // OLD
  USER_TOKEN: "USER_TOKEN",
  CHANGE_SLIDE: "CHANGE_SLIDE", // profa -> emit, server -> on, emit
  GET_SLIDE: "GET_SLIDE",

  // NEW
  START_CLASS_ID_GENERATION: "start",
  STOP_CLASS_ID_GENERATION: "stop",
  ADMIT_NEW_STUDENTS: "admit_new_students",
  ADMIT_NON_REGISTERED_STUDENTS: "admit_non_registered_students",
  ADMIT_REGISTERED_STUDENTS: "admit_registered_students",
  KICK_NEW_STUDENTS: "kick_new_students",
  KICK_NON_REGISTERED_STUDENTS: "kick_non_registered_students",
  KICK_REGISTERED_STUDENTS: "kick_registered_students",
  GET_CURRENT_TOTALS: "len",
  GET_CURRENT_CLASS_ID: "get_curr",
};

const recvRemoteEvents = {
  ACCESS_GRANTED: "access_granted",
  CONNECT_ERROR: "connect_error",
  ACTIVE_EXPRESSION: "active_expression",
  NO_ACTIVE_EXPRESSION: "no_active_expression",
  EXPRESSION_DEACTIVATED: "expression_deactivated",
  SLIDE_CHANGED: "slide_changed",
  EXPRESSION_UPDATE: "expression_update",
  CURRENT_SLIDE: "current_slide",
  EXPRESSION_OVERLOAD: "expression_overload",
  EXTEND_CLASS_RES: "extend_class_res",
  ALLOW_REACTIONS: "allow_reactions",
  IS_ATTENDING_RES: "is_attending_res",
  IS_END_LECTURE_RES: "is_end_lecture_res",
};

const sendRemoteEvents = {
  PRESS_BUTTON: "press_button",
  GET_EXPRESSIONS: "get_expressions",
  GET_CURRENT_SLIDE: "get_current_slide",
  GET_ACTIVE_EXPRESSION: "get_active_expression",
  SET_SLIDE: "set_slide",
  EXTEND_CLASS: "extend_class",
  EXTEND_CLASS_STOP: "extend_class_stop",
  SET_ALLOW_REACTIONS: "set_allow_reactions",
  IS_ATTENDING: "is_attending",
  IS_END_LECTURE: "is_end_lecture",
};

export const remoteEvent = {
  send: sendRemoteEvents,
  recv: recvRemoteEvents,
};

export const wsEvents = {
  send: sendEvents,
  recv: recvEvents,
};

export const NOTIFICATION_STATUS = {
  NEW: 1,
  SEND: 2,
  READ: 3,
};

export const NOTIFICATION_TYPE = {
  RECIVED_ANSWER: 1,
  RECIVED_RESPONSE: 2,
  RECIVED_BEST_Q_A: 3,
  RECIVED_VOTE: 4,
  RECIVED_FLAG: 5,
  POSTING_QUESTION: 6,
  POSTING_ANSWER: 7,
  POSTING_FLAG: 8,
};
