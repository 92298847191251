import styled from "@emotion/styled";
import { Badge, Button } from "@mui/material";

const shouldForwardProp = (prop: string) => !["style", "colorNotif", "visible"].includes(prop);
const StyledBadge = styled(Badge, {
  shouldForwardProp: shouldForwardProp,
})<{ style?: any; colorNotif?: string; visible?: boolean }>(({ style, colorNotif, visible }) => ({
  "& .MuiBadge-badge": {
    background: colorNotif,
    opacity: visible ? 1 : 0,
    padding: "0 1px",
    maxWidth: "10px",
    minWidth: "10px",
    maxHeight: "10px",
    ...(style && { ...style }),
  },
}));

const StyledButton = styled(Button)`
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.0892857143em;
  padding: 7px 15px 7px 15px;
`;

export { StyledBadge, StyledButton };
