import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Colors from "../../../utils/Colors";

interface SwitchCustomProps {
  small?: boolean;
  activeColor?: string;
  deactiveColor?: string;
}

const arrayNotForwardProp: Array<string> = ["small", "activeColor", "deactiveColor"];

const IOSSwitch = styled(Switch, {
  shouldForwardProp: (prop: string) => !arrayNotForwardProp.includes(prop),
})<SwitchCustomProps>(({ small, activeColor, deactiveColor, theme }) => ({
  width: small ? 38 : 42,
  height: small ? 20 : 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: activeColor ? activeColor : theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: small ? 16 : 22,
    height: small ? 16 : 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: deactiveColor ? deactiveColor : theme.palette.mode === "light" ? Colors.RED_SOLID : Colors.GREEN_LIGHT,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SwitchIOS({ name, value, small, onChange, disabled, activeColor, deactiveColor }: any) {
  return <IOSSwitch sx={{ m: 1 }} small={small} activeColor={activeColor} deactiveColor={deactiveColor} onChange={onChange} name={name} value={value} checked={value} disabled={disabled} />;
}
