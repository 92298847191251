const EnumSignal = Object.freeze({
  LECTURE_STARTED: "lecture_started",
  LECTURE_ENDED: "lecture_ended",
  LECTURE_UPDATED: "lecture_updated",
  REMOVED_FROM_PSEUDONYM: "removed_from_pseudonym",
  ACCOUNT_DELETED: "account_deleted",
  UNREGISTERED_COURSE: "unregistered_course",
  QUESTION_FLAGGED: "question_flagged",
  ANSWER_FLAGGED: "answer_flagged", //not in use
});

export default EnumSignal;
