import { Box, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { useAppContext } from "../../../../context/AuthContext";
import { Course, Lecture } from "../../../../controller";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import LectureNumParams from "../../../../utils/LectureNumParams";
import MessageType from "../../../../utils/MessageType";
import { extractVideoIdFromUrl } from "../../../../utils/Utils";
import EnumExpressions from "../../../../utils/enum/EnumExpressions";
import EnumExpressionsNum from "../../../../utils/enum/EnumExpressionsNum";
import EnumExpressionsText from "../../../../utils/enum/EnumExpressionsText";
import StudentBreadcrumbs from "../../student-breadcrumbs/StudentBreadcrumbs";
import RemoteItem from "../live-lecture/tab-remote/RemoteItem";

const initExpression = { [EnumExpressionsText.LOST]: false, [EnumExpressionsText.PAUSE]: false, [EnumExpressionsText.GREAT]: false, [EnumExpressionsText.REW]: false, [EnumExpressionsText.FF]: false };

const reactions = [
  {
    expressionTagNum: EnumExpressionsNum.LOST,
    expressionTag: EnumExpressionsText.LOST,
    title: "I'm lost",
  },
  {
    expressionTagNum: EnumExpressionsNum.PAUSE,
    expressionTag: EnumExpressionsText.PAUSE,
    title: "Not clear",
  },
  {
    expressionTagNum: EnumExpressionsNum.GREAT,
    expressionTag: EnumExpressionsText.GREAT,
    title: "Great!",
  },
  {
    expressionTagNum: EnumExpressionsNum.REW,
    expressionTag: EnumExpressionsText.REW,
    title: "Slow Down",
  },
  {
    expressionTagNum: EnumExpressionsNum.FF,
    expressionTag: EnumExpressionsText.FF,
    title: "Speed Up",
  },
];

const MINUTE = 60 * 1000;
type NodeTimer = NodeJS.Timeout | string | number | undefined;

export default function VideoLecture() {
  const { selectedCourse, selectedLecture, updateSelectedCourse, updateSelectedLecture, showMessage } = useAppContext();
  const lectureNum = LectureNumParams();

  const { isMobile } = useQueryMedia();
  const ytRef = useRef<YouTube | null>(null);
  const [lecture, setLecture] = useState<LectureDto | undefined>(undefined);
  const [reactionZoom, setReactionZoom] = useState<number | undefined>();
  const [expressionState, setExpressionState] = useState<{ [key: string]: boolean }>(initExpression);
  const [selectedReaction, setSelectedReaction] = useState<number>();
  const [reactionInterval, setReactionInterval] = useState<NodeTimer>(undefined);

  const opts: YouTubeProps["opts"] = {
    height: isMobile ? window.innerWidth * 0.75 : "450",
    width: isMobile ? window.innerWidth : "850",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    Lecture.addSlideInteractionsVideo(selectedCourse.id, lectureNum, event.target.getDuration())
      .then((data) => console.log(data.data))
      .catch((error) => console.log(error));
    Lecture.getLastExpressionVideo(selectedCourse.id, lectureNum)
      .then(({ data }) => {
        const lastExpressionTag: string = data.data.lastExpressionTag;
        if (Object.keys(EnumExpressions).includes(lastExpressionTag)) {
          setExpressionState({ ...initExpression, [EnumExpressions[+lastExpressionTag]]: true });
          setSelectedReaction(Number(lastExpressionTag));
          const newInterval = createInterval();
          setReactionInterval(newInterval);
        }
      })
      .catch((error) => console.log(error));
  };

  const getLecture = () => {
    Lecture.getLecture(selectedCourse.id, lectureNum)
      .then(async (lectureLoaded) => {
        if (Boolean(!selectedCourse?.name)) {
          const course = await Course.getCourse(lectureLoaded.courseId);
          updateSelectedCourse(course.data.data);
        }
        if (Boolean(!selectedLecture)) {
          updateSelectedLecture(lectureLoaded);
        }
        setLecture(lectureLoaded);
      })
      .catch((error) => showMessage(error.response?.data, MessageType.ERROR));
  };

  useEffect(() => {
    getLecture();

    return () => clearMyInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearMyInterval = () => {
    if (reactionInterval) {
      clearInterval(reactionInterval);
      setReactionInterval(undefined);
    }
  };

  const reactionAction = async (expressionTagNum: number) => {
    if (ytRef.current) {
      const ytPlayer = ytRef.current.getInternalPlayer();
      const time = await ytPlayer.getCurrentTime();
      const endTimeDuration = await ytPlayer.getDuration();
      const endTime = time + 60 > endTimeDuration ? endTimeDuration : time + 60;

      Lecture.addExpressionVideo(selectedCourse.id, lectureNum, expressionTagNum, Number(time).toFixed(1), endTime)
        .then(({ data }) => {
          const { isDeactivated, expressionTag } = data.data;
          if (isDeactivated) {
            setExpressionState(initExpression);
            setSelectedReaction(undefined);
          } else {
            if (Object.keys(EnumExpressions).includes(expressionTag)) {
              setExpressionState({ ...initExpression, [EnumExpressions[+expressionTag]]: true });
              setSelectedReaction(expressionTag);
              const newInterval = createInterval();
              setReactionInterval(newInterval);
            }
          }
        })
        .catch((error) => showMessage(error.response?.data, MessageType.ERROR));
    }
  };

  const addReaction = async (expressionTagNum: number) => {
    clearMyInterval();

    setReactionZoom(expressionTagNum);
    setTimeout(() => {
      setReactionZoom(undefined);
    }, 500);
    await reactionAction(expressionTagNum);
  };

  const createInterval = (): NodeTimer => {
    const INTERVAL_DURITATION = MINUTE; // minutes
    const newInterval: NodeTimer = setInterval(() => {
      setReactionZoom(undefined);
      setExpressionState(initExpression);

      // Clear the interval after executing once
      clearInterval(newInterval);
      setReactionInterval(undefined);
    }, INTERVAL_DURITATION);
    return newInterval;
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={2}>
        {!isMobile && (
          <Grid item container xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid item ml={{ xs: -2, sm: -2, md: 0, lg: 0, xl: 0 }} mt={{ xs: -1.5, sm: -1.5, md: 0, lg: 0, xl: 0 }}>
              <StudentBreadcrumbs />
            </Grid>
          </Grid>
        )}
        {lecture?.urlYoutube && (
          <Grid display="flex" flexDirection="column">
            <YouTube
              ref={(ref) => (ytRef.current = ref)}
              videoId={extractVideoIdFromUrl(lecture?.urlYoutube as string)}
              opts={opts}
              onError={(err) => console.log(err)}
              onReady={onPlayerReady}
              onPause={() => {
                if (selectedReaction) {
                  addReaction(selectedReaction);
                }
              }}
            />
            <Box display="flex" flexDirection="row" mt="30px" justifyContent="space-between" flexWrap={isMobile ? "wrap" : "nowrap"} gap={1} padding={1.25}>
              {reactions.map(({ expressionTagNum, title, expressionTag }) => (
                <Box key={expressionTag} sx={{ transform: reactionZoom === expressionTagNum ? "scale(1.1)" : "scale(1)", transition: "0.5s" }}>
                  <RemoteItem isVideo hideCount expressionData={[]} onClick={() => addReaction(expressionTagNum)} expressionState={expressionState} expressionTag={expressionTag} title={title} />
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}
