import { useEffect, useRef, useState } from "react";
import { wsEvents } from "../../../constants";
import { useAppContext } from "../../../context/AuthContext";
import { Discussion, SignalSocket } from "../../../controller";
import useAckNotificationDelay from "../../../hooks/useAckNotificationDelay";
import DiscussionDialogStateType from "../../../interfaces/discussion-dialog-state/DiscussionDialogState";
import DiscussionType from "../../../interfaces/discussion/DiscussionType";
import EnumNotificationEvent from "../../../utils/enum/EnumNotificationEvent";
import MessageType from "../../../utils/MessageType";
import { DISCUSSION_EVENTS } from "../../../utils/Utils";

interface DiscussionDialogServiceExport {
  discussion: DiscussionType | undefined;
  discussions: DiscussionType[];
  ref: any;
  haveStarted: boolean;
  startNew: boolean;
  handleStartNew: () => void;
  submitDiscussion: any;
  handleDiscussionCardOnClick: (discussionId: number) => void;
}

interface DiscussionDialogServiceProps {
  state: DiscussionDialogStateType;
  refreshData: () => void;
}

export default function DiscussionDialogService({ state, refreshData }: DiscussionDialogServiceProps): DiscussionDialogServiceExport {
  const { open, answer, directDiscussionId } = state;
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse?.id;
  const { acknowledgeNotificationDelay } = useAckNotificationDelay();

  const { id: answerId, lectureNum, questionId } = answer ?? { pseudonym: "" };
  const [discussions, setDiscussions] = useState<DiscussionType[]>([]);
  const [discussion, setDiscussion] = useState<DiscussionType>();
  const [directId, setDirectId] = useState<number | undefined>();
  const [startNew, setStartNew] = useState<boolean>(false);
  const [haveStarted, setHaveStarted] = useState<boolean>(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    open && fetchDiscussions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (discussion && open) {
      scrollToBottom();
      SignalSocket.on(wsEvents.recv.NOTIFICATION, handleNotification);
      return () => {
        SignalSocket.off(wsEvents.recv.NOTIFICATION, handleNotification);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussion, open]);

  useEffect(() => {
    setHaveStarted(discussions.some((d: DiscussionType) => d.respondable));
    if (discussions.length) {
      fetchDiscussion(discussions[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussions]);

  useEffect(() => {
    setDirectId(directDiscussionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directDiscussionId]);

  const handleNotification = (notification: string) => {
    if (notification === EnumNotificationEvent.DISCUSSION_REPLY && discussion?.id) {
      fetchDiscussion(discussion.id);
    }
  };

  const submitDiscussion = async (messageText: string, callback: Function) => {
    if (discussion) {
      Discussion.replyToDiscussion(courseId, lectureNum, questionId, answerId, discussion.id, { message: messageText })
        .then(() => {
          callback();
          fetchDiscussion(discussion.id);
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    } else {
      Discussion.startDiscussion(courseId, lectureNum, questionId, answerId, { message: messageText })
        .then(() => {
          callback();
          fetchDiscussions();
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
  };

  const fetchDiscussions = () => {
    Discussion.getAll(courseId, lectureNum, questionId, answerId)
      .then(({ data }: any) => {
        setDiscussions(data);
      })
      .catch((e: any) => {});
  };

  const fetchDiscussion = (discussionId: number) => {
    if (directId) {
      discussionId = directId;
      setDirectId(undefined);
    }
    Discussion.getById(courseId, lectureNum, questionId, answerId, discussionId)
      .then(({ data }: any) => {
        setDiscussion(data);
        if (data.respondable) {
          acknowledgeNotificationDelay(DISCUSSION_EVENTS, [discussionId], refreshData);
        }
      })
      .catch((e: any) => console.log(e));
  };

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  const handleDiscussionCardOnClick = (discussionId: number) => {
    fetchDiscussion(discussionId);
    setStartNew(false);
  };

  const handleStartNew = () => {
    setStartNew(!startNew);
    setDiscussion(undefined);
  };

  return { discussion, discussions, ref, haveStarted, startNew, handleStartNew, submitDiscussion, handleDiscussionCardOnClick };
}
export type { DiscussionDialogServiceExport };
