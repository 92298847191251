import { SvgIcon } from "@mui/material";
import Colors from "../../../utils/Colors";

const AnswerIcon = ({ sideLength = 24, fill = Colors.WHITE }: any) => (
  <SvgIcon
    sx={{
      paddingTop: "3px",
      width: `${sideLength} !important`,
      height: `${sideLength} !important`,
      verticalAlign: "-webkit-baseline-middle",
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.022 22.706"
  >
    <path d="M9.40508 9.17969C9.21508 4.74969 9.12008 5.41469 8.93008 5.88969L8.45508 7.78969H10.3551L9.78508 5.88969C9.69008 5.41469 9.50008 4.74969 9.40508 4.17969Z" fill={fill} />
    <path
      d="M17.1 0H1.9C0.855 0 0 0.855 0 1.9V13.3C0 14.345 0.855 15.2 1.9 15.2H15.2L19 19V1.9C19 0.855 18.145 0 17.1 0ZM11.305 11.495L10.64 9.215H8.17L7.505 11.495H5.415L8.075 2.66H10.64L13.395 11.495H11.305Z"
      fill={fill}
    />
  </SvgIcon>
);
export default AnswerIcon;
