import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Colors from "../../../../utils/Colors";

const CreateTitle = ({ title, isFirst = false }: { title: string; isFirst?: boolean }) => (
  <Typography fontWeight="bold" pt={isFirst ? 0 : 2} pb={1} color={Colors.ANSWER} variant="body1">
    {title}
  </Typography>
);

const CreateText = ({ children }: any) => (
  <Typography component="span" variant="body2">
    {children}
  </Typography>
);

const CreateImage = ({ width, image }: { width: number; image: string }) => (
  <Box component="img" sx={{ float: "left", maxWidth: `${width}vh`, objectFit: "contain", padding: "0px 10px 0px 0px" }} alt="???" src={image} />
);

const boldText = (text: string) => <Box sx={{ fontWeight: "bold", display: "inline" }}>{text}</Box>;

export { CreateTitle, CreateText, CreateImage, boldText };
