import SvgIcon from "@mui/material/SvgIcon";

const ArrowIcon = ({ color = "white", edgeLength = "1.5rem", rotate }: any) => (
  <SvgIcon style={{ width: edgeLength, height: edgeLength, transform: `rotate(${rotate}deg)` }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.71 21.71">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="_1280x740" data-name="1280x740">
        <rect style={{ fill: color }} x="8.75" y="7.4" width="0.82" height="8.32" rx="0.41" ry="0.41" transform="translate(-4.49 5.97) rotate(-29.34)" />
        <rect style={{ fill: color }} x="12.49" y="7.4" width="0.82" height="8.32" rx="0.41" ry="0.41" transform="translate(18.48 27.95) rotate(-150.66)" />
        <circle style={{ fill: "none", stroke: color }} cx="10.85" cy="10.85" r="10.35" />
      </g>
    </g>
  </SvgIcon>
);
export default ArrowIcon;
