//NEW BACKEND

import { http } from "../controller/http";

export const Answer = {
  // ANSWER ROUTES

  // Get all answers for a question
  async getAnswers(courseId, lectureNum, questionId) {
    let result = await http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answers`);
    return result;
  },

  // Get all answers by lecturesId.
  getAnswersByLecturesId: (lecturesId) => http.post(`/courses/lectures/answers/${JSON.stringify(lecturesId)}`),

  // Creates an answer
  createAnswer(courseId, lectureNum, questionId, text, teacherAnswer, teacherCanView) {
    return http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer`, {
      text,
      teacherAnswer,
      teacherCanView,
    });
  },

  // Gets a specific answer
  getAnswer(courseId, lectureNum, questionId, answerId) {
    return http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}`);
  },

  // Updates a specific answer
  updateAnswer(courseId, lectureNum, questionId, answerId, text, teacherAnswer) {
    return http.put(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}`, {
      text,
      teacherAnswer,
    });
  },

  // vote answer, up 1, down -1
  voteAnswer: (courseId, lectureNum, questionId, answerId, vote) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/vote/${vote}`),

  toggleAnswerFlag: (courseId, lectureNum, questionId, answerId) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/flag`),

  riseUpAnswer: (courseId, lectureNum, questionId, answerId) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/riseUp`),

  getHistory: (courseId, lectureNum, questionId, answerId) => http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/history`),
};

/*



*/
