import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";
import Colors from "../../../utils/Colors";

interface ButtonCustomPropsNew extends ButtonProps {
  gradient: string;
  textColor?: string;
  whiteBorder?: boolean;
  bigFont?: boolean;
  fontWeight?: string;
}
interface ButtonCustomProps {
  gradient: string;
  textColor?: string;
  whiteBorder?: boolean;
  bigFont?: boolean;
  fontWeight?: string;
  fullWidth?: boolean;
  className?: string;
  onClick?: any;
  label: string;
  disabled?: boolean;
  startIcon?: any;
  endIcon?: any;
  type?: "button" | "submit" | "reset" | undefined;
}

const getGradient = (color: string) => {
  switch (color) {
    case "green":
      return "linear-gradient(to right, #24b300 0%, #b1ff55 100%)";
    case "yellow":
      return `linear-gradient(to right, #f0ee15 0%, ${Colors.YELLOW_REACT} 100%)`;
    case "red":
      return "linear-gradient(to right, #f05e15 0%, #ff0000 100%)";
    case "purple":
      return `linear-gradient(to right, #a17fff 0%, ${Colors.PINK_REACT} 100%)`;
    case "dark-purple":
      return "linear-gradient(to right, #8c00ff 0%, #1f409d 100%)";
    case "dark-green":
      return "linear-gradient(to right, #24b200 0%, #00880e 100%)";
    case "orange":
      return "linear-gradient(to right, #fff100 0%, #fe9500 100%)";
    case "light-blue":
      return `linear-gradient(to right, ${Colors.LIGHT_BLUE_HEADER} 0%, #1f409d 100%)`;
    default:
      return `linear-gradient(to right, ${Colors.LIGHT_BLUE_HEADER} 0%, ${Colors.DARK_BLUE_HEADER} 100%)`;
  }
};

const arrayNotForwardProp: Array<string> = ["gradient", "textColor", "whiteBorder", "bigFont", "fontWeight"];

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => !arrayNotForwardProp.includes(prop.toString()),
})<ButtonCustomPropsNew>(({ gradient, textColor = "white", whiteBorder = false, bigFont = false, fontWeight = 100 }) => {
  return `
  z-index: 1;
  width: 100%;
  height: 48px;
  ${whiteBorder && "border-style: solid; border-width: 1px; border-color: white;"};
  font-size: ${bigFont ? "1rem" : "0.8rem"};
  background: ${getGradient(gradient)};
  padding: 12px 24px;
  border-radius: 30px;
  color: ${textColor};
  transition: all 550ms ease;
  cursor: pointer;
  font-weight:${fontWeight};

  &:hover {
    opacity: 0.8;
  }

  &.Mui-disabled {
    color:${textColor};
    opacity: 0.6;
  }
`;
});

export default function ButtonCustom({ label, ...props }: ButtonCustomProps) {
  return (
    <CustomButton variant="contained" {...props}>
      {label}
    </CustomButton>
  );
}
