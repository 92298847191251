import { FormControl, FormControlLabel, Typography } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";

interface CheckboxCustomProps {
  label: string;
  checked: boolean;
  onChange: any;
}

export default function CheckboxCustom({ label, checked, onChange }: CheckboxCustomProps) {
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={<Checkbox sx={{ padding: 0, color: "white" }} disableRipple color="default" inputProps={{ "aria-label": "Checkbox demo" }} checked={checked} onChange={onChange} />}
        label={
          <Typography sx={{ fontSize: 12 }} variant={"subtitle2"}>
            {label}
          </Typography>
        }
        labelPlacement="end"
      />
    </FormControl>
  );
}
