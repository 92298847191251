import { Grid, List, ListItem, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Document, Page, pdfjs } from "react-pdf";
import useExtendLecture from "../../../hooks/useExtendLecture";
import useLectureType, { UseLectureTypeExport } from "../../../hooks/useLectureType";
import Colors from "../../../utils/Colors";
import ClassId from "../../pages/teacher/class-status/class-id/ClassId";
import ClassInfoShort from "../../pages/teacher/class-status/class-info/ClassInfoShort";
import ClassPermission from "../../pages/teacher/class-status/class-permission/ClassPermission";
import ClassWaitingRoom from "../../pages/teacher/class-status/class-waiting-room/ClassWaitingRoom";
import DialogYesNo from "../dialog-yes-no/DialogYesNo";
import ArrowIcon from "../icons/ArrowIcon";
import SlidesService, { SlidesServiceExport } from "./SlidesService";
import SlidesStatusBar from "./SlidesStatusBar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface SlidesProps {
  lectureNum: number;
  isPreview: boolean;
  showClassStatus?: boolean;
  isFullscreen?: boolean;
  newConnectionWarning?: boolean;
  setNewConnectionWarning?: (val: boolean) => void;
  closeWarningStartLecture?: any;
}

export default function Slides({ lectureNum, showClassStatus, isPreview, isFullscreen, newConnectionWarning, setNewConnectionWarning, closeWarningStartLecture }: SlidesProps) {
  const { lecture, setLecture, currentPage, numPages, onDocumentLoadSuccess, handleCurrentPageSocket }: SlidesServiceExport = SlidesService({
    lectureNum,
    isPreview,
    setNewConnectionWarning,
  });
  const { presentationBlob }: UseLectureTypeExport = useLectureType({ lectureNum });
  const { showDialog, setShowDialog, confirmActionFunc, rejectActionFunc } = useExtendLecture({ lectureNum });

  const cardArray = [
    { title: "Lecture Code", content: <ClassId lectureNum={lectureNum} sideDisplay={true} /> },
    {
      title: "Waiting room",
      content: <ClassWaitingRoom lectureNum={lectureNum} sideDisplay={true} newConnectionWarning={newConnectionWarning} closeWarningStartLecture={closeWarningStartLecture} />,
    },
    { title: "Class info", content: <ClassInfoShort lectureNum={lectureNum} /> },
    { title: "Permissions", content: <ClassPermission liveLecture={lecture} setLiveLecture={setLecture} sideDisplay={true} /> },
  ];

  const sortColor = (isActive: boolean) => (isActive ? "#4e4c4cc9" : Colors.YELLOW_REACT);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={0.5} textAlign="right">
        <IconButton disabled={currentPage === 1} onClick={() => handleCurrentPageSocket(currentPage - 1)}>
          <ArrowIcon color={sortColor(currentPage === 1)} rotate={90} edgeLength="2.5rem" />
        </IconButton>
      </Grid>
      <Grid item xs={showClassStatus ? 9.5 : 11}>
        <div className={`${isPreview ? "preview-document" : isFullscreen ? "lecture-document-full" : "lecture-document"} ${showClassStatus ? "lecture-document-collapse" : ""}`}>
          <Document
            file={presentationBlob}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={(data: any) => console.log(data)}
            onLoadError={(data: any) => console.log("ERROR:" + data.message)}
            error="PDF presentation was not correctly uploaded. Please upload presentation again."
          >
            <Page key={`page_${currentPage}`} pageNumber={currentPage} scale={2} />
          </Document>
          <div className="slides-bottom-container">
            <div className="slides-bottom-show">
              <Document file={presentationBlob} error="">
                <List component={Stack} direction="row" sx={{ backgroundColor: "#f1f1f2" }}>
                  {Array.from(Array(numPages).keys()).map((num) => (
                    <ListItem key={`page_${num + 1}`} sx={{ width: "auto" }}>
                      <div onClick={() => handleCurrentPageSocket(num + 1)} className="slide-bottom" style={{ border: `1px solid ${num + 1 === currentPage ? Colors.GREEN_LIGHT : "#f1f1f2"}` }}>
                        <Page pageNumber={num + 1} />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Document>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={0.5}>
        <IconButton disabled={currentPage === numPages} onClick={() => handleCurrentPageSocket(currentPage + 1)}>
          <ArrowIcon color={sortColor(currentPage === numPages)} rotate={-90} edgeLength="2.5rem" />
        </IconButton>
      </Grid>
      {!isPreview && (
        <Grid item xs={1.5} alignSelf="flex-start" sx={{ display: showClassStatus ? "block" : "none" }}>
          <Grid container direction="column" textAlign="center" justifyContent="center" alignItems="center" rowGap={1}>
            {cardArray.map(({ content }: any, i: number) => (
              <Grid key={i} item className="class-status-card" xs={12}>
                <Grid container direction="column">
                  <Grid item padding={1}>
                    {content}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!isPreview && (
        <Grid item xs={12}>
          <SlidesStatusBar />
        </Grid>
      )}
      <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} rejectAction={rejectActionFunc} />
    </Grid>
  );
}
