import SvgIcon from "@mui/material/SvgIcon";

const DownloadIcon = ({ className = "" }: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24.295" height="22.516" viewBox="0 0 24.295 22.516" className={className}>
    <metadata>Created by potrace 1.10, written by Peter Selinger 2001-2011</metadata>
    <g transform="translate(0 -17.25)">
      <g transform="translate(0 17.25)">
        <path
          d="M23.6,228.253a.693.693,0,0,0-.7.7v6.332a3.124,3.124,0,0,1-3.119,3.119H4.512a3.124,3.124,0,0,1-3.119-3.119v-6.435a.7.7,0,0,0-1.392,0v6.435a4.516,4.516,0,0,0,4.512,4.512H19.784a4.516,4.516,0,0,0,4.512-4.512v-6.332A.7.7,0,0,0,23.6,228.253Z"
          transform="translate(0 -217.276)"
        />
        <path
          d="M140.859,34.239a.7.7,0,0,0,.49.206.679.679,0,0,0,.49-.206l4.424-4.424a.7.7,0,0,0-.985-.985l-3.233,3.238V17.946a.7.7,0,1,0-1.392,0V32.068l-3.238-3.238a.7.7,0,0,0-.985.985Z"
          transform="translate(-129.201 -17.25)"
        />
      </g>
    </g>
  </SvgIcon>
);
export default DownloadIcon;
