import SvgIcon from "@mui/material/SvgIcon";
import Colors from "../../../utils/Colors";

const QuestionOrderUp = ({ className = "" }: any) => (
  <SvgIcon viewBox="0 0 20.74 20.74" className={className}>
    <g clip-path="url(#clip0_616_6446)">
      <path
        d="M15.3999 3.7998C14.1999 3.7998 13.3999 4.9998 13.3999 6.3998C13.3999 7.89981 14.0999 8.9998 15.3999 8.9998C16.5999 8.9998 17.2999 7.89981 17.2999 6.3998C17.1999 4.9998 16.4999 3.7998 15.3999 3.7998Z"
        fill={Colors.BLUE_DIANNE}
      />
      <path
        d="M22.1999 0.0996094H8.5999C7.6999 0.0996094 6.8999 0.899609 6.8999 1.79961V17.0996L10.2999 13.6996H22.1999C23.0999 13.6996 23.8999 12.8996 23.8999 11.9996V1.79961C23.8999 0.899609 23.0999 0.0996094 22.1999 0.0996094ZM18.6999 11.7996C17.5999 11.4996 16.6999 11.0996 15.5999 10.6996C15.3999 10.5996 15.2999 10.5996 15.0999 10.5996C13.2999 10.4996 11.5999 9.19961 11.5999 6.59961C11.5999 4.19961 13.0999 2.39961 15.3999 2.39961C17.7999 2.39961 19.0999 4.19961 19.0999 6.39961C19.0999 8.19961 18.1999 9.49961 17.1999 9.89961V9.99961C17.7999 10.1996 18.4999 10.2996 19.0999 10.3996L18.6999 11.7996Z"
        fill={Colors.BLUE_DIANNE}
      />
      <path d="M3.7 13.4L5.4 13.5L2.7 17.3L0 13.4H1.7V0H3.7V13.4Z" fill={Colors.BLUE_DIANNE} />
    </g>
    <defs>
      <clipPath id="clip0_616_6446">
        <rect width="23.9" height="17.3" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default QuestionOrderUp;
