import { mdiChevronLeft } from "@mdi/js";
import { AppBar, Grid, Toolbar } from "@mui/material";
import { BackButton } from "../../../shared/components/styledComponents";
import Slides from "../../../shared/slides/Slides";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";

interface PreviewPresentationType {
  handleClose: any;
  lectureNum: number;
  fileName: string;
}
const PreviewPresentation = ({ handleClose, lectureNum, fileName }: PreviewPresentationType) => (
  <div>
    <AppBar sx={{ position: "relative", backgroundColor: "black" }}>
      <Toolbar>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={6}>
            <BackButton onClick={handleClose} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
              Close presentation
            </BackButton>
          </Grid>
          <Grid item xs={6} textAlign="right">
            Previewing: {fileName ?? ""}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    <Slides lectureNum={lectureNum} isPreview={true} />
  </div>
);

export default PreviewPresentation;
