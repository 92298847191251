import { Redirect, Route, Switch } from "react-router-dom";
import { useAppContext } from "../../context/AuthContext";
import Routes from "../../utils/Routes";
import FreeLayout from "../layout/free/FreeLayout";
import LoginLayout from "../layout/LoginLayout";
import StudentLayout from "../layout/student/StudentLayout";
import OldOrNewStudent from "../pages/old-or-new-studnet/OldOrNewStudent";
import HowItWorks from "../pages/student-and-teacher/how-it-works/HowItWorks";
import MyAnswersTabs from "../pages/student-and-teacher/my-answers/MyAnswersTabs";
import MyQuestionTabs from "../pages/student-and-teacher/my-questions/MyQuestionTabs";
import Notifications from "../pages/student-and-teacher/notifications/Notifications";
import Settings from "../pages/student-and-teacher/settings/Settings";
import LiveLecture from "../pages/student/live-lecture/LiveLecture";
import MyCourses from "../pages/student/my-courses/MyCourses";
import MyDiscussionsTabs from "../pages/student/my-discussions/MyDiscussionsTabs";
import MyLectures from "../pages/student/my-lectures/MyLectures";
import PickCard from "../pages/student/pick-card/PickCard";
import QuestionAnswers from "../pages/student/question-answers/QuestionAnswers";
import VideoLecture from "../pages/student/video-lecture/VideoLecture";
import { WaitingRoom } from "../pages/student/waiting-room/WaitingRoom";
import { FeedbackPage } from "../shared/feedback-page/FeedbackPage";

const studentWrap = (component: JSX.Element) => <StudentLayout>{component}</StudentLayout>;
const freeWrap = (component: JSX.Element) => <FreeLayout>{component}</FreeLayout>;
const loginWrap = (component: JSX.Element) => <LoginLayout>{component}</LoginLayout>;

export const PrivateRouteStudent = () => {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse?.id;

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={Routes.STUDENT_MY_COURSE} />} />
      <Route exact path={Routes.OLD_OR_NEW_STUDENT} component={() => loginWrap(<OldOrNewStudent />)} />
      <Route exact path={Routes.PICK_CARD} component={() => freeWrap(<PickCard />)} />
      <Route exact path={Routes.STUDENT_MY_COURSE} component={() => studentWrap(<MyCourses />)} />
      <Route exact path={Routes.STUDENT_WAITING_ROOM} component={() => loginWrap(<WaitingRoom />)} />

      <Route exact path={Routes.STUDENT_SETTINGS} component={() => studentWrap(<Settings />)} />
      <Route exact path={Routes.STUDENT_HOW_IT_WORKS} component={() => studentWrap(<HowItWorks />)} />
      <Route exact path={Routes.FEEDBACK} component={() => studentWrap(<FeedbackPage />)} />

      {courseId && (
        <>
          <Route exact path={Routes.STUDENT_MY_LECTURES} component={() => studentWrap(<MyLectures />)} />
          <Route exact path={Routes.LECTURE_LIVE} component={() => freeWrap(<LiveLecture />)} />
          <Route exact path={Routes.LECTURE_VIDEO} component={() => freeWrap(<VideoLecture />)} />
          <Route exact path={Routes.STUDENT_MY_QUESTIONS} component={() => studentWrap(<MyQuestionTabs />)} />
          <Route exact path={Routes.STUDENT_MY_ANSWERS} component={() => studentWrap(<MyAnswersTabs />)} />
          <Route exact path={Routes.STUDENT_MY_DISCUSSIONS} component={() => studentWrap(<MyDiscussionsTabs />)} />
          <Route exact path={Routes.PAGE_QUESTION_ANSWERS} component={() => freeWrap(<QuestionAnswers />)} />
          <Route exact path={Routes.STUDENT_NOTIFICATIONS} component={() => studentWrap(<Notifications />)} />
        </>
      )}
    </Switch>
  );
};
