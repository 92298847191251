import { Box, Grid } from "@mui/material";
import OutlinedInputCustom from "../../../../shared/outlined-input-custom/OutlinedInputCustom";
import ClassInfoShortService, { ClassInfoShortServiceType } from "./ClassInfoShortService";

export default function ClassInfoShort({ lectureNum }: any) {
  const { pseudonyms }: ClassInfoShortServiceType = ClassInfoShortService({ lectureNum });
  const upperCase = { typography: "subtitle2", textTransform: "uppercase", fontSize: "12px" };

  return (
    <div className="class-status">
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Box sx={upperCase}>Pseudonyms issued</Box>
        </Grid>
        <Grid item xs={12}>
          <OutlinedInputCustom className="text-field" inputProps={{ style: { textAlign: "center", padding: "4px" } }} readOnly={true} value={pseudonyms} />
        </Grid>
      </Grid>
    </div>
  );
}
