import { SvgIcon } from "@mui/material";
import Colors from "../../../utils/Colors";

const QuestionIcon = ({ sideLength = 24, fill = Colors.WHITE }: any) => (
  <SvgIcon
    sx={{ width: `${sideLength} !important`, height: `${sideLength} !important`, verticalAlign: "-webkit-baseline-middle" }}
    xmlns="http://www.w3.org/2000/svg"
    width={sideLength}
    height={sideLength}
    viewBox="0 0 23.022 22.706"
  >
    <path
      d="M9.49994 4.08496C8.16994 4.08496 7.31494 5.41496 7.31494 7.02996C7.31494 8.73996 8.07494 9.97496 9.49994 9.97496C10.8299 9.97496 11.5899 8.73996 11.5899 7.02996C11.5899 5.41496 10.8299 4.08496 9.49994 4.08496Z"
      fill={fill}
    />
    <path
      d="M17.1 0H1.9C0.855 0 0 0.855 0 1.9V19L3.8 15.2H17.1C18.145 15.2 19 14.345 19 13.3V1.9C19 0.855 18.145 0 17.1 0ZM13.205 13.015C11.97 12.635 10.925 12.255 9.69 11.78C9.5 11.685 9.31 11.685 9.12 11.685C7.125 11.59 5.225 10.07 5.225 7.22C5.225 4.56 6.935 2.565 9.5 2.565C12.16 2.565 13.68 4.56 13.68 7.03C13.68 9.025 12.73 10.45 11.59 10.925V11.02C12.255 11.21 13.015 11.4 13.68 11.495L13.205 13.015Z"
      fill={fill}
    />
  </SvgIcon>
);
export default QuestionIcon;
