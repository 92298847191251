import Colors from "../Colors";

const EnumExpressionColors: any = Object.freeze({
  1: Colors.RED_LIGHT,
  2: Colors.YELLOW_REACT,
  3: Colors.GREEN_SOLID,
  4: Colors.DARK_BLUE_HEADER,
  5: Colors.PINK_REACT,
});

export default EnumExpressionColors;
