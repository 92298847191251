import Routes from "../../../utils/Routes";

const PUBLIC_ROUTE = [
  Routes.ABOUT,
  Routes.TEACHER_LOGIN,
  Routes.REGISTER_TEACHER,
  Routes.REGISTER_STUDENT,
  Routes.OLD_OR_NEW_STUDENT,
  Routes.PICK_CARD,
  Routes.STUDENT_WAITING_ROOM,
  Routes.STUDENT_DOWNLOAD_PASSWORD,
];
const PAGE_HIDE_LIVE_LECTURE: string[] = [Routes.OLD_OR_NEW_STUDENT, Routes.LECTURE_LIVE, Routes.TEACHER_CLASS_STATUS, ...PUBLIC_ROUTE];

export { PAGE_HIDE_LIVE_LECTURE };
