import { Dispatch, SetStateAction, useState } from "react";
import { trigger } from "../../../constants/events";
import { useAppContext } from "../../../context/AuthContext";
import { Answer, Notification } from "../../../controller";
import AnswerType from "../../../interfaces/answer/AnswerType";
import DiscussionDialogStateType from "../../../interfaces/discussion-dialog-state/DiscussionDialogState";
import { VoteType } from "../../../interfaces/Types";
import EnumPropName from "../../../utils/enum/EnumPropName";
import MessageType from "../../../utils/MessageType";
import { EVENT_UPDATE_NOTIFICATIONS } from "../../../utils/Utils";

interface AnswerCardServiceExport {
  voteAnswer: (val: VoteType) => void;
  toggleFlag: () => void;
  riseUpAnswer: () => void;
  handleOpenDiscussion: (answer: AnswerType) => void;
  handleCloseDiscussion: () => void;
  readNotification: (notificationId: number) => void;
  handleEditAnswer: (answerText: string) => void;
  discussionDialogState: DiscussionDialogStateType;
  editAnswer: string;
  handleEditIcon: () => void;
  showEditField: boolean;
  openHistoryDialog: boolean;
  setOpenHistoryDialog: Dispatch<SetStateAction<boolean>>;
}

interface AnswerCardServiceProps {
  id: number;
  text: string;
  selfPosted: boolean;
  courseId: number;
  lectureNum: number;
  callbackRefresh: any;
  questionId: number;
}

const initDiscussionDialogState = { open: false, answer: undefined };
export default function AnswerCardService({ id, text, selfPosted, courseId, lectureNum, callbackRefresh, questionId }: AnswerCardServiceProps): AnswerCardServiceExport {
  const { showMessage } = useAppContext();
  const [editAnswer, setEditAnswer] = useState<string>("");
  const [showEditField, setShowEditField] = useState<boolean>(false);
  const [discussionDialogState, setDiscussionDialogState] = useState<DiscussionDialogStateType>(initDiscussionDialogState);
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);

  const isSelfPosted = () => {
    selfPosted && showMessage("Can't interact with your question!", MessageType.INFO);
    return selfPosted;
  };

  const toggleFlag = () => {
    if (isSelfPosted()) return;
    Answer.toggleAnswerFlag(courseId, lectureNum, questionId, id)
      .then(() => {
        // callbackRefresh(id, EnumPropName.SELF_FLAGGED, data);
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const riseUpAnswer = () => {
    if (isSelfPosted()) return;
    Answer.riseUpAnswer(courseId, lectureNum, questionId, id)
      .then(() => {
        // callbackRefresh(id, EnumPropName.RISE_UP, data);
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const voteAnswer = (vote: VoteType) => {
    if (isSelfPosted()) return;
    Answer.voteAnswer(courseId, lectureNum, questionId, id, vote)
      .then(({ data }: any) => {
        // callbackRefresh(id, EnumPropName.VOTE, data as { numUpVote: string; numDownVote: string });
        callbackRefresh();
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  const readNotification = (notificationId: number) => {
    Notification.acknowledgeById(notificationId)
      .then((res: any) => {
        callbackRefresh(id, EnumPropName.MARK_AS_READ, undefined);
        trigger(EVENT_UPDATE_NOTIFICATIONS);
      })
      .catch((err: any) => console.log(err));
  };
  const handleEditIcon = () => {
    setEditAnswer(text);
    setShowEditField(!showEditField);
  };

  const handleEditAnswer = async (answerText: string) => {
    Answer.updateAnswer(courseId, lectureNum, questionId, id, answerText, false)
      .then((res: any) => {
        callbackRefresh();
        setShowEditField(false);
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };
  const handleOpenDiscussion = (answer: AnswerType) => setDiscussionDialogState({ open: true, answer: answer });
  const handleCloseDiscussion = () => setDiscussionDialogState(initDiscussionDialogState);

  return {
    voteAnswer,
    toggleFlag,
    riseUpAnswer,
    handleOpenDiscussion,
    handleCloseDiscussion,
    readNotification,
    handleEditAnswer,
    handleEditIcon,
    discussionDialogState,
    editAnswer,
    showEditField,
    openHistoryDialog,
    setOpenHistoryDialog,
  };
}
export type { AnswerCardServiceExport };
