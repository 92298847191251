import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";

export const Containter = styled.div`
  margin-top: 40px;
`;

export const Title = styled(Typography)`
  color: white;
`;

export const FieldText = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;
