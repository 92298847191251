import { useState } from "react";
import { useAppContext } from "../../../../../context/AuthContext";
import { Lecture } from "../../../../../controller";
import { useEffectOnce } from "../../../../../utils/Utils";

interface ClassInfoShortServiceType {
  pseudonyms: number;
}

export default function ClassInfoShortService({ lectureNum }: any): ClassInfoShortServiceType {
  const { selectedCourse } = useAppContext();
  const [pseudonyms, setPseudonyms] = useState<number>(0);

  useEffectOnce(() => {
    Lecture.classStatusInfo(selectedCourse.id, lectureNum)
      .then((res: any) => setPseudonyms(res.data.data.attending))
      .catch((err: any) => console.log(err));
  });

  return { pseudonyms };
}

export type { ClassInfoShortServiceType };
