const EnumSidemenuIcon = Object.freeze({
  COURSES: 1,
  LECTURES: 2,
  LIVE_LECTURE: 3,
  JOIN_LECTURE: 4,
  STATS: 5,
  MY_QUESTIONS: 6,
  MY_ANSWERS: 7,
  NOTIFICATIONS: 8,
  SETTINGS: 9,
  HELP: 10,
  LOGOUT: 11,
  MY_DISCUSSIONS: 12,
  FEEDBACK: 13,
  ADMIN_STATS: 14,
});

export default EnumSidemenuIcon;
