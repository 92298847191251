import { Grid, Typography } from "@mui/material";
import DiscussionType from "../../interfaces/discussion/DiscussionType";
import MessageType from "../../interfaces/message/MessageType";
import Colors from "../../utils/Colors";
import { getTitleQAD } from "../../utils/Utils";
interface ChatMessagesType {
  innerRef: any;
  discussion: DiscussionType | undefined;
}

export default function ChatMessages({ innerRef, discussion }: ChatMessagesType) {
  if (!discussion) return null;
  const { answerPosterPseudonym, messages }: DiscussionType = discussion;

  return (
    <Grid container className="discussion-dialog-chat-container" padding={1} ref={innerRef}>
      {messages
        .sort((a: MessageType, b: MessageType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map(({ createdAt, pseudonym, message, teacherPost }: MessageType, i: number) => {
          const myPost = answerPosterPseudonym !== pseudonym;
          return (
            <Grid key={i} container justifyContent={myPost ? "flex-end" : "flex-start"}>
              <Grid item maxWidth="70%">
                <Typography padding={1} color={Colors.BLUE_DIANNE} variant="inherit">
                  {getTitleQAD(pseudonym, teacherPost, createdAt)}
                </Typography>
                <Grid container className={`discussion-dialog-chat-box-${myPost ? "right" : "left"}`} item padding={1.5}>
                  {message}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}
