import { Redirect, Route, Switch } from "react-router-dom";
import Routes from "../../utils/Routes";
import FreeLayout from "../layout/free/FreeLayout";
import LoginLayout from "../layout/LoginLayout";
import About from "../pages/about/About";
import OldOrNewStudent from "../pages/old-or-new-studnet/OldOrNewStudent";
import RegisterStudent from "../pages/register-student/RegisterStudent";
import RegisterTeacher from "../pages/register-teacher/RegisterTeacher";
import { DownloadPassword } from "../pages/student/download-password/DownloadPassword";
import { DownloadPasswordMobile } from "../pages/student/download-password/DownloadPasswordMobile";
import PickCard from "../pages/student/pick-card/PickCard";
import { WaitingRoom } from "../pages/student/waiting-room/WaitingRoom";
import TeacherLogin from "../pages/teacher-login/TeacherLogin";

export const PublicRoute = ({ isTeacher }: any) => {
  const loginWrap = (component: JSX.Element) => <LoginLayout>{component}</LoginLayout>;
  const freeWrap = (component: JSX.Element) => <FreeLayout>{component}</FreeLayout>;

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={Routes.ABOUT} />} />
      <Route exact path={Routes.ABOUT} component={() => loginWrap(<About />)} />
      <Route exact path={Routes.TEACHER_LOGIN} component={() => loginWrap(<TeacherLogin />)} />
      <Route exact path={Routes.REGISTER_TEACHER} component={() => loginWrap(<RegisterTeacher />)} />
      <Route exact path={Routes.REGISTER_STUDENT} component={() => loginWrap(<RegisterStudent />)} />
      <Route exact path={Routes.OLD_OR_NEW_STUDENT} component={() => loginWrap(<OldOrNewStudent />)} />
      <Route exact path={Routes.PICK_CARD} component={() => freeWrap(<PickCard />)} />
      <Route exact path={Routes.STUDENT_WAITING_ROOM} component={() => freeWrap(<WaitingRoom />)} />
      <Route exact path={Routes.STUDENT_DOWNLOAD_PASSWORD} component={() => loginWrap(<DownloadPassword />)} />
      <Route exact path={Routes.STUDENT_DOWNLOAD_PASSWORD_MOBILE} component={() => loginWrap(<DownloadPasswordMobile />)} />
      <Route path="/" component={() => loginWrap(isTeacher ? <TeacherLogin /> : <RegisterStudent />)} />
    </Switch>
  );
};
