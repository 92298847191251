import { mdiChevronLeft } from "@mdi/js";
import { Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import hand_pointer from "../../../../assets/icons/hand_pointer.svg";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import Colors from "../../../../utils/Colors";
import { extractVideoIdFromUrl } from "../../../../utils/Utils";
import EnumExpressionColors from "../../../../utils/enum/EnumExpressionColors";
import EnumExpressions from "../../../../utils/enum/EnumExpressions";
import { BackButton } from "../../../shared/components/styledComponents";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import TeacherBreadcrumbs from "../../teacher-breadcrumbs/TeacherBreadcrumbs";
import BarChart from "./BarChart";
import SlideStatisticsService, { SlideStatisticsServiceExport } from "./SlideStatisticsService";
import { segmentList } from "./config";

function SlideStatistics() {
  const history = useHistory();
  const {
    convertSeconds,
    handleSortBy,
    segmentBy,
    slideNumber,
    handleBackward,
    handleForward,
    handleMouseMove,
    chartContainerRef,
    coords,
    lineTime,
    pickedData,
    maxExpression,
    setShowLegend,
    showLegend,
    combinedData,
    maxValuePerExpression,
    percentagePerExpression,
    expressionData,
    graphWidth,
    noPresentation,
    urlVideo,
    onStateChange,
  }: SlideStatisticsServiceExport = SlideStatisticsService();
  const { isMobile } = useQueryMedia();

  const percentageContainer = (percent: any, color: string, index: number, expression?: boolean) => {
    return (
      <Grid
        container
        item
        xs={12}
        sx={{ maxWidth: "38px !important", height: "38px", backgroundColor: color, borderRadius: "12px", color: index === 1 ? Colors.BLUE_DIANNE : "white" }}
        justifyContent="center"
        alignItems="center"
        key={index}
      >
        {expression ? (
          <Grid container item>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Typography color={index === 1 ? Colors.BLUE_DIANNE : "white"} fontSize="11px">
                {"0 - " + percent}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography color={index === 1 ? Colors.BLUE_DIANNE : "white"} fontSize="11px">{`${percent}%`}</Typography>
        )}
      </Grid>
    );
  };

  const statsPercentage = (percent: any, color: string, index: number, expression?: boolean) => {
    return (
      <Grid container item xs={12} sx={{ height: "80px" }} justifyContent="center" alignItems="flex-end" key={index}>
        {index === 0 && (
          <Grid container item justifyContent="center" xs={12}>
            <Typography sx={{ color: "white", fontSize: "12px" }}>{expression ? "Range" : "Average"}</Typography>
          </Grid>
        )}
        {percentageContainer(percent, color, index, expression)}
      </Grid>
    );
  };

  const showPickedData = (pickedData: [any]) => {
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          position: "absolute",
          left: coords.x + 10,
          top: coords.y,
          zIndex: 1003,
          backgroundColor: "white",
          borderRadius: 5,
          height: 100,
          width: 80,
          padding: 1,
        }}
      >
        {pickedData?.map((data: number, index: number) => {
          return (
            <Grid key={index} container item xs={12} justifyContent="flex-start" alignItems="center">
              <Grid item style={{ backgroundColor: EnumExpressionColors[index + 1], width: "10px", height: "10px", borderRadius: "5px" }}></Grid>
              <Grid item style={{ fontSize: "10px", marginLeft: "5px", marginRight: "5px", color: Colors.BLUE_DIANNE }}>
                {data !== undefined ? data : ""}
              </Grid>
              <Grid item style={{ fontSize: "10px", color: Colors.BLUE_DIANNE }}>
                {EnumExpressions[index + 1]}
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Typography style={{ fontSize: "11px", color: Colors.BLUE_DIANNE }}>{convertSeconds(lineTime)}</Typography>
        </Grid>
      </Grid>
    );
  };

  const showTime = () => {
    return (
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{
          position: "absolute",
          left: coords.x - 15,
          bottom: "15px",
          zIndex: 1003,
          backgroundColor: "white",
          borderRadius: 5,
          height: 20,
          width: convertSeconds(lineTime).length * 8,
          padding: 1,
          fontSize: "10px",
        }}
      >
        {convertSeconds(lineTime)}
      </Grid>
    );
  };

  const youtubePlayer = () => (
    <Grid display="flex" flexDirection="column">
      <YouTube videoId={extractVideoIdFromUrl(urlVideo)} onError={(err) => console.log(err)} onStateChange={onStateChange} />
    </Grid>
  );

  return (
    <Grid container item xs={12} justifyContent="center" minWidth={!isMobile ? "1000px" : ""} width={!isMobile ? "1000px" : ""}>
      <Grid container item xs={9}>
        <TeacherBreadcrumbs />
      </Grid>
      <Grid container item xs={9} justifyContent="center">
        <Grid container item xs={12} justifyContent="space-between" mb={2}>
          <BackButton onClick={() => history.go(-1)} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
            Back
          </BackButton>
          <SelectCustom title="Resolution" value={segmentBy} handleChange={handleSortBy} options={segmentList} />
        </Grid>
        {urlVideo && youtubePlayer()}
        <Grid container item xs={12} justifyContent="center" sx={{ marginBottom: "10px" }}>
          <Grid item sx={{ marginBottom: "10px" }} xs={1}></Grid>
          <Grid item sx={{ marginBottom: "10px" }} xs={10} ref={chartContainerRef}></Grid>
          <Grid item sx={{ marginBottom: "10px" }} xs={1}></Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" wrap="nowrap">
          <Grid container item xs={1} minWidth={"90px"} justifyContent="flex-end" alignItems="flex-end" style={{ marginBottom: "30px" }}>
            {maxValuePerExpression?.map((data: any, index: number) => {
              return statsPercentage(data.maxValue, EnumExpressionColors[data.tag], index, true);
            })}
            <Grid container item xs={12} justifyContent="center" alignItems="flex-end" style={{ height: "40px", color: "white" }}>
              <Typography fontSize="13px">Time</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            width={graphWidth}
            style={{
              backgroundColor: "transparent",
              position: "relative",
              marginBottom: "30px",
            }}
          >
            <Grid container item width={graphWidth} style={{ position: "relative" }} onMouseEnter={() => setShowLegend(true)} onMouseLeave={() => setShowLegend(false)}>
              {combinedData?.map(({ data, tag }: any, i: number) => {
                return (
                  <Grid key={i} container item width={graphWidth} sx={{ height: "80px" }} alignItems="flex-end">
                    <BarChart key={i} width={graphWidth} height={70} maxValue={maxExpression} segmentNum={segmentBy} data={data} color={EnumExpressionColors[+tag]} />
                  </Grid>
                );
              })}

              <Grid container item width={graphWidth} height="40px" onMouseMove={handleMouseMove} onMouseEnter={() => setShowLegend(false)} zIndex={1002} onMouseLeave={() => setShowLegend(true)}>
                <Grid container item width={graphWidth} height="20px"></Grid>
                <Grid container item width={graphWidth} height="20px" alignItems="center" style={{ cursor: "url(" + hand_pointer + "), auto" }}>
                  {!showLegend && showTime()}
                  <Grid item width={graphWidth} style={{ backgroundColor: "white", height: "3px" }} />
                </Grid>
              </Grid>
              <Grid container item width={graphWidth} position="absolute" top={0} left={0} height="100%" zIndex={1000} onMouseMove={handleMouseMove}></Grid>
              <Grid
                item
                style={{
                  position: "absolute",
                  left: coords.x,
                  zIndex: 1001,
                  borderStyle: "dotted",
                  borderWidth: 1,
                  borderRadius: 1,
                  borderColor: Colors.YELLOW_SOLID,
                  height: "98%",
                }}
              ></Grid>
              {showLegend && showPickedData(pickedData)}
            </Grid>
          </Grid>
          <Grid container item xs={1} minWidth={"90px"} justifyContent="flex-end" alignItems="flex-end" style={{ marginBottom: "30px" }}>
            {percentagePerExpression?.map((percent: number, index: number) => {
              return statsPercentage(percent, EnumExpressionColors[index + 1], index);
            })}
            <Grid container item xs={12} justifyContent="center" alignItems="flex-end" style={{ height: "40px", color: "white" }}>
              <Grid container item xs={12} height="20px" alignItems="center">
                <Grid item width={3} style={{ backgroundColor: "white", height: "7px" }} />
                <Grid item width={3} style={{ backgroundColor: "white", height: "3px" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!noPresentation && !urlVideo && (
          <>
            <Grid container item minWidth={graphWidth} maxWidth={graphWidth} style={{ marginTop: "-30px" }} justifyContent="space-between">
              <Grid container item width={50} justifyContent="flex-start">
                <Typography fontSize="13px">{"0:0"}</Typography>
              </Grid>
              <Grid container item width={50} justifyContent="flex-end" marginRight={"-15px"}>
                <Typography fontSize="13px">{convertSeconds(expressionData && expressionData.duritationSec)}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={5} justifyContent="flex-end" style={{ color: "white" }}>
                <Button onClick={() => handleBackward()}>{"<"}</Button>
              </Grid>
              <Grid container item xs={2} justifyContent="center" style={{ color: "white" }} alignItems="center">
                {slideNumber}
              </Grid>
              <Grid container item xs={5} justifyContent="flex-start" style={{ color: "white" }}>
                <Button onClick={() => handleForward()}>{">"}</Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default SlideStatistics;
