import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

interface MenuAnonProps {
  id: string;
  open: boolean;
  anchorEl: any;
  handleClose: () => void;
  items: {
    label: string;
    action: () => void;
  }[];
}

export default function MenuAnon({ id, open, anchorEl, handleClose, items }: MenuAnonProps) {
  return (
    <Menu
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      MenuListProps={{
        "aria-labelledby": id,
      }}
    >
      {items.map(({ label, action }) => (
        <MenuItem key={label} sx={{ fontSize: 11 }} onClick={action}>
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
}
