const EnumNotificationEvent = Object.freeze({
  QUESTION_POSTED: "question_posted",
  QUESTION_ANSWERED: "question_answered",
  DISCUSSION_STARTED: "discussion_started",
  DISCUSSION_REPLY: "discussion_reply",
  QUESTION_FLAGGED: "question_flagged",
  ANSWER_FLAGGED: "answer_flagged",
});

export default EnumNotificationEvent;
