import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import CourseDto from "../../../../interfaces/course/CourseDto";
import Colors from "../../../../utils/Colors";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT_STYLE } from "../../../../utils/Utils";
import { AddButton } from "../../../shared/components/styledComponents";
import GridCard from "../../../shared/grid-card/GridCard";
import AddIcon from "../../../shared/icons/AddIcon";
import MyCoursesService, { MyCoursesServiceType } from "./MyCoursesService";

export default function MyCourses() {
  const { updateSelectedCourse, selectedCourse } = useAppContext();
  const history = useHistory();
  const { courses }: MyCoursesServiceType = MyCoursesService();

  return (
    <div className="my-courses">
      <div className="course-card__subtext">{courses?.length ? "My Courses" : "There Are Currently No Courses."}</div>
      <Box sx={{ pb: 2 }}>
        <AddButton startIcon={<AddIcon />} onClick={() => history.push(Routes.TEACHER_MY_COURSES_ADD)}>
          Add a New Course
        </AddButton>
      </Box>
      <Grid container justifyContent={{ xs: "center", sm: "center", md: "flex-start", lg: "flex-start", xl: "flex-start" }} alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
        {courses?.map(({ id, name, startDate, shortName }: CourseDto, index: number) => (
          <Grid key={id} item>
            <GridCard
              position={index + 1}
              title={startDate ? moment(startDate).format(DATE_FORMAT_STYLE) : ""}
              cardAction={() => {
                history.push(`${Routes.TEACHER_MY_LECTURES}`);
                updateSelectedCourse({ id, name, shortName, startDate });
              }}
              headerAction={(event: any) => {
                event.stopPropagation();

                history.push(`${Routes.TEACHER_MY_COURSE}/edit/${id}`);
              }}
              showEdit={true}
              name={name}
              active={selectedCourse?.id === id}
              color={Colors.COURSE}
            />
          </Grid>
        ))}
        {courses && courses?.length / 2 !== 0 && <Grid container className="courses-card__fake-card" item></Grid>}
      </Grid>
    </div>
  );
}
