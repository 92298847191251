import { Box, Typography } from "@mui/material";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import ExpressionDto from "../../../../../interfaces/expression/ExpressionDto";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import FFIcon from "../../../../shared/icons/FFIcon";
import GreatIcon from "../../../../shared/icons/GreatIcon";
import LostIcon from "../../../../shared/icons/LostIcon";
import PauseIcon from "../../../../shared/icons/PauseIcon";
import QuestionMarkIcon from "../../../../shared/icons/QuestionMarkIcon";
import RewIcon from "../../../../shared/icons/RewIcon";

interface RemoteItemType {
  expressionData: ExpressionDto[];
  expressionState: { [key: string]: boolean };
  onClick: (expStr: string) => void;
  title: string;
  expressionTag: string;
  hideCount?: boolean;
  isVideo?: boolean;
}

const CIRCULAR_MAX_VALUE = 100;
const CIRCULAR_SIZE = 95;
const CIRCULAR_VALUE = 85;

const expressionDataInit = { count: 0, percentCount: 0, totalExpressions: 0, expressionTag: 0, id: "0" };

export default function RemoteItem({ expressionData, onClick, expressionState, title, expressionTag, hideCount, isVideo, ...props }: CircularProgressProps & RemoteItemType) {
  const expressionDataByName: ExpressionDto = expressionData.find((item: any) => item.id === expressionTag) ?? expressionDataInit;
  const voted = expressionState[expressionTag];

  const handleExpressionClick = () => onClick(expressionTag);

  const mapExpressionToIcon = () => {
    switch (expressionTag) {
      case EnumExpressionsText.LOST:
        return <LostIcon />;
      case EnumExpressionsText.PAUSE:
        return isVideo ? <QuestionMarkIcon /> : <PauseIcon />;
      case EnumExpressionsText.GREAT:
        return <GreatIcon />;
      case EnumExpressionsText.FF:
        return <FFIcon />;
      case EnumExpressionsText.REW:
        return <RewIcon />;
      default:
        console.error("error expression icon");
        return;
    }
  };

  const mapExpressionPercentToColor = () => {
    switch (expressionTag) {
      case EnumExpressionsText.LOST:
        return "#f72f0a";
      case EnumExpressionsText.PAUSE:
        return "#f7df0a";
      case EnumExpressionsText.GREAT:
        return "#68d729";
      case EnumExpressionsText.FF:
        return "#a240df";
      case EnumExpressionsText.REW:
        return "#108dff";
      default:
        console.error("error expression color");
        return;
    }
  };

  return (
    <div className="remote-item">
      <div className={`remote-item remote-item--${expressionTag} ${voted && "remote-item--voted"}`}>
        <Box sx={{ position: "relative", display: "block" }}>
          {!hideCount && (
            <Typography variant="caption" component="div" style={{ textAlign: "center", position: "relative", top: "8px" }}>
              {expressionDataByName.count}
            </Typography>
          )}
          {(Number(expressionDataByName.percentCount) > 0 || hideCount) && (
            <CircularProgress
              {...props}
              size={CIRCULAR_SIZE}
              thickness={2.2}
              variant="determinate"
              value={hideCount && voted ? CIRCULAR_MAX_VALUE : +expressionDataByName.percentCount / (100 / CIRCULAR_VALUE)}
              sx={{ transform: "rotate(-65deg) scale(1.05)!important ", color: `${mapExpressionPercentToColor()} !important`, position: "absolute", zIndex: 100 }}
            />
          )}
          <CircularProgress
            {...props}
            size={CIRCULAR_SIZE}
            thickness={0.5}
            variant="determinate"
            value={hideCount ? CIRCULAR_MAX_VALUE : CIRCULAR_VALUE}
            sx={{ transform: "rotate(-65deg) !important", color: "#39a5c0 !important" }}
          />
          <Box
            sx={{
              top: hideCount ? 0 : "22px",
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ cursor: "pointer", zIndex: 101 }} onClick={handleExpressionClick}>
              {mapExpressionToIcon()}
            </div>
          </Box>
        </Box>
        <div className="remote-item__title">{title || expressionTag}</div>
      </div>
    </div>
  );
}
