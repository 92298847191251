import SvgIcon from "@mui/material/SvgIcon";

const StudentIcon = ({ className = "" }: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ stroke: "black", fill: "none", width: "60px", strokeWidth: "1.5" }} className={className}>
    <g id="Group_127" data-name="Group 127" transform="translate(-3099.137 -406.394)">
      <path id="Path_81" data-name="Path 81" d="M3123.352,420.174l-13.857-4.167v7.013c0,2.071,6.928,4.752,13.857,4.752s13.856-2.681,13.856-4.752v-7.013h0Z" />
      <path id="Path_82" data-name="Path 82" d="M3123.352,420.174l-21.61-6.5,21.61-6.5,21.61,6.5Z" style={{ fill: "none", strokeMiterlimit: 10, strokeWidth: "1.5" }} />
      <line id="Line_18" data-name="Line 18" y2="16.637" transform="translate(3141.84 415.322)" style={{ fill: "none", strokeMiterlimit: 10, strokeWidth: "1.5" }} />
      <circle id="Ellipse_5" data-name="Ellipse 5" cx="2.23" cy="2.23" r="2.23" transform="translate(3139.61 425.078)" />
    </g>
  </SvgIcon>
);
export default StudentIcon;
