import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { INIT_PAGE_NUM, LAST_VIEWED_SLIDE, remoteEvent } from "../../../constants";
import { useAppContext } from "../../../context/AuthContext";
import { Lecture, RemoteSocket } from "../../../controller";
import LectureDto from "../../../interfaces/lecture/LectureDto";
import MessageType from "../../../utils/MessageType";
import Routes from "../../../utils/Routes";
import UserRole from "../../../utils/UserRole";
import { useEffectOnce } from "../../../utils/Utils";

interface SlidesServiceExport {
  lecture: any;
  setLecture: (val: LectureDto) => void;
  currentPage: number;
  numPages: number;
  onDocumentLoadSuccess: any;
  handleCurrentPageSocket: (page: number) => void;
}

interface SlidesServiceProps {
  lectureNum: number;
  isPreview: boolean;
  setNewConnectionWarning?: (val: boolean) => void;
}

const LEFT_ARROR = "ArrowLeft";
const RIGHT_ARROR = "ArrowRight";

export default function SlidesService({ lectureNum, isPreview, setNewConnectionWarning }: SlidesServiceProps): SlidesServiceExport {
  const { selectedCourse, updateLastViewedSlide, showMessage } = useAppContext();
  const courseId = selectedCourse.id;
  const history = useHistory();
  const [lecture, setLecture] = useState<LectureDto>();
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(INIT_PAGE_NUM);
  const [lastSlideViewed, setLastSlideViewed] = useState<number>(parseInt(localStorage.getItem(LAST_VIEWED_SLIDE) ?? INIT_PAGE_NUM.toString()));
  const { isTeacher } = UserRole();

  useEffectOnce(() => {
    handleSetCurrentPage(lastSlideViewed);

    if (!isPreview) {
      initSlideAction();
      getLecture();
      RemoteSocket.connect({ query: { courseId, lectureNum } });
      isTeacher && RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, true);
      RemoteSocket.emit(remoteEvent.send.IS_END_LECTURE);
      RemoteSocket.on(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
      RemoteSocket.on(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
      RemoteSocket.on(remoteEvent.recv.IS_END_LECTURE_RES, handleEndLecture);
    }

    return () => {
      if (!isPreview) {
        isTeacher && RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, false);
        RemoteSocket.off(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
        RemoteSocket.off(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
        RemoteSocket.off(remoteEvent.recv.IS_END_LECTURE_RES, handleEndLecture);
      }
    };
  });

  useEffect(() => {
    document.addEventListener("keyup", keyboardHandler);
    return () => {
      document.removeEventListener("keyup", keyboardHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numPages]);

  const keyboardHandler = ({ key }: { key: string }) => {
    if (currentPage !== 1 && key === LEFT_ARROR) {
      handleCurrentPageSocket(currentPage - 1);
    } else if (currentPage !== numPages && key === RIGHT_ARROR) {
      handleCurrentPageSocket(currentPage + 1);
    }
  };

  const getLecture = async () => {
    if (lectureNum) {
      const course = await Lecture.getLecture(courseId, lectureNum);
      setLecture(course);
    }
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleCurrentPageSocket = (page: number) => {
    handleSetCurrentPage(page);
    if (!isPreview) {
      setNewConnectionWarning?.(true);
      RemoteSocket.emit(remoteEvent.send.SET_SLIDE, page);
    }
  };

  const initSlideAction = () => {
    handleCurrentPageSocket(lastSlideViewed);
    RemoteSocket.emit(remoteEvent.send.GET_EXPRESSIONS);
  };

  const handleAccessGranted = () => console.log("Accessed Granted");
  const handleConnectError = (err: any) => console.log("Socket connect error", err);

  const handleEndLecture = ({ isEndLecture }: { isEndLecture: boolean }) => {
    if (!isEndLecture) return;
    showMessage("The lecture has ended.", MessageType.INFO);
    history.push(Routes.TEACHER_MY_LECTURES);
  };

  const handleSetCurrentPage = (pageNum: number) => {
    setCurrentPage(pageNum);
    setLastSlideViewed(pageNum);
    updateLastViewedSlide(pageNum);
  };

  return {
    lecture,
    setLecture,
    currentPage,
    numPages,
    onDocumentLoadSuccess,
    handleCurrentPageSocket,
  };
}
export type { SlidesServiceExport, SlidesServiceProps };
