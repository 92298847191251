import Colors from "../../../utils/Colors";

const RewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.0333" height="50.0333" viewBox="0 0 63.233 63.233">
    <defs>
      <linearGradient id="linear-gradient-rew" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor={Colors.LIGHT_BLUE_HEADER} />
        <stop offset="1" stopColor={Colors.DARK_BLUE_HEADER} />
      </linearGradient>
    </defs>
    <g id="Group_119" data-name="Group 119" transform="translate(-2582.163 -5279.519)">
      <g id="Group_118" data-name="Group 118">
        <path id="Path_71" d="M2645.4 5311.136a31.616 31.616 0 1 1-31.617-31.617 31.617 31.617 0 0 1 31.617 31.617z" style={{ fill: "url(#linear-gradient-rew)" }} data-name="Path 71" />
      </g>
      <path
        id="Path_72"
        d="M2596.764 5311.807l15.2 8.776a.994.994 0 0 0 1.491-.86v-6.523l12.792 7.385a.994.994 0 0 0 1.491-.86v-17.554a.994.994 0 0 0-1.491-.86l-12.792 7.386v-6.526a.994.994 0 0 0-1.491-.86l-15.2 8.777a.994.994 0 0 0 0 1.719z"
        style={{
          fill: "none",
          stroke: "#231f20",
          strokeMiterlimit: 10,
          strokeWidth: "1.888px",
        }}
        data-name="Path 72"
      />
    </g>
  </svg>
);
export default RewIcon;
