import { useEffect, useState } from "react";
import { useQueryMedia } from "../../../hooks/useQueryMedia";

interface DiscussionCardServiceType {
  activeId: number | undefined;
  makeRoundData: (array: any) => Array<any>;
  handleOnClick: (e: any) => void;
  handleShowAll: (e: any) => void;
  showAll: boolean;
}

export default function DiscussionCardService({ discussions, activeDiscussionId, callbackHandleOnClick }: any): DiscussionCardServiceType {
  const { isMobile } = useQueryMedia();
  const [showAll, setShowAll] = useState<boolean>(!(discussions.length > 6));
  const [activeId, setActiveId] = useState<number | undefined>();

  const showHideDiscussions = (array: Array<any>, showAll: boolean) => {
    const returnArray = [...array];
    if (returnArray.length > (isMobile ? 4 : 6) && !showAll) {
      return returnArray.splice(0, isMobile ? 3 : 5);
    } else {
      return returnArray;
    }
  };
  const makeRoundData = (array: Array<any>) => {
    const returnArray = [...array];
    return showHideDiscussions(returnArray, showAll);
  };

  useEffect(() => {
    setActiveId(activeDiscussionId);
  }, [activeDiscussionId]);

  const handleOnClick = (e: any) => {
    const discussionId = e?.target?.id ? +e.target.id : undefined;
    if (discussionId) {
      callbackHandleOnClick(discussionId);
      setActiveId(discussionId);
    }
  };

  const handleShowAll = () => setShowAll(true);

  return { activeId, makeRoundData, handleOnClick, handleShowAll, showAll };
}
export type { DiscussionCardServiceType };
