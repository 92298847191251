import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import TooltipStyled from "../tooltip/TooltipStyled";

export default function RemovedFromClass() {
  return (
    <TooltipStyled placement="bottom" tooltipText="Author was removed from the class.">
      <DoNotDisturbAltIcon sx={{ fontSize: "12px", ml: 1, color: "white" }} />
    </TooltipStyled>
  );
}
