import { useState } from "react";
import { SendInputType } from "../../../interfaces/Types";
import { countCharacters, countWords, makeRestrictions } from "../../../utils/Utils";

interface SendInputServiceType {
  handleInput: any;
  textValidationState: {
    text: string;
    validated: boolean;
  };
  showSendData: boolean;
  setShowSendData: any;
  send: () => void;
  sendAsStudent: boolean;
  handleCheckbox: any;
}

interface SendInputServiceProps {
  setText?: any;
  handleSend: (teacherAsStudent: boolean) => void;
  type: SendInputType;
}
export default function SendInputService({ setText, handleSend, type }: SendInputServiceProps): SendInputServiceType {
  const initTextValidation = { text: `0 / ${makeRestrictions(type).chars} chars 0 / ${makeRestrictions(type).words} words. Min 5 chars`, validated: false };
  const [textValidationState, setTextValidationState] = useState<any>(initTextValidation);
  const [showSendData, setShowSendData] = useState<boolean>(false);
  const [sendAsStudent, setSendAsStudent] = useState<boolean>(false);

  const send = () => {
    handleSend(sendAsStudent);
    setTextValidationState(initTextValidation);
    setSendAsStudent(false);
  };

  const handleInput = (e: any) => {
    const validatedObj = validation(e.target.value);
    setTextValidationState(validatedObj);
    setText(e.target.value);
  };

  const validation = (textValiation: string) => {
    const numOfCharacters = countCharacters(textValiation);
    const numOfWords = countWords(textValiation);
    const typeBasedRestrictions = makeRestrictions(type);

    let returnObj = { text: `${numOfCharacters} / ${typeBasedRestrictions.chars} chars ${numOfWords} / ${typeBasedRestrictions.words} words`, validated: false };
    if (numOfCharacters < 6) {
      returnObj = {
        text: `${numOfCharacters} / ${typeBasedRestrictions.chars} chars ${numOfWords} / ${typeBasedRestrictions.words} words. Min 5 chars`,
        validated: false,
      };
    }

    if (numOfCharacters > 5 && numOfCharacters <= typeBasedRestrictions.chars && numOfWords <= typeBasedRestrictions.words) {
      returnObj = { ...returnObj, validated: true };
    }
    return returnObj;
  };

  const handleCheckbox = () => setSendAsStudent(!sendAsStudent);

  return {
    handleInput,
    textValidationState,
    showSendData,
    setShowSendData,
    send,
    sendAsStudent,
    handleCheckbox,
  };
}
export type { SendInputServiceType };
