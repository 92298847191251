import { List, ListItem, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import { Lecture } from "../../../controller/Lecture";
import MenuItemDto from "../../../interfaces/menu-item/MenuItem";
import Colors from "../../../utils/Colors";
import EnumSidemenuIcon from "../../../utils/enum/EnumSidemenuIcon";
import { isLectureLive } from "../../../utils/Utils";
import AnswerIcon from "../../shared/icons/AnswerIcon";
import LiveIconOff from "../../shared/icons/live-icon/LiveIconOff";
import LiveIconOn from "../../shared/icons/live-icon/LiveIconOn";
import QuestionIcon from "../../shared/icons/QuestionIcon";
import { teacherItems1, teacherItems2, teacherItems3, teacherItems4 } from "./config";

export default function TeacherSidebar() {
  const { accessToken, selectedCourse, deleteAccessToken, isTeacherAdmin } = useAppContext();
  const [existLiveClass, setExistLiveClass] = useState<boolean>(false);

  const routeOnClick = (id: number) => {
    switch (id) {
      case EnumSidemenuIcon.LOGOUT:
        deleteAccessToken();
        break;
      default:
        break;
    }
  };

  const renderIcon = (id: number, icon: any, isActive: boolean) => {
    switch (id) {
      case EnumSidemenuIcon.LIVE_LECTURE:
        return existLiveClass ? <LiveIconOn fill={isActive ? Colors.BLUE_DIANNE : ""} /> : <LiveIconOff fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      case EnumSidemenuIcon.MY_QUESTIONS:
        return <QuestionIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      case EnumSidemenuIcon.MY_ANSWERS:
        return <AnswerIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;

      default:
        return icon;
    }
  };

  useEffect(() => {
    if (accessToken && selectedCourse?.id) {
      Lecture.getCourseLectures(selectedCourse.id).then(({ data }: any) => {
        if (data?.message) {
          setExistLiveClass(data.data.some((lecture: any) => isLectureLive(lecture)));
        } else {
          console.error(data);
        }
      });
    }
  }, [accessToken, selectedCourse?.id]);

  const createOneItem = ({ id, icon, label, to }: MenuItemDto, i: number) => {
    let isActive = false;
    if (!isTeacherAdmin && id === EnumSidemenuIcon.ADMIN_STATS) return null;
    return (
      <ListItem key={label} disablePadding>
        <NavLink
          key={i}
          isActive={(match, location) => {
            isActive = match?.url === location.pathname;
            return isActive;
          }}
          to={to}
          onClick={() => routeOnClick(id)}
          className={(isActive) => "teacher-sidebar__link" + (isActive ? "__selected" : "")}
        >
          <ListItemButton>
            <div className="teacher-sidebar__card__icon">{renderIcon(id, icon, isActive)}</div>
            <div className="teacher-sidebar__card__label">{label}</div>
          </ListItemButton>
        </NavLink>
      </ListItem>
    );
  };

  const createListItem = (itemList: Array<MenuItemDto>) => (
    <div className="teacher-sidebar__card">
      <List>{itemList.filter(({ id }: MenuItemDto) => !(id === EnumSidemenuIcon.LECTURES && !selectedCourse)).map((item: any, i: number) => createOneItem(item, i))}</List>
    </div>
  );

  return (
    <div className="teacher-sidebar">
      {createListItem(teacherItems1)}
      {selectedCourse && createListItem(teacherItems2)}
      {createListItem(teacherItems3)}
      {createListItem(teacherItems4)}
    </div>
  );
}
