const EnumSortItem = Object.freeze({
  TIME: 1,
  VOTE: 2,
  NUM_OF_ANSWERS: 3,
  FLAG: 4,
  LECTURE_NUM: 5,
  NUM_OF_DISCUSSIONS: 6,
});

export default EnumSortItem;
