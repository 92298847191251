import { Grid } from "@mui/material";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import StudentBreadcrumbs from "../../student-breadcrumbs/StudentBreadcrumbs";
import LiveLectureTabs from "./LiveLectureTabs";

export default function LiveLecture() {
  const { isMobile } = useQueryMedia();
  return (
    <>
      <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={2}>
        {!isMobile && (
          <Grid item container xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid item ml={{ xs: -2, sm: -2, md: 0, lg: 0, xl: 0 }} mt={{ xs: -1.5, sm: -1.5, md: 0, lg: 0, xl: 0 }}>
              <StudentBreadcrumbs />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <LiveLectureTabs />
        </Grid>
      </Grid>
    </>
  );
}
