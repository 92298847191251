import { http } from "../controller/http";
import { getAccessToken } from "../utilsVue";

export const User = {
  // Get all user anonIds that exist. Some may belong to teachers.
  getAllUsers() {
    return http.get("/users");
  },

  // Get information related to current signed in user.
  getCurrentUser() {
    return http.get("/user");
  },

  // Create a new user. If no credentials supplied, will be random.
  createUser(anonId, password) {
    return http.post("/user", { anonId, password });
  },

  // Update current user's password
  updatePassword(password) {
    return http.put("/user", { password });
  },

  // Get a specific user's info
  getUserInfo(anonId) {
    return http.get(`/user/${anonId}`);
  },

  // Update a user.
  updateUser(anonId) {
    return http.put(`/user/${anonId}`);
  },

  changePassword: (body) => http.put(`/user/changePassword`, body),

  userLogin: (anonId, password) => http.post("/user/login", { anonId, password }),

  userLogout: () => http.post("/user/logout"), //TODOs not implemented

  // copied from old backend
  isLoggedIn() {
    try {
      const token = getAccessToken();
      return !!token;
    } catch {
      return false;
    }
  },
};
