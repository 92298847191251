import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Document } from "react-pdf";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import useLectureType, { UseLectureTypeExport } from "../../../../hooks/useLectureType";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import LectureNumParams from "../../../../utils/LectureNumParams";
import { EXPRESSION_SLIDE_NUM } from "../../../../utils/Utils";
import StatsCard from "../../../shared/stats-card/StatsCard";
import SwitchIOS from "../../../shared/switch-ios/SwitchIOS";
import { LectureStatistics } from "./lecture-statistics/LectureStatistics";

interface paging {
  startPage: number;
  endPage: number;
}

export default function Stats() {
  const baseClass = "stats";
  const { isTeacherAdmin, selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const { WITHOUT_PDF, VIDEO_SLIDE } = EXPRESSION_SLIDE_NUM;
  const lectureNum = LectureNumParams();
  const { presentationBlob, noPresentation, isVideoInteraction }: UseLectureTypeExport = useLectureType({ lectureNum });
  const [numOfPages, setNumOfPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pagesForDisplay, setPagesForDisplay] = useState<paging>({ startPage: 1, endPage: 12 });
  const [hideAll, setHideAll] = useState<boolean>(true);
  const [lecture, setLecture] = useState<LectureDto | undefined>(undefined);

  useEffect(() => {
    Lecture.getLecture(selectedCourse.id, lectureNum)
      .then((lectureLoaded) => setLecture(lectureLoaded))
      .catch((error) => console.log(error));

    showPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, numOfPages]);

  const showPage = () => {
    const maxPages = Math.ceil(numOfPages / 12);
    if (page === 1) {
      if (numOfPages < 12) {
        setPagesForDisplay({ startPage: 1, endPage: numOfPages % 12 });
      } else {
        setPagesForDisplay({ startPage: 1, endPage: 12 });
      }
    } else if (maxPages === page) {
      setPagesForDisplay({ startPage: (page - 1) * 12 + 1, endPage: (page - 1) * 12 + (numOfPages % 12) });
    } else {
      setPagesForDisplay({ startPage: (page - 1) * 12 + 1, endPage: (page - 1) * 12 + 12 });
    }
  };

  const range = (start: number, end: number) => {
    let arr = [];
    while (start <= end) {
      arr.push(start++);
    }
    return arr;
  };

  const handleForward = () => {
    if (page < Math.ceil(numOfPages / 12)) setPage(page + 1);
  };

  const handleBackward = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className={`${baseClass}`}>
      <div>
        <Grid container ml="15px">
          <Grid item xs={4}></Grid>
          <Grid item xs={4} className={`${baseClass}__subtext`}>
            Statistics
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color={Colors.WHITE} sx={{ fontSize: "10px", alignSelf: "center", width: "52px" }}>
              Show/Hide Statistics
            </Typography>
            <SwitchIOS value={hideAll} onChange={() => setHideAll(!hideAll)} activeColor={Colors.RED_SOLID} deactiveColor={Colors.GREEN_SOLID} />
          </Grid>
        </Grid>
        <LectureStatistics lecture={lecture} />
        {presentationBlob ? (
          <Document file={presentationBlob} onLoadSuccess={(data: any) => setNumOfPages(data?._pdfInfo?.numPages)}>
            <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={2} columnSpacing={2} pl={3}>
              {pagesForDisplay &&
                range(pagesForDisplay.startPage, pagesForDisplay.endPage).map((e: any) => (
                  <Grid key={e} item xs={3}>
                    <StatsCard pageNumber={e} courseId={courseId} lectureNum={lectureNum} showAll={!hideAll} numOfPages={numOfPages} />
                  </Grid>
                ))}
            </Grid>
          </Document>
        ) : noPresentation ? (
          <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={2} columnSpacing={2} pl={3}>
            <Grid item xs={3}>
              <StatsCard noPresentation pageNumber={WITHOUT_PDF} courseId={courseId} lectureNum={lectureNum} showAll={!hideAll} numOfPages={WITHOUT_PDF} />
            </Grid>
          </Grid>
        ) : (
          <Typography>No statistics were collected for this lecture.</Typography>
        )}
      </div>
      {isTeacherAdmin && (
        <>
          {isVideoInteraction ? (
            <Box mt={2}>
              <Typography>Video statistics</Typography>
              <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={2} columnSpacing={2} pl={3}>
                <Grid item xs={3}>
                  <StatsCard urlVideo={lecture?.urlYoutube} pageNumber={VIDEO_SLIDE} courseId={courseId} lectureNum={lectureNum} showAll={!hideAll} numOfPages={VIDEO_SLIDE} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Typography>No video statistics were collected for this lecture.</Typography>
          )}
        </>
      )}
      {presentationBlob && numOfPages > 12 && (
        <Grid container width={"100%"} justifyContent="center" mb={20} ml={2}>
          <Button onClick={handleBackward}>{"<"}</Button>
          <Button> {page} </Button>
          <Button onClick={handleForward}>{">"}</Button>
        </Grid>
      )}
    </div>
  );
}
