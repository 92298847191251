import SvgIcon from "@mui/material/SvgIcon";

const ProffessorIcon = ({ className = "", width, height, color = "white" }: any) => (
  <SvgIcon width="22.923" height="21.983" viewBox="0 0 22.923 21.983" style={{ stroke: color, width: width, height: height }} className={className}>
    <g id="Group_1549" data-name="Group 1549" transform="translate(-3146.206 -6095.606)">
      <g id="Group_1548" data-name="Group 1548">
        <g id="Group_1547" data-name="Group 1547">
          <path
            id="Path_1554"
            data-name="Path 1554"
            d="M3163.779,6101.427h0a1.88,1.88,0,0,1,1.88,1.88v.94a1.88,1.88,0,0,1-1.88,1.88h0a1.88,1.88,0,0,1-1.881-1.88v-.94A1.88,1.88,0,0,1,3163.779,6101.427Z"
            fill="none"
          />
          <path id="Path_1555" data-name="Path 1555" d="M3162.839,6106.127v.94h-4.7l-4.7-1.41-2.641-5.641" fill="none" />
          <path id="Path_1556" data-name="Path 1556" d="M3164.719,6106.127v.94l2.621.922a1.882,1.882,0,0,1,1.139,1.729V6116" fill="none" />
        </g>
        <path id="Path_1557" data-name="Path 1557" d="M3166.6,6110.828V6116h-4.231" fill="none" />
      </g>
      <path id="Path_1558" data-name="Path 1558" d="M3158.078,6113.178h-11.222v-16.922h21.623v9.635" fill="none" />
      <path id="Path_1559" data-name="Path 1559" d="M3153.437,6105.657l.229,2.09,4,1.2h2.821v7.991" fill="none" />
      <line id="Line_947" data-name="Line 947" y2="0.94" transform="translate(3166.599 6115.999)" fill="none" />
      <path id="Path_1560" data-name="Path 1560" d="M3157.668,6096.256" fill="none" />
    </g>
  </SvgIcon>
);
export default ProffessorIcon;
