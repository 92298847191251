import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import AnswerType from "../../../../interfaces/answer/AnswerType";
import DiscussionDialogStateType from "../../../../interfaces/discussion-dialog-state/DiscussionDialogState";
import AnswerCard from "../../../shared/answer-card/AnswerCard";
import DiscussionCard from "../../../shared/discussion-card/DiscussionCard";
import DiscussionDialog from "../../../shared/discussion-dialog/DiscussionDialog";
import QuestionOrderDown from "../../../shared/icons/QuestionOrderDown";
import QuestionOrderUp from "../../../shared/icons/QuestionOrderUp";
import QuestionCard from "../../../shared/question-card/QuestionCard";
import MyDiscussionTabService from "./MyDiscussionTabService";

interface MyDiscussionsTabProps {
  selectedLectures: any;
  selectedCourses: any;
  answers: AnswerType[];
  callbackRefresh: any;
  callbackQuestionRefresh: any;
  tabValue: any;
  showAllAnswers: any;
}

const initDiscussionDialogState = { open: false, answer: undefined, directDiscussionId: undefined };
const MyDiscussionsTab = ({ answers, callbackRefresh, callbackQuestionRefresh, tabValue, showAllAnswers, selectedLectures, selectedCourses }: MyDiscussionsTabProps) => {
  const { isMobile } = useQueryMedia();

  const answersIds = answers.map((answer: AnswerType) => answer.id);
  const { showQuestions, showQuestionAction } = MyDiscussionTabService({ answersIds, showAllAnswers });
  const [discussionDialogState, setDiscussionDialogState] = useState<DiscussionDialogStateType>(initDiscussionDialogState);

  const handleDiscussionCardOnClick = (discussionId: number, answer: AnswerType) => setDiscussionDialogState({ open: true, directDiscussionId: discussionId, answer: answer });
  const handleCloseDiscussion = () => setDiscussionDialogState(initDiscussionDialogState);

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
      {answers.map((answer: AnswerType) => {
        const { id, question, discussions } = answer;
        const showQuestion = showQuestions.includes(id);
        return (
          <Grid key={id} item xs={12} sx={{ paddingLeft: "0px !important", mt: isMobile ? 0 : 2 }} pr={1}>
            <>
              {showQuestions.includes(id) && (
                <Grid sx={{ mb: isMobile ? 0.25 : 0.5 }}>
                  <QuestionCard question={question} callbackRefresh={callbackQuestionRefresh} selectedLectures={selectedLectures} selectedCourses={selectedCourses} tabValue={tabValue} />
                </Grid>
              )}
              <Grid position="relative" display="flex" flexDirection="column">
                {!isMobile ? (
                  <Button endIcon={showQuestion ? <KeyboardArrowDown /> : <KeyboardArrowUp />} className="answer-card-btn-show-answers" onClick={() => showQuestionAction(id)}>
                    {showQuestion ? "hide question" : "show question"}
                  </Button>
                ) : (
                  <>
                    {!showQuestion ? (
                      <div onClick={() => showQuestionAction(id)}>
                        <QuestionOrderDown className={`answer-card-question-order ${showQuestion && "answer-card-question-order-active"}`} />
                      </div>
                    ) : (
                      <div onClick={() => showQuestionAction(id)}>
                        <QuestionOrderUp className={`answer-card-question-order ${showQuestion && "answer-card-question-order-active"}`} />
                      </div>
                    )}
                  </>
                )}
                <AnswerCard answer={answer} questionId={answer.question.id} callbackRefresh={callbackRefresh} />
                <DiscussionCard showStartNew={false} discussions={discussions} callbackHandleOnClick={(discussionId: number) => handleDiscussionCardOnClick(discussionId, answer)} />
              </Grid>
            </>
          </Grid>
        );
      })}
      {discussionDialogState.answer && <DiscussionDialog state={discussionDialogState} handleClose={handleCloseDiscussion} refreshData={callbackRefresh} />}
    </Grid>
  );
};

export default MyDiscussionsTab;
