import { SvgIcon } from "@mui/material";

const LikeIconFull = ({ edgeLength, unlike, className }: any) => (
  <SvgIcon style={{ width: edgeLength, height: edgeLength, transform: `${unlike ? "rotate(180deg)" : ""}` }} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.56 46.52">
    <title>{unlike ? "dislike" : "like"}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="_1280x740_bckg" data-name="1280x740 bckg">
        <path
          className="cls-1"
          d="M22.2,19.58,25,9.54a2.63,2.63,0,0,0-.51-1.94,2.69,2.69,0,0,0-1.76-1.06,3,3,0,0,0-.43,0h-7V3.8C15.25.39,13.79,0,12.7,0a.49.49,0,0,0-.44.28L8.13,9.58V21.86H19.52a2.7,2.7,0,0,0,2.68-2.28Z"
        />
        <path className="cls-1" d="M0,12.66v6.51a2.69,2.69,0,0,0,2.69,2.69H6.13V10H2.69A2.7,2.7,0,0,0,0,12.66Z" />
      </g>
    </g>
  </SvgIcon>
);
export default LikeIconFull;
