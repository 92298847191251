import { useState } from "react";
import { Course } from "../../../../controller";
import CourseDto from "../../../../interfaces/course/CourseDto";
import { useEffectOnce } from "../../../../utils/Utils";

interface MyCoursesServiceType {
  courses: Array<CourseDto> | undefined;
}

export default function MyCoursesService(): MyCoursesServiceType {
  const [courses, setCourses] = useState<Array<CourseDto>>();

  useEffectOnce(() => {
    getCourses();
  });

  const getCourses = () => {
    Course.getAllCourses()
      .then(({ data }: any) => {
        if (data?.message) {
          setCourses(data.data);
        } else {
          console.error(data);
        }
      })
      .catch((err: any) => console.error(err));
  };

  return { courses };
}

export type { MyCoursesServiceType };
