import Colors from "../../../utils/Colors";
import { StyledBadge, StyledButton } from "./styled";
import TeacherBadgeNotificationService, { TeacherBadgeNotificationServiceType } from "./TeacherBadgeNotificationService";

export default function TeacherBadgeNotification() {
  const { newNotification, openQuestions }: TeacherBadgeNotificationServiceType = TeacherBadgeNotificationService();

  return (
    <StyledBadge colorNotif={Colors.RED_SOLID} badgeContent=" " visible={newNotification}>
      <StyledButton onClick={openQuestions} variant="contained">
        Q&A
      </StyledButton>
    </StyledBadge>
  );
}
