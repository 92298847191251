import { Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Colors from "../../../utils/Colors";

const myHeight = "28px";

interface StyledTabProps {
  label: string;
  icon?: any;
  width?: number;
  value?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AntTabs = styled(Tabs)({
  borderRadius: "6px",
  border: "2px solid white",
  backgroundColor: "#dbdcdc",
  minHeight: myHeight,
  height: myHeight,
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple iconPosition="end" {...props} />)(({ width, value }) => {
  const borderFor3Tabs = value === 1;
  const borderFor5Tabs = value === 3;
  const showBorder = borderFor3Tabs || borderFor5Tabs;
  return {
    textTransform: "none",
    width: `${width}%`,
    minWidth: "auto",
    borderLeft: showBorder ? "2px solid white" : "",
    borderRight: showBorder ? "2px solid white" : "",
    color: Colors.BLACK,
    minHeight: myHeight,
    height: myHeight,
    "&:hover": {
      opacity: 0.8,
    },
    "&.Mui-selected": {
      color: Colors.WHITE,
      background: Colors.QUESTION,
    },
    "@media (max-width: 600px)": {
      fontSize: "11.5px",
    },
    "& .MuiSvgIcon-root": {
      marginLeft: "5px",
    },
  };
});

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pl: 1 }}>{children}</Box>}
    </div>
  );
}
