import { Button, Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT_STYLE, isLectureFuture, isLectureLive, parseStartEndTime } from "../../../../utils/Utils";
import GridCard from "../../../shared/grid-card/GridCard";
import MyLecturesService, { MyLecturesServiceType } from "./MyLecturesService";

export default function MyLectures() {
  const history = useHistory();
  const { updateSelectedLecture } = useAppContext();
  const { lectures }: MyLecturesServiceType = MyLecturesService();
  const endLecture = lectures.filter((l: LectureDto) => !isLectureLive(l));
  const liveLecture = lectures.filter((l: LectureDto) => isLectureLive(l));

  const sortLectures = (lectureArray: Array<LectureDto>) => {
    return lectureArray.sort((a: LectureDto, b: LectureDto) => {
      return moment(a.startTime).valueOf() - moment(b.startTime).valueOf();
    });
  };

  return (
    <div className="my-lectures">
      <div className="lecture-card__subtext">{lectures?.length ? "Lectures" : "There Are Currently No Lectures."}</div>
      <Grid container item justifyContent={{ xs: "center", sm: "center", md: "flex-start", lg: "flex-start", xl: "flex-start" }} alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
        {sortLectures([...endLecture, ...liveLecture]).map(({ id, name, startTime, endTime, lectureNum, urlYoutube }: LectureDto, index: number) => {
          const isLive = isLectureLive({ startTime, endTime });
          const isFuture = isLectureFuture({ startTime, endTime });
          return (
            <Grid key={id} item>
              <GridCard
                isLive={isLive}
                isFuture={isFuture}
                position={index + 1}
                title={startTime ? moment(startTime).format(DATE_FORMAT_STYLE) : ""}
                subtitle={parseStartEndTime(startTime, endTime)}
                showPointer={!isFuture}
                cardAction={() => {
                  if (!isFuture) {
                    updateSelectedLecture({ id, name, lectureNum });
                    history.push({
                      pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
                      state: { lectureNum: lectureNum, goToTab: isLive ? EnumTabs.REMOTE : EnumTabs.Q_A },
                    });
                  }
                }}
                name={name}
                active={false}
                color={isFuture ? Colors.LECTURE_FUTURE : isLive ? Colors.LECTURE_LIVE : Colors.LECTURE_LIVE}
              />
              {urlYoutube && (
                <Button
                  className="lecture-card__video"
                  variant="contained"
                  onClick={() =>
                    history.push({
                      pathname: Routes.LECTURE_VIDEO.replace(":lectureNum", String(lectureNum)),
                    })
                  }
                >
                  Video
                </Button>
              )}
            </Grid>
          );
        })}
        {lectures && lectures?.length / 2 !== 0 && <Grid container className="lecture-card__fake-card" item></Grid>}
      </Grid>
    </div>
  );
}
