import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Notification } from "../../../controller";
import NotificationType from "../../../interfaces/notification/NotificationType";
import Colors from "../../../utils/Colors";
import EnumNotificationEvent from "../../../utils/enum/EnumNotificationEvent";
import UserRole from "../../../utils/UserRole";
import { DISCUSSION_EVENTS } from "../../../utils/Utils";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "0px 0px 5px 5px",
    left: "auto !important",
    right: "0px",
    width: 150,
    backgroundColor: Colors.LIGHT_BLUE_HEADER,
    color: "white",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: "13px",
    },
  },
}));

interface MenuItemType {
  tab: 0 | 1 | 2 | 3 | 4;
  label: string;
  value: number;
}

interface TabNotificationType {
  questions: number;
  answers: number;
  discussions: number;
  flaggedA: number;
  flaggedQ: number;
}

const initTabNotification = {
  questions: 0,
  answers: 0,
  discussions: 0,
  flaggedQ: 0,
  flaggedA: 0,
};

interface HeaderNotificationType {
  anchorEl: any;
  closeMenu: any;
  liveUnreadNotifications: number;
  handleTabRoute: (tab: number) => void;
}

export default function HeaderNotification({ anchorEl, closeMenu, liveUnreadNotifications, handleTabRoute }: HeaderNotificationType) {
  const [myNotifications, setMyNotifications] = useState<TabNotificationType>(initTabNotification);
  const { isStudent } = UserRole();

  useEffect(() => {
    getAllNotificationsNotAcknowledged();
  }, [liveUnreadNotifications]);

  const getAllNotificationsNotAcknowledged = () => {
    Notification.getAllNotAcknowledged()
      .then(({ data }) => {
        const res = data.data;
        const tempMyNotifications: TabNotificationType = {
          ...initTabNotification,
          questions: res.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_POSTED).length,
          answers: res.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_ANSWERED).length,
          discussions: res.filter(({ event }: NotificationType) => DISCUSSION_EVENTS.includes(event)).length,
          flaggedQ: res.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_FLAGGED).length,
          flaggedA: res.filter(({ event }: NotificationType) => event === EnumNotificationEvent.ANSWER_FLAGGED).length,
        };
        setMyNotifications(tempMyNotifications);
      })
      .catch((error) => console.log(error));
  };

  const valueStyle = (val: number) => (val ? <label style={{ color: Colors.YELLOW_SOLID }}>({val})</label> : "");

  const createMenuItem = ({ tab, label, value }: MenuItemType) => (
    <MenuItem key={label} onClick={() => handleTabRoute(tab)} disableRipple>
      {label}&nbsp;{valueStyle(value)}
    </MenuItem>
  );

  const itemList: MenuItemType[] = [
    { tab: 0, label: "Questions", value: myNotifications.questions },
    { tab: 1, label: "Answers", value: myNotifications.answers },
    { tab: 2, label: "Discussions", value: myNotifications.discussions },
  ];

  if (isStudent) {
    itemList.push({ tab: 3, label: "Flagged Q", value: myNotifications.flaggedQ });
    itemList.push({ tab: 4, label: "Flagged A", value: myNotifications.flaggedA });
  }

  return (
    <>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }}>
        {itemList.map(createMenuItem)}
      </StyledMenu>
    </>
  );
}
