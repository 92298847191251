import { mdiChevronLeft, mdiEye } from "@mdi/js";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useHistory } from "react-router-dom";
import LectureNumParams from "../../../../utils/LectureNumParams";
import Routes from "../../../../utils/Routes";
import { BackButton } from "../../../shared/components/styledComponents";
import Slides from "../../../shared/slides/Slides";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import TeacherBadgeNotification from "../../../shared/teacher-badge-notification/TeacherBadgeNotification";

export default function Presentation() {
  const history = useHistory();
  const handle = useFullScreenHandle();
  const lectureNum = LectureNumParams();
  const [showClassStatus, setShowClassStatus] = useState<boolean>(false);
  const [newConnectionWarning, setNewConnectionWarning] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const toggleShowClassStatus = () => {
    if (showClassStatus) {
      setNewConnectionWarning(true);
    } else {
      setShowClassStatus(true);
    }
  };

  const closeWarningStartLecture = () => {
    setNewConnectionWarning(false);
    setShowClassStatus(false);
  };

  return (
    <FullScreen handle={handle} onChange={setFullscreen}>
      <Grid container justifyContent="center">
        <Grid container item padding={1}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <BackButton onClick={() => history.push(Routes.TEACHER_CLASS_STATUS)} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
              Back
            </BackButton>
          </Grid>
          <Grid container item xs={2} justifyContent="center">
            <TeacherBadgeNotification />
          </Grid>
          <Grid item xs={4.5} textAlign="right">
            <Button variant="outlined" className="presentation-fullscreen" onClick={() => (fullscreen ? handle.exit() : handle.enter())}>
              {fullscreen ? "Exit" : "Enter"} Full screen
            </Button>
            {!fullscreen && (
              <Button onClick={toggleShowClassStatus} variant="contained" className="presentation-class-status" endIcon={<SvgIconWrapper icon={mdiEye} />}>
                Class status
              </Button>
            )}
          </Grid>
          <Grid item xs={0.5} />
        </Grid>
        <Grid item xs={11.5}>
          <Slides
            lectureNum={lectureNum}
            isPreview={false}
            showClassStatus={showClassStatus}
            isFullscreen={fullscreen}
            newConnectionWarning={newConnectionWarning}
            setNewConnectionWarning={setNewConnectionWarning}
            closeWarningStartLecture={closeWarningStartLecture}
          />
        </Grid>
      </Grid>
    </FullScreen>
  );
}
