import { Button } from "@mui/material";
import LikeIconFull from "../icons/LikeIconFull";

interface DislikeButtonProps {
  onClick: () => void;
  disabled?: boolean;
  label?: string | number;
}

const EDGE_IN = "35px";

const DislikeButton = ({ onClick, disabled, label }: DislikeButtonProps) => (
  <Button
    sx={{ ...(disabled && { opacity: "0.5" }), color: "white !important" }}
    variant="outlined"
    className="button-reaction-button button-reaction-button__dislike"
    size="small"
    onClick={onClick}
    disabled={disabled}
    startIcon={<LikeIconFull unlike edgeLength={EDGE_IN} className="button-reaction-icon button-reaction-icon__dislike" />}
  >
    <label className="button-reaction-label" style={{ cursor: disabled ? "auto" : "pointer" }}>
      {label}
    </label>
  </Button>
);

export default DislikeButton;
