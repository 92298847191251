const EnumTabs = Object.freeze({
  REMOTE: 0,
  Q_A: 1,
  //myQuestions
  Q_MY: 0,
  Q_ALL: 1,
  Q_FLAG: 2,
  //myAnswers
  A_MY: 0,
  A_ALL: 1,
  A_FLAG: 2,
  //myDiscussions
  D_MY: 0,
  D_ALL: 1,
  D_FLAG: 2,

  //how it works
  H_STD: 0,
  H_TCH: 1,
  H_FAQ: 2,
});

export default EnumTabs;
