import { Grid } from "@mui/material";

interface BarType {
  width: number;
  height: number;
  color: string;
}

function Bar(props: BarType) {
  const { width, height, color } = props;

  return <Grid item style={{ height: height, width: width, backgroundColor: color }}></Grid>;
}

export default Bar;
