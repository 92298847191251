import SvgIcon from "@mui/material/SvgIcon";

const FlagIcon = ({ edgeLength = "12px", color = "white" }: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.55 18" style={{ fill: color, stroke: color, width: edgeLength, height: edgeLength }}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="_1280x740" data-name="1280x740">
        <path
          fill="white"
          d="M14.45,10.37,11.79,6.13l2.66-4.25a.69.69,0,0,0,0-.68.67.67,0,0,0-.58-.34H1.33V.66A.67.67,0,0,0,0,.66V17.34a.67.67,0,0,0,1.33,0V11.39H13.89a.67.67,0,0,0,.58-.34A.69.69,0,0,0,14.45,10.37ZM1.33,10.06V2.19H12.69L10.45,5.77a.7.7,0,0,0,0,.71l2.24,3.58Z"
        />
      </g>
    </g>
  </SvgIcon>
);
export default FlagIcon;
