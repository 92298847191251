import { Divider, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import SendInputMode from "../../../constants/SendInputMode";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import DiscussionDialogStateType from "../../../interfaces/discussion-dialog-state/DiscussionDialogState";
import Colors from "../../../utils/Colors";
import { getTitleQAD, makeRestrictions } from "../../../utils/Utils";
import ChatMessages from "../ChatMessages";
import DialogTitleAnon from "../dialog-title/DialogTitleAnon";
import DiscussionCard from "../discussion-card/DiscussionCard";
import MultilineQuestionAnswer from "../multiline-question-answer/MultilineQuestionAnswer";
import DiscussionDialogService, { DiscussionDialogServiceExport } from "./DiscussionDialogService";

const BootstrapDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: Colors.ANSWER,
    color: Colors.BLUE_DIANNE,
    borderRadius: "5px",
  },
});

interface DiscussionDialogProps {
  state: DiscussionDialogStateType;
  handleClose: () => void;
  refreshData: () => void;
}

export default function DiscussionDialog({ state, handleClose, refreshData }: DiscussionDialogProps) {
  const { isMobile } = useQueryMedia();
  const { discussion, discussions, ref, haveStarted, startNew, handleStartNew, submitDiscussion, handleDiscussionCardOnClick }: DiscussionDialogServiceExport = DiscussionDialogService({
    state,
    refreshData,
  });
  const { open, answer } = state;
  const { removedFromClass, pseudonym, createdAt, text, selfPosted, teacherAnswer } = answer ?? { pseudonym: "", teacherAnswer: false, selfPosted: true };

  return (
    <BootstrapDialog fullScreen={isMobile} className="discussion-dialog" sx={{ p: 4 }} open={open} fullWidth maxWidth="md" keepMounted onClose={handleClose}>
      <DialogTitleAnon color={Colors.NAVY_SOLID} onClose={handleClose}>
        Answer:
      </DialogTitleAnon>
      <Box mx={1} sx={{ padding: 0 }}>
        <Grid container className="discussion-dialog-content">
          <Grid item xs={12}>
            <Typography color={Colors.NAVY_SOLID} fontSize="13px" variant="inherit">
              {getTitleQAD(pseudonym, teacherAnswer, createdAt)}
            </Typography>
            <Divider sx={{ backgroundColor: Colors.BLUE_DIANNE, marginTop: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color={Colors.NAVY_SOLID}>
              {text}
            </Typography>
          </Grid>
        </Grid>
        <DiscussionCard
          showStartNew={!(removedFromClass || selfPosted || haveStarted || !answer?.respondable)}
          startNew={startNew}
          handleStartNew={handleStartNew}
          discussions={discussions}
          activeDiscussionId={discussion?.id}
          callbackHandleOnClick={handleDiscussionCardOnClick}
        />
      </Box>
      <Grid item xs={12} sx={{ marginTop: "16px", backgroundColor: "#f5f5f5" }}>
        <ChatMessages innerRef={ref} discussion={discussion} />
      </Grid>
      {!removedFromClass && (discussion?.respondable || startNew) && (
        <div className="discussion-dialog-input">
          <Grid item xs={12}>
            <MultilineQuestionAnswer onSubmit={submitDiscussion} chars={makeRestrictions(SendInputMode.D).chars} words={makeRestrictions(SendInputMode.D).words} rows={2} />
          </Grid>
        </div>
      )}
    </BootstrapDialog>
  );
}
