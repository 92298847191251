import { createContext, useContext } from "react";

interface AppContextProps {
  role: string;
  updateRole: (val: string) => void;
  selectedCourse: any;
  updateSelectedCourse: any;
  selectedLecture: any;
  updateSelectedLecture: any;
  updateLastViewedSlide: (pageNum: number) => void;
  accessToken: string | undefined;
  updateAccessToken: (data: string) => void;
  deleteAccessToken: () => void;
  showMessage: (summery: string, detail: string, severity?: string) => void;
  t: (scope: any, options?: any) => string;
  locale: string;
  setLocale: any;
  isTeacherAdmin: boolean;
  updateIsTeacherAdmin: (data: boolean) => void;
}

export const AppContext = createContext({} as AppContextProps);

export function useAppContext() {
  return useContext(AppContext);
}

export const headers = (token: string, params: any) => {
  return {
    headers: {
      "Content-Type": "application/json;",
      Authorization: "Bearer " + token,
    },
    params: params ? params : {},
  };
};
