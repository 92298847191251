import { Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { Course } from "../../../../controller";
import CourseDto from "../../../../interfaces/course/CourseDto";
import DialogYesNoDataType from "../../../../interfaces/dialog/DialogYesNoDataType";
import MessageType from "../../../../utils/MessageType";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT, useEffectOnce } from "../../../../utils/Utils";
import DialogYesNo from "../../../shared/dialog-yes-no/DialogYesNo";
import OutlinedInputCustom from "../../../shared/outlined-input-custom/OutlinedInputCustom";
import { validationSchema } from "./config";

interface ConfirmFunctionAndParameters {
  function: Function;
  parameters?: Array<any>;
}

const AddCourse = () => {
  const { showMessage } = useAppContext();
  const [course, setCourse] = useState<CourseDto>();
  const [showDialog, setShowDialog] = useState<DialogYesNoDataType>({ open: false, title: "" });
  const [confirmActionFunc, setConfirmActionFunc] = useState<ConfirmFunctionAndParameters>();
  const history = useHistory();

  const baseClass = "create-course";
  const { id } = useParams<{ id: any }>();
  const editCourse = Boolean(id);

  const fetchData = async () => {
    if (editCourse) {
      const course = await Course.getCourse(id);
      setCourse(course.data.data);
    }
  };

  useEffectOnce(() => {
    fetchData();
  });

  const handleOnSubmit = ({ courseTitle, courseShortname, courseDate }: any) => {
    if (id) {
      Course.updateCourse(id, courseTitle, courseShortname, courseDate)
        .then(({ data }: any) => {
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_COURSE);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_COURSE);
            console.error(data);
          }
        })
        .catch((err) => console.error(err));
    } else {
      Course.createCourse(courseTitle, courseShortname, courseDate)
        .then(({ data }: any) => {
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_COURSE);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_COURSE);
            console.error(data);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const handleDelete = (id: number) => {
    Course.deleteCourse(id)
      .then(({ data }: any) => {
        if (data?.message) {
          showMessage(data.message, MessageType.SUCCESS);
          history.push(Routes.TEACHER_MY_COURSE);
        } else {
          showMessage(data, MessageType.SUCCESS);
          history.push(Routes.TEACHER_MY_COURSE);
          console.error(data);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} />
      <Formik
        enableReinitialize
        initialValues={{ courseTitle: course?.name ?? "", courseShortname: course?.shortName ?? "", courseDate: course ? moment(course?.startDate).format(DATE_FORMAT) : moment().format(DATE_FORMAT) }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ handleBlur, values, touched, errors, handleChange, handleSubmit }: any) => (
          <Form>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} xl={9} lg={9} sm={8} md={8} mt={7}>
                <div className={baseClass}>
                  <div className={`${baseClass}__title`}>COURSE TITLE:</div>
                  <OutlinedInputCustom
                    placeholder="Write the title of the course here"
                    name="courseTitle"
                    value={values.courseTitle}
                    onChange={handleChange}
                    aria-describedby="outlined-id-helper-text"
                    error={touched.courseTitle && Boolean(errors.courseTitle)}
                    onBlur={handleBlur}
                    fullWidth
                    className={`${baseClass}__text-field`}
                  />
                  <div className="error-text">{touched.courseTitle && Boolean(errors.courseTitle) && <label>{errors.courseTitle}</label>}</div>
                  <div className={`${baseClass}__title`}>SHORT NAME (OPTIONAL): </div>
                  <OutlinedInputCustom
                    placeholder="Write the shortname of the course here"
                    name="courseShortname"
                    value={values.courseShortname}
                    onChange={handleChange}
                    aria-describedby="outlined-id-helper-text"
                    error={touched.courseShortname && Boolean(errors.courseShortname)}
                    onBlur={handleBlur}
                    fullWidth
                    className={`${baseClass}__text-field`}
                  />
                  <div className="error-text">{touched.courseShortname && Boolean(errors.courseShortname) && <label>{errors.courseShortname}</label>}</div>
                  <div className={`${baseClass}__title`}>COURSE START DATE: </div>
                  <OutlinedInputCustom
                    type="date"
                    placeholder="Pick a Start Date"
                    name="courseDate"
                    value={values.courseDate}
                    onChange={handleChange}
                    aria-describedby="outlined-pass-helper-text"
                    error={touched.courseDate && Boolean(errors.courseDate)}
                    onBlur={handleBlur}
                    fullWidth
                    className={`${baseClass}__text-field`}
                  />
                  <div className="error-text">{touched.courseDate && Boolean(errors.courseDate) && <label>{errors.courseDate}</label>}</div>
                  <Button type="submit" variant="contained" className="v-btn ac-color-white btn-send">
                    <p style={{ color: "black" }}>{course ? "Update" : "Save"}</p>
                  </Button>
                  {course && (
                    <Button
                      variant="contained"
                      className="v-btn ac-color-white btn-delete"
                      onClick={() => {
                        setShowDialog({ open: true, title: "Are you sure that you want to delete this course?", content: <Typography>{course?.name ?? course.id}</Typography> });
                        setConfirmActionFunc({ function: handleDelete, parameters: [course.id] });
                      }}
                    >
                      <p>Delete Course</p>
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCourse;
