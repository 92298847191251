import { SvgIcon } from "@mui/material";
import Colors from "../../../../utils/Colors";

const LiveIconOff = ({ fill = Colors.WHITE }) => (
  <SvgIcon version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18 18">
    <g>
      <path
        fill={fill}
        d="M4.5,7C4.1,7,3.8,7.2,3.6,7.5S3.3,8.2,3.3,8.8v0.3c0,0.6,0.1,1,0.3,1.4S4.1,11,4.5,11s0.7-0.2,0.9-0.5
		s0.3-0.8,0.3-1.3V8.8c0-0.6-0.1-1-0.3-1.4S4.9,7,4.5,7z"
      />
      <path
        fill={fill}
        d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S13.9,0,9,0z M6.9,9.1c0,0.6-0.1,1.1-0.3,1.5s-0.5,0.7-0.8,1S5,11.9,4.5,11.9
		s-0.9-0.1-1.3-0.3s-0.7-0.6-0.9-1S2,9.7,2,9.1V8.8c0-0.6,0.1-1.1,0.3-1.5s0.5-0.8,0.9-1S4,6,4.5,6s0.9,0.2,1.3,0.4s0.7,0.6,0.9,1
		S7,8.3,7,8.9L6.9,9.1L6.9,9.1z M11.5,7.1H9v1.5h2.3v0.9H9v2.3H7.8V6.1h3.7V7.1z M15.6,8.5v0.9h-2.3v2.4h-1.2V6.1h3.7V7h-2.5v1.5
		H15.6z"
      />
    </g>
  </SvgIcon>
);

export default LiveIconOff;
