import { mdiPlay } from "@mdi/js";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import EnumLectureState from "../../../../utils/enum/EnumLectureState";
import Routes from "../../../../utils/Routes";
import { AddButton } from "../../../shared/components/styledComponents";
import DialogYesNo from "../../../shared/dialog-yes-no/DialogYesNo";
import AddIcon from "../../../shared/icons/AddIcon";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import ClassId from "./class-id/ClassId";
import ClassInfo from "./class-info/ClassInfo";
import ClassPermission from "./class-permission/ClassPermission";
import ClassWaitingRoom from "./class-waiting-room/ClassWaitingRoom";
import ClassStatusService, { ClassStatusServiceType } from "./ClassStatusService";

export default function ClassStatus() {
  const history = useHistory();

  const {
    liveLecture,
    setLiveLecture,
    handleEndLecture,
    handleContinuePresentation,
    newConnectionWarning,
    setNewConnectionWarning,
    closeWarningStartLecture,
    noPresentationConfirmActionFunc,
    showNoPresentationDialog,
    setShowNoPresentationDialog,
    noPresentation,
  }: ClassStatusServiceType = ClassStatusService();

  const iconPlay = <SvgIconWrapper icon={mdiPlay} />;

  const cardArray = (liveLecture: LectureDto) => [
    { title: "Lecture Code", content: <ClassId lectureNum={liveLecture.lectureNum} /> },
    { title: "Permissions", content: <ClassPermission liveLecture={liveLecture} setLiveLecture={setLiveLecture} /> },
    { title: "Class info", content: <ClassInfo lectureNum={liveLecture.lectureNum} /> },
    {
      title: "Waiting room",
      content: <ClassWaitingRoom lectureNum={liveLecture.lectureNum} newConnectionWarning={newConnectionWarning} closeWarningStartLecture={closeWarningStartLecture} />,
    },
  ];

  return (
    <Grid xl={10} sx={{ mt: -1, ml: -4 }}>
      <div className="class-status">
        <Typography variant="h6" className="class-status-title">
          {liveLecture && `Lecture: ${liveLecture.name}`}
        </Typography>
        {!liveLecture && (
          <Box sx={{ color: "white", textAlign: "center" }}>
            <Box>
              <Typography component="span" variant="body1">
                There is no live lecture at this time. Please create a new lecture.
              </Typography>
            </Box>
            <Box sx={{ pt: 1 }}>
              <AddButton startIcon={<AddIcon />} onClick={() => history.push(Routes.TEACHER_MY_LECTURES_ADD)}>
                Add a New Lecture
              </AddButton>
            </Box>
          </Box>
        )}

        {liveLecture && (
          <>
            <Grid container textAlign="center" justifyContent="center" alignItems="flex-start" rowGap={1.5} columnGap={1.5}>
              {cardArray(liveLecture).map(({ title, content }: any, i: number) => (
                <Grid key={i} item className="class-status-card" xs={12} sm={5.5} md={5.5} lg={5.5}>
                  <Grid container direction="column">
                    <Grid item className="class-status-card-title" padding={1}>
                      {title}
                    </Grid>
                    <hr className="class-status-card-divider" />
                    <Grid item padding={3}>
                      {content}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {liveLecture.state !== EnumLectureState.STARTED ? (
              <Grid container paddingTop={2} justifyContent="space-around" alignItems="center">
                <Grid item xs={3} sm={3} md={3} lg={2.5} xl={2.5}>
                  <Button
                    variant="contained"
                    onClick={() => history.push(`${Routes.TEACHER_MY_LECTURES}/edit/${liveLecture.lectureNum}`)}
                    endIcon={iconPlay}
                    style={{ backgroundColor: Colors.YELLOW_SOLID }}
                    className="button-custom-action"
                  >
                    Edit Lecture
                  </Button>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={2.5} xl={2.5}>
                  <Button onClick={() => setNewConnectionWarning(true)} variant="contained" endIcon={iconPlay} style={{ backgroundColor: Colors.GREEN_LIGHT }} className="button-custom-action">
                    Start Lecture
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container paddingTop={2} justifyContent="space-around" alignItems="center">
                <Grid item xs={6} sm={6} md={5} lg={4} xl={4} textAlign="center">
                  <Button onClick={handleContinuePresentation} variant="contained" endIcon={iconPlay} style={{ backgroundColor: "#8bc63f", width: "80%" }} className="button-custom-action">
                    Continue {noPresentation ? "Lecture" : "Presentation"}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={4} textAlign="center">
                  <Button onClick={handleEndLecture} variant="contained" style={{ backgroundColor: Colors.RED_LIGHT, width: "55%" }} className="button-custom-action">
                    End Lecture
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
      <DialogYesNo dialogData={showNoPresentationDialog} setDialogData={setShowNoPresentationDialog} confirmAction={noPresentationConfirmActionFunc} />
    </Grid>
  );
}
