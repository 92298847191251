import { Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Colors from "../../../utils/Colors";

const myHeight = "25px";

interface StyledTabProps {
  label: string;
  width: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AntTabs = styled(Tabs)({
  minHeight: myHeight,
  borderRadius: "4px",
  color: "white",
  backgroundColor: Colors.DARK_BLUE_HEADER,
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-evenly !important",
  },
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme, width }) => ({
  justifyContent: "space-evenly !important",
  textTransform: "none",
  width: `${width}%`,
  // minWidth: "auto",
  borderRadius: "50px",
  color: "white",
  border: "1px solid white",
  minHeight: myHeight,
  fontSize: "10px",
  height: myHeight,
  "&:hover": {
    opacity: 0.8,
  },
  "&.Mui-selected": {
    color: Colors.DARK_BLUE_HEADER,
    backgroundColor: "white",
  },
}));

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
