import { useState } from "react";
import { useAppContext } from "../../../../context/AuthContext";
import { Teacher } from "../../../../controller";
import DataInterface from "../../../../interfaces/statistics/AdminStatisticsType";
import MessageType from "../../../../utils/MessageType";
import { useEffectOnce } from "../../../../utils/Utils";

interface StatisticsAdminServiceExport {
  data: Array<DataInterface>;
}

export default function StatisticsAdminService(): StatisticsAdminServiceExport {
  const { showMessage } = useAppContext();
  const [data, setData] = useState<Array<DataInterface>>([]);

  useEffectOnce(() => {
    Teacher.getAdminStatistics()
      .then(({ data }) => {
        const filter = data.data.filter(({ creator }: DataInterface) => creator.username !== "pedja");
        setData(filter);
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  });

  return { data };
}

export type { StatisticsAdminServiceExport };
