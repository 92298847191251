import moment from "moment";
import { EffectCallback, useEffect } from "react";
import SendInputMode from "../constants/SendInputMode";
import AnswerType from "../interfaces/answer/AnswerType";
import QuestionType from "../interfaces/question/QuestionType";
import { SendInputType } from "../interfaces/Types";
import EnumNotificationEvent from "./enum/EnumNotificationEvent";
import EnumPropName from "./enum/EnumPropName";
import EnumSignal from "./enum/EnumSignal";
import EnumSortItem from "./enum/EnumSortItem";

const axiosConfig = (token: string | undefined, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

// eslint-disable-next-line
const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

const preventDefaultEnter = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

const parseStartEndTime = (startTime: string, endTime: string) => {
  const start = startTime ? moment(startTime).format(TIME_FORMAT_1) : "";
  const end = endTime ? moment(endTime).format(TIME_FORMAT_1) : "";
  return start + " - " + end;
};

const sortListByItem = (array: Array<any>, item: number) => {
  switch (item) {
    case EnumSortItem.TIME:
      return array.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    case EnumSortItem.VOTE:
      return array.sort((a, b) => b.numUpVotes - a.numUpVotes || a.numDownVotes - b.numDownVotes);
    case EnumSortItem.NUM_OF_ANSWERS:
      return array.sort((a, b) => b.answerCount - a.answerCount);
    case EnumSortItem.FLAG:
      return array.sort((a, b) => +b.selfFlagged - +a.selfFlagged);
    case EnumSortItem.LECTURE_NUM:
      return array.sort((a, b) => +b.lectureNum - +a.lectureNum);
    case EnumSortItem.NUM_OF_DISCUSSIONS:
      return array.sort((a, b) => b.discussionCount - a.discussionCount);
    default:
      return array.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }
};

const updateStateHelper = (obj: QuestionType | AnswerType, propName: string, response: any): any => {
  let objUpdated = { ...obj };
  if (propName === EnumPropName.VOTE) {
    objUpdated = { ...obj, ...response, notificationId: undefined };
  }
  if (propName === EnumPropName.SELF_FLAGGED) {
    objUpdated = { ...obj, selfFlagged: response, notificationId: undefined };
  }
  if (propName === EnumPropName.RISE_UP) {
    objUpdated = { ...obj, ...response, notificationId: undefined };
  }
  if (propName === EnumPropName.MARK_AS_READ) {
    objUpdated = { ...obj, notificationId: response };
  }
  if (propName === EnumPropName.EDIT) {
    const { text, editable, hasHistory } = response ?? {};
    objUpdated = { ...obj, text, editable, hasHistory };
  }
  return objUpdated;
};

const countCharacters = (sentence: string) => sentence.trim().length;

const countWords = (sentence: string) => (sentence === "" ? 0 : sentence.trim().split(" ").length);

const getTitleQAD = (pseudonym: string, teacherPost: boolean, createdAt: string | undefined): string =>
  `${teacherPost ? "Teacher" : pseudonym} - ${createdAt ? moment(createdAt).format(DATE_TIME_FORMAT_2) : ""} `;

const getCreateUpdateDate = (created: string, updated: string): string => {
  return created && updated && moment(created).isBefore(updated) ? updated : created ?? "";
};

const isLectureLive = (lection: { startTime: string; endTime: string }) => moment(lection?.startTime).isBefore() && moment(lection?.endTime).isSameOrAfter(new Date(), "minute");

const isLectureFuture = ({ startTime, endTime }: { startTime: string; endTime: string }) => moment(startTime).isAfter() && moment(endTime).isAfter();

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT_STYLE = "MMM DD, YYYY";
const DATE_FORMAT_STYLE_1 = "MMM DD - HH:mm";
const DATE_FORMAT_STYLE_2 = "MMM DD, YYYY hh:mm A";
const DATE_TIME_FORMAT_1 = "YYYY-MM-DDTHH:mm";
const DATE_TIME_FORMAT_2 = "MMM. D - hh:mm A";
const TIME_FORMAT_1 = "hh:mm a";
const TIME_FORMAT_2 = "HH:mm";

const EVENT_UPDATE_CLASS_INFO = "update-class-info";
const EVENT_UPDATE_NOTIFICATIONS = "update-notifications";
const EVENT_UPDATE_USER_INFO = "update-user-info";

const DISCUSSION_EVENTS: any = [EnumNotificationEvent.DISCUSSION_STARTED, EnumNotificationEvent.DISCUSSION_REPLY];

const SIGNAL_REMOVED_FROM_CLASS: string[] = [EnumSignal.REMOVED_FROM_PSEUDONYM, EnumSignal.ACCOUNT_DELETED];

const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const EXPRESSION_SLIDE_NUM = {
  WITHOUT_PDF: -1,
  VIDEO_SLIDE: -2,
};

const makeRestrictions = (type: SendInputType) => {
  switch (type) {
    case SendInputMode.Q:
      return { chars: 250, words: 50 };
    case SendInputMode.A:
      return { chars: 750, words: 150 };
    case SendInputMode.D:
      return { chars: 3000, words: 500 };
    default:
      return { chars: 250, words: 50 };
  }
};

const textSmallOverflow = (name: string, length: number) => (name.length > length ? `${name.substring(0, length)}...` : name);

export const extractVideoIdFromUrl = (url: string): string => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : "";
};

export {
  axiosConfig,
  useEffectOnce,
  preventDefaultEnter,
  DATE_FORMAT,
  DATE_FORMAT_STYLE,
  DATE_TIME_FORMAT_1,
  DATE_TIME_FORMAT_2,
  TIME_FORMAT_1,
  TIME_FORMAT_2,
  DATE_FORMAT_STYLE_1,
  parseStartEndTime,
  sortListByItem,
  EVENT_UPDATE_CLASS_INFO,
  EVENT_UPDATE_NOTIFICATIONS,
  EVENT_UPDATE_USER_INFO,
  countCharacters,
  countWords,
  DISCUSSION_EVENTS,
  SIGNAL_REMOVED_FROM_CLASS,
  getTitleQAD,
  getCreateUpdateDate,
  isLectureLive,
  IS_SAFARI,
  makeRestrictions,
  updateStateHelper,
  textSmallOverflow,
  isLectureFuture,
  EXPRESSION_SLIDE_NUM,
  DATE_FORMAT_STYLE_2,
};
