import { mdiFlagRemove, mdiFlagRemoveOutline } from "@mdi/js";

import Icon from "@mdi/react";
import { Grid, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendInputMode from "../../../constants/SendInputMode";
import { SendInputType } from "../../../interfaces/Types";
import Colors from "../../../utils/Colors";

interface RiseUpComponentProps {
  type?: SendInputType;
  disabled: boolean;
  handleRiseUp: any;
  hasRiseUp: boolean;
}
export default function RiseUpComponent({ type = SendInputMode.Q, disabled, handleRiseUp, hasRiseUp }: RiseUpComponentProps) {
  const fillColor = hasRiseUp ? Colors.GREEN_LIGHT : type === SendInputMode.A ? "darkgray" : Colors.WHITE;
  const subject = type === SendInputMode.Q ? "question" : "answer";
  const tooltipTitle = hasRiseUp ? `You clicked that this ${subject} should not be in a doghouse` : `Click if you think that this ${subject} should not be in a doghouse.`;
  return (
    <Grid item>
      <Tooltip title={tooltipTitle}>
        <IconButton disabled={disabled} onClick={handleRiseUp} sx={{ ml: 1, p: 0.1, cursor: disabled || hasRiseUp ? "auto !important" : "pointer" }} aria-label="flag-button">
          <Icon path={hasRiseUp ? mdiFlagRemove : mdiFlagRemoveOutline} color={disabled ? "#9cadc3" : fillColor} size={0.75} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
