import { Box, Grid } from "@mui/material";
import Images from "../../../../utils/Images";
import { CreateImage, CreateText, CreateTitle, boldText } from "./HowItWorkComponents";

export default function HowItWorkStudent() {
  return (
    <Box sx={{ color: "white" }}>
      <Grid container item xs={12}>
        <CreateTitle isFirst title="Obtaining a pseudonym" />
      </Grid>
      <CreateImage width={20} image={Images.STUDENT_MASK} />
      <CreateText>
        To receive a pseudonym for a specific lecture, you must attend that lecture, either in-person or on-line. At the beginning of the lecture, the teacher will share with you the Lecture Code.
        Once you enter the Lecture Code, you will land at the page with masks.
        <br />
        <br />
        Each mask contains one pseudonym. By clicking on the mask of your choice, you will be assigned a pseudonym.
        <br />
        <br />
        The first time you join the platform, in addition to the pseudonym, you also receive an AnonID and a password. Please use that AnonID whenever you login to our platform, during the class or
        after the class.
        <br />
        <br />
      </CreateText>

      <Grid container item xs={12}>
        <CreateTitle title="Student-Teacher Interaction" />
      </Grid>
      <CreateImage width={20} image={Images.STUDENT_REMOTE} />
      <CreateText>
        The main mode of interaction during the class is through the remote control.
        <br />
        <br />
        It provides an anonymous and instant feedback to the teacher. To make sure that the feedback is minimally disruptive, the option to post questions, answers, or discuss is disabled during the
        lecture. However, the teacher can enable it at any time if it is needed.
      </CreateText>
      <Grid container item xs={12} pt={1}>
        <CreateText>
          There are five options on the remote control.
          <ul>
            <li>{boldText("FF")} (Fast Forward), if the teacher should go faster; for example, if the material is too simple.</li>
            <li>{boldText("REW")} (Rewind), if the teacher is going too fast, and/or should go back and explain the material again.</li>
            <li>
              {boldText("Pause")}, if the student needs time to reflect. This is also a signal to the teacher to pause the lecture and allow students to ask questions (either through the platform or
              verbally).
            </li>
            <li>{boldText("I’m lost")}, if the student doesn’t understand the material.</li>
            <li>{boldText("Great")}, if everything is clear.</li>
          </ul>
        </CreateText>
      </Grid>

      <CreateTitle title="Questions, Answers and Discussions (Q/A/D)" />
      <CreateText>
        Unlike other platforms where posting under a pseudonym is an option, in our platform a student can post a Q/A/D only under a pseudonym. If you did not attend a specific lecture (you don’t have
        a pseudonym for that lecture), you cannot make a new post for that lecture, but you could view all the Q/A/Ds.
        <br />
        <br />
        To make it easy to find relevant information, Q/A/Ds are grouped by lectures. A teacher can make a post either under a pseudonym or as a teacher.
        <br />
        <br />
        To discourage trivial posts that just clutter the space, the number of answers to each question is limited to two. To avoid endless threads that involve numerous people, only two people can be
        involved in a discussion. If you would like to engage in a discussion, you can do so by making a comment on a specific answer.
        <br />
        <br />
        Once posted, a Q/A is visible to all students, but it becomes visible to the teacher only after it is ‘filtered’ by a sufficient number of students. This filtering is done by the algorithm.
      </CreateText>

      <CreateTitle title="Flagging and Reputation" />
      <CreateText>
        Flags are used to label Q/As that have inappropriate or offensive content and should not be used because a user does not agree with the logical content of the post. If a Q/A receives enough
        flags, it is removed to the flagged area (a ‘doghouse’). All the Q/As in the doghouse area are visible to all the students but not to the teacher. Once a student presses a flag button, the
        flag color changes to red only on that student’s page.
        <br />
        <br />
        A Q/A can be moved back to the regular Q&A area if enough users do not think that the Q/A should be in the doghouse. Every time a Q/A goes to the doghouse, the reputation of the person who
        posted a Q/A is reduced. A person that has reputation below some threshold is not allowed to post a new Q/A. However, reputation can be regained over time. All this is calculated by the
        algorithm.
        <br />
        <br />
        Please use flagging judiciously. If you flag to many Q/As and many other users disagree with you, your reputation might be decreased.
      </CreateText>

      <CreateTitle title="Some icons/buttons of interest:" />
      <CreateImage width={4.5} image={Images.ICON_LOCK} />
      <CreateText>
        {boldText("Lock")} icon, on Q/As cards means that you can only view a Q/A and not interact with it (vote, post answers or enter discussions). This can happen if you are viewing Q/As from the
        lectures that you did not attend and therefore have not received a pseudonym for that lecture.
      </CreateText>
      <br />
      <br />
      <CreateImage width={10} image={Images.LIVE_LECTURE_HEADER} />
      <CreateText>{boldText("Live")} button, for students, can appear in the header if the lecture is live. Pressing the live button will take you to the remote control.</CreateText>
      <br />
      <br />
      <CreateImage width={5} image={Images.WHO_AM_I} />
      <CreateText>
        {boldText("Who am I?")} icon/button. Since students are assigned a new pseudonym for every lecture they attend, it might be hard to track your virtual identity especially if you are switching
        lectures. To figure out your current pseudonym, press the ‘Who am I?’ button.
      </CreateText>
      <br />
      <br />
      <CreateImage width={15} image={Images.JOIN_LECTURE} />
      <CreateText>
        {boldText("Join Lecture")}, located within the Menu, allows students to switch from one lecture to another. However, a student can attend only one live lecture at a time. If for example you
        are participating in a live lecture, and decide to join another live lecture (e.g., from another course), you will be signed out from the first live lecture.
      </CreateText>
    </Box>
  );
}
