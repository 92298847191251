import { OutlinedInput, OutlinedInputProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const OutlinedInputCustom = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => ({
  borderRadius: "30px",
  color: "black",
  backgroundColor: "white",
  ".MuiOutlinedInput-input": {
    padding: "12.5px 14px",
  },
}));

export default OutlinedInputCustom;
