import SocketIO from "socket.io-client";
import { BACKEND_BASE_URL, SOCKET_ID_HISTORY_KEY } from "../constants";
import { getAccessToken } from "../utilsVue";

class BaseSocket {
  constructor(connectionString, options = {}) {
    this.connection = SocketIO(connectionString, {
      autoConnect: false,
    });

    this.options = options;

    this.lastConnection = null;
    this.lastDisconnection = null;

    // TODO: Remove, just for debugging purposes
    this.connection.on("connect_error", () => console.error("ERROR WHILE CONNECTING TO SOCKET", this.connection));

    this.on("connect", () => {
      // console.log("Connected to socket, checking previous ids...");
      this.lastConnection = new Date();

      const socketIdHistory = localStorage.getItem(SOCKET_ID_HISTORY_KEY);
      let newIdHistory = {};
      if (socketIdHistory) {
        newIdHistory = JSON.parse(socketIdHistory);
      }
      newIdHistory[this.connection.nsp] = this.connection.id;
      localStorage.setItem(SOCKET_ID_HISTORY_KEY, JSON.stringify(newIdHistory));
    });

    this.on("disconnect", () => {
      this.lastDisconnection = new Date();
    });
  }

  isConnected() {
    return this.connection.connected;
  }

  connect({ query } = {}) {
    // handles reconnecting
    const previousId = this.getPreviousSocketId();
    // console.log("PRE", this.connection?.auth?.lectureNum);
    // console.log("SAD", query?.lectureNum);
    this.connection.auth = {
      bearer: getAccessToken(),
      ...query,
    };
    if (previousId) {
      // console.log("Previous id found, injecting ...");
      this.connection.auth.previousId = previousId;
    }

    // TODO: remove
    // console.log("Connecting with", this.connection);

    this.connection.connect();
  }

  on(event, method) {
    this.connection.on(event, method);
  }

  off(event, method) {
    this.connection.off(event, method);
  }

  emit(event, payload) {
    // TODO: Remove
    // console.log("EMITTING", event, this.connection);
    if (this.connection.disconnected && this.options.connectBeforeEmit) {
      console.warn("Tried to emit with disconnected socket, automatically reconnecting...");
      this.connection.connect();
    }
    this.connection.emit(event, payload);
  }

  disconnect() {
    this.connection?.disconnect();
  }

  getPreviousSocketId() {
    const socketIdHistory = localStorage.getItem(SOCKET_ID_HISTORY_KEY);
    if (!socketIdHistory) {
      return null;
    }
    const socketNamespace = this.connection.nsp;
    return JSON.parse(socketIdHistory)[socketNamespace];
  }
}

export const ClassIdGeneratorSocket = new BaseSocket(`${BACKEND_BASE_URL}/class_id_generator`, { connectBeforeEmit: true });

export const WaitingRoomSocket = new BaseSocket(`${BACKEND_BASE_URL}/waiting_room`);

export const RemoteSocket = new BaseSocket(`${BACKEND_BASE_URL}/expressions`);

export const SignalSocket = new BaseSocket(`${BACKEND_BASE_URL}/signals`);

// TODO: Make generic
export const allSockets = {
  // ClassIdGeneratorSocket,
  WaitingRoomSocket,
  RemoteSocket,
  SignalSocket,
};
