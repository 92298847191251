import { SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trigger } from "../../../../constants/events";
import { useAppContext } from "../../../../context/AuthContext";
import { Answer, Course, Lecture } from "../../../../controller";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import AnswerType from "../../../../interfaces/answer/AnswerType";
import CourseDto from "../../../../interfaces/course/CourseDto";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import OptionItemDto from "../../../../interfaces/option-item/OptionItemDto";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import Routes from "../../../../utils/Routes";
import UserRole from "../../../../utils/UserRole";
import { EVENT_UPDATE_USER_INFO, updateStateHelper, useEffectOnce } from "../../../../utils/Utils";
import { optionsSortBy } from "./config";

interface AnswerTabsType {
  my: AnswerType[];
  all: AnswerType[];
  flagged: AnswerType[];
}

interface MyAnswersServiceType {
  tabValue: number;
  handleTabChange: (e: React.SyntheticEvent, newValue: number) => void;
  sortBy: number;
  handleSortBy: (e: SelectChangeEvent) => void;
  courses: OptionItemDto[];
  lectures: OptionItemDto[];
  handleCoursesChange: (e: any) => void;
  handleLecturesChange: (e: any) => void;
  selectedCourses: number[];
  setSelectedCourses: any;
  selectedLectures: number[];
  setSelectedLectures: any;
  callbackUpdateState: (answerId: number, propName: string, response: any) => void;
  callbackUpdateQuestionState: (questionId: number, propName: string, response: any) => void;
  showFilter: boolean;
  toggleFilter: any;
  answers: AnswerTabsType;
  hideAllAnswers: boolean;
  toogleHideAllAnswer: () => void;
  mobileHeight: () => string;
}

const initAnswers = { my: [], all: [], flagged: [] };
export default function MyAnswersService(): MyAnswersServiceType {
  const { selectedCourse: selectedCourseGlobal } = useAppContext();
  const location: any = useLocation();
  const { isTeacher } = UserRole();
  const { isMobile } = useQueryMedia();
  const history = useHistory();

  const { initLectureId, goToTab, locationState } = location?.state ?? {};

  const [tabValue, setTabValue] = useState<number>(goToTab ?? 0);
  const [courses, setCourses] = useState<OptionItemDto[]>([]);
  const [lectures, setLectures] = useState<any>([]);
  const [answers, setAnswers] = useState<AnswerTabsType>(initAnswers);
  const [hideAllAnswers, setHideAllAnswers] = useState<boolean>(true);
  const [initLectureSet, setInitLectureSet] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<number>(optionsSortBy[0].id);

  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [selectedLectures, setSelectedLectures] = useState<number[]>([]);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const toggleFilter = () => setShowFilter(!showFilter);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    history.replace({
      pathname: isTeacher ? Routes.TEACHER_MY_ANSWERS : Routes.STUDENT_MY_ANSWERS,
      state: { goToTab: newValue },
    });

    setTabValue(newValue);
  };

  const handleSortBy = (e: SelectChangeEvent) => setSortBy(+e.target.value);

  const handleCoursesChange = (e: any) => setSelectedCourses(e.target.value);

  const handleLecturesChange = (e: any) => setSelectedLectures(e.target.value);

  const toogleHideAllAnswer = () => setHideAllAnswers(!hideAllAnswers);

  const mobileHeight = () => {
    let height = isMobile ? window.screen.height - 205 : 495;
    let additionalHeight = 0;

    if (showFilter) {
      additionalHeight += isMobile ? 30 : 0;
    }

    return `${height - additionalHeight}px`;
  };

  useEffectOnce(() => {
    getAllCourses();
  });

  const getAllCourses = () => {
    Course.getAllCourses()
      .then(({ data }: any) => {
        setSelectedCourses([selectedCourseGlobal.id]);
        setCourses(data.data.map(({ id, name }: CourseDto) => ({ id: id, label: name })));
      })
      .catch((err) => console.error(err.data));
  };

  const getLecturesByCourses = useCallback(() => {
    Lecture.getLecturesByCoursesId(selectedCourses)
      .then(({ data }: any) => {
        if (locationState?.prevSelectedLectures.length > 0) {
          setSelectedLectures(locationState?.prevSelectedLectures);
        } else {
          setSelectedLectures(data.data.map(({ id }: LectureDto) => id));
        }
        setLectures(
          data.data.map(({ name, courseId, ...other }: LectureDto) => ({
            label: name,
            description: `${name} - ${courses.find((course: any) => course.id === courseId)?.label}`,
            ...other,
          }))
        );
      })
      .catch((err) => console.error(err.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  const getAnswersByCoursesLectures = useCallback(() => {
    Answer.getAnswersByLecturesId(selectedLectures)
      .then(({ data }) => {
        const res = data.data;
        const notFlagged = res.filter((a: AnswerType) => !a.flagged);
        setAnswers({
          my: notFlagged.filter((a: AnswerType) => a.selfPosted),
          all: notFlagged,
          flagged: res.filter((a: AnswerType) => a.flagged),
        });
      })
      .catch((error) => console.log(error));
  }, [selectedLectures]);

  const callbackUpdateState = (answerId: number, propName: string, response: any) => {
    if (answerId && propName && response) {
      const tabName = tabValue === EnumTabs.A_MY ? "my" : tabValue === EnumTabs.A_ALL ? "all" : "flagged";
      const tabAnswers: AnswerType[] = [...answers[tabName]];
      const index = tabAnswers.findIndex((a: AnswerType) => a.id === answerId);
      const answerToUpdate: AnswerType = { ...tabAnswers[index] };
      const answerUpdated = updateStateHelper(answerToUpdate, propName, response);
      tabAnswers[index] = answerUpdated;
      setAnswers({ ...answers, [tabName]: tabAnswers });
    } else {
      getAnswersByCoursesLectures();
    }
  };

  const callbackUpdateQuestionState = (questionId: number, propName: string, response: any) => {
    const tabName = tabValue === EnumTabs.A_MY ? "my" : tabValue === EnumTabs.A_ALL ? "all" : "flagged";
    const tabAnswers: AnswerType[] = [...answers[tabName]];
    const answerIndex = tabAnswers.findIndex((a: AnswerType) => a.question.id === questionId);
    const answerToUpdate: AnswerType = { ...tabAnswers[answerIndex] };
    const questionUpdated = updateStateHelper(answerToUpdate.question, propName, response);
    answerToUpdate.question = questionUpdated;
    tabAnswers[answerIndex] = answerToUpdate;
    setAnswers({ ...answers, [tabName]: tabAnswers });
  };

  useEffect(() => {
    if (selectedLectures.length && !initLectureSet && initLectureId) {
      setSelectedLectures([initLectureId]);
      setInitLectureSet(true);
    }
  }, [selectedLectures, initLectureSet, initLectureId]);

  useEffect(() => {
    if (selectedCourses.length) {
      getLecturesByCourses();
    } else {
      setLectures([]);
      setSelectedLectures([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses, getLecturesByCourses]);

  useEffect(() => {
    if (selectedLectures.length) {
      getAnswersByCoursesLectures();

      if (selectedLectures.length === 1) {
        const lecture = lectures.find((l: any) => l.id === selectedLectures[0]);
        trigger(EVENT_UPDATE_USER_INFO, { courseId: selectedCourses[0], lectureNum: lecture?.lectureNum });
      } else {
        trigger(EVENT_UPDATE_USER_INFO, { courseId: undefined, lectureNum: undefined });
      }
    } else {
      setAnswers(initAnswers);
      trigger(EVENT_UPDATE_USER_INFO, { courseId: undefined, lectureNum: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLectures]);

  return {
    tabValue,
    handleTabChange,
    courses,
    lectures,
    sortBy,
    handleSortBy,
    selectedCourses,
    handleCoursesChange,
    handleLecturesChange,
    setSelectedCourses,
    selectedLectures,
    setSelectedLectures,
    callbackUpdateState,
    callbackUpdateQuestionState,
    showFilter,
    toggleFilter,
    answers,
    hideAllAnswers,
    toogleHideAllAnswer,
    mobileHeight,
  };
}
export type { MyAnswersServiceType };
