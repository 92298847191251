import SvgIcon from "@mui/material/SvgIcon";
import Colors from "../../../utils/Colors";

const EditIcon = ({ className, width, height, fillColor = Colors.WHITE }: any) => (
  <SvgIcon width="58.772" height="58.621" viewBox="0 0 58.772 58.621" className={className} style={{ color: fillColor, width: width, height: height }}>
    <defs />
    <g id="Group_31" data-name="Group 31" transform="translate(-9039.446 -5236.367)">
      <path id="Path_33" d="M9090.887 5237.623l-1.6 1.595 6.077 6.075 1.6-1.595a4.3 4.3 0 0 0-6.077-6.075z" className="cls-1" data-name="Path 33" />
      <path id="Path_34" d="M9086.641 5241.717l-27.18 27.181-2.021 8.1 8.1-2.025 27.183-27.178z" className="cls-1" data-name="Path 34" />
      <path id="Path_35" d="M9039.446 5294.988h53.428v-36.441l-4.513 4.514v27.414h-44.4v-44.4h27.414l4.513-4.514h-36.44z" className="cls-1" data-name="Path 35" />
    </g>
  </SvgIcon>
);
export default EditIcon;
