// NEW BACKEND

import { http } from "../controller/http";

export const Teacher = {
  // Get all user anonIds that exist. Some may belong to teachers.
  getAllTeachers: () => http.get("/teachers"),
  // Get info about current signed in teacher.
  getCurrentTeacher() {
    return http.get("/teacher");
  },

  // Manually create a new teacher
  createTeacher(username, firstName, lastName, email, password, university) {
    return http.post("/teacher", {
      username,
      firstName,
      lastName,
      email,
      password,
      university,
    });
  },

  // Update current teacher
  updateCurrentTeacher(firstName, lastName, email, password) {
    return http.put("/teacher", {
      firstName,
      lastName,
      email,
      password,
    });
  },

  // Get a specific teacher's info
  getTeacher() {
    return http.get(`/teacher`);
  },

  // Update a specific teacher.
  updateTeacher(username, firstName, lastName, email, password) {
    return http.put(`/teacher/${username}`, {
      firstName,
      lastName,
      email,
      password,
    });
  },

  // Route for teachers to log in.
  async loginTeacher(username, password) {
    let result = await http.post(`/teacher/login`, { username, password });
    return result;
  },
  // Route for teacher to change password
  changePassword(newPassword, currentPassword, username) {
    return http.put(`/teachers/passwordupdate`, {
      newPassword,
      currentPassword,
      username,
    });
  },

  async getAdminStatistics() {
    return await http.get(`/teacher/adminStatistics/`);
  },
};
