import { Button } from "@mui/material";
import LikeIconFull from "../icons/LikeIconFull";

const EDGE_IN = "35px";

interface LikeButtonProps {
  onClick: () => void;
  disabled?: boolean;
  label?: number;
}

const LikeButton = ({ onClick, disabled, label }: LikeButtonProps) => (
  <Button
    sx={{ ...(disabled && { opacity: "0.5" }), color: "white !important" }}
    variant="outlined"
    className="button-reaction-button"
    size="small"
    startIcon={<LikeIconFull edgeLength={EDGE_IN} className="button-reaction-icon" />}
    onClick={onClick}
    disabled={disabled}
  >
    <label className="button-reaction-label" style={{ cursor: disabled ? "auto" : "pointer" }}>
      {label}
    </label>
  </Button>
);

export default LikeButton;
