import { http } from "../controller/http";

export const Question = {
  // Get all questions by lecturesId.
  getQuestionsByLecturesId: (lecturesId) => http.post(`/courses/lectures/questions/${JSON.stringify(lecturesId)}`),

  // Get all questions for a lecture.
  getQuestionsFromLecture(courseId, lectureNum) {
    return http.get(`/course/${courseId}/lecture/${lectureNum}/questions`);
  },

  // Post a new question
  postQuestion(courseId, lectureNum, text, slideNumber, teacherQuestion, teacherCanView) {
    return http.post(`/course/${courseId}/lecture/${lectureNum}/question`, {
      text,
      slideNumber,
      teacherQuestion,
      teacherCanView,
    });
  },

  // Get a question
  async getQuestion(courseId, lectureNum, questionId) {
    const data = await http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}`);
    return data;
  },

  // Updates a question
  updateQuestion(courseId, lectureNum, questionId, text, teacherQuestion) {
    return http.put(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}`, {
      text,
      teacherQuestion,
    });
  },

  // Deletes a question
  deleteQuestion(courseId, lectureNum, questionId) {
    return http.delete(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}`);
  },

  // vote question, up 1, down -1
  voteQuestion: async (courseId, lectureNum, questionId, vote) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/vote/${vote}`),

  toggleQuestionFlag: async (courseId, lectureNum, questionId) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/flag`),

  riseUpQuestion: async (courseId, lectureNum, questionId) => http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/riseUp`),

  getHistory: (courseId, lectureNum, questionId) => http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/history`),
};
