import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FlagFullIcon from "../icons/FlagFullIcon";
import FlagIcon from "../icons/FlagIcon";

interface FlagComponentProps {
  disabled: boolean;
  selfFlagged: boolean;
  handleToggleFlag: any;
  color?: string;
}
export default function FlagComponent({ disabled, selfFlagged, handleToggleFlag, color }: FlagComponentProps) {
  return (
    <Grid item sx={{ cursor: disabled ? "default" : "pointer" }}>
      <IconButton disabled={disabled} onClick={handleToggleFlag} sx={{ ml: 1, p: 0.1 }} aria-label="flag-button">
        {selfFlagged ? <FlagFullIcon /> : <FlagIcon color={disabled ? "darkgray" : color} />}
      </IconButton>
    </Grid>
  );
}
