import { Grid } from "@mui/material";
import Bar from "./Bar";

interface BarChartType {
  data: Array<number>;
  color: string;
  maxValue: number;
  height: number;
  segmentNum: number;
  width: number;
}

function BarChart(props: BarChartType) {
  const { data, color, maxValue, height, segmentNum, width } = props;
  const multiplicator = maxValue > 0 ? height / maxValue : 0;

  return (
    <Grid container item style={{ width: width }} alignItems="flex-end" flexDirection="row">
      {data?.map((bar: any, index: number) => {
        return <Bar key={index} width={width / segmentNum} height={bar * multiplicator} color={color} />;
      })}
      <hr style={{ color: "white", width: "100%" }} />
    </Grid>
  );
}

export default BarChart;
