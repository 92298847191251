import { Grid } from "@mui/material";
import TeacherBreadcrumbs from "../../pages/teacher-breadcrumbs/TeacherBreadcrumbs";
import TeacherSidebar from "./TeacherSidebar";

export default function TeacherLayout({ children }: any) {
  const height = document?.getElementsByClassName("teacher-sidebar").length > 0 ? document?.getElementsByClassName("teacher-sidebar")[0].clientHeight : null;

  return (
    <div className="teacher-layout">
      <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={2}>
        <Grid item xs={9}>
          <TeacherBreadcrumbs />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="flex-start" columnSpacing={2}>
        <Grid item md={3} lg={3} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
          <TeacherSidebar />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={8} minHeight={height ?? 500}>
          {children}
        </Grid>
        <Grid item md={0} lg={1} />
      </Grid>
    </div>
  );
}
