import CancelIcon from "@mui/icons-material/Cancel";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Colors from "../../../utils/Colors";

export interface DialogTitleAnonProps {
  children?: React.ReactNode;
  onClose: () => void;
  color: string;
}

const DialogTitleAnon = (props: DialogTitleAnonProps) => {
  const { children, onClose, color, ...other } = props;

  return (
    <DialogTitle sx={{ color: color, m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon fontSize="medium" sx={{ color: Colors.BLUE_DIANNE }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default DialogTitleAnon;
