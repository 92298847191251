import { useState } from "react";
import { useHistory } from "react-router-dom";
import { INIT_PAGE_NUM } from "../../../../constants";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture, RemoteSocket } from "../../../../controller";
import useLectureType, { UseLectureTypeExport } from "../../../../hooks/useLectureType";
import ConfirmFunctionAndParametersType from "../../../../interfaces/dialog/ConfirmFunctionAndParametersType";
import DialogYesNoDataType from "../../../../interfaces/dialog/DialogYesNoDataType";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Routes from "../../../../utils/Routes";
import { isLectureLive, useEffectOnce } from "../../../../utils/Utils";

interface ClassStatusServiceType {
  liveLecture: LectureDto | undefined;
  setLiveLecture: any;
  handleEndLecture: any;
  handleContinuePresentation: any;
  newConnectionWarning: boolean;
  setNewConnectionWarning: (value: boolean) => void;
  closeWarningStartLecture: any;
  noPresentationConfirmActionFunc: ConfirmFunctionAndParametersType | undefined;
  showNoPresentationDialog: DialogYesNoDataType;
  setShowNoPresentationDialog: (val: DialogYesNoDataType) => void;
  noPresentation: boolean;
}

const dialogInit = { open: false, title: "", content: "" } as DialogYesNoDataType;
export default function ClassStatusService(): ClassStatusServiceType {
  const history = useHistory();
  const { selectedCourse, updateLastViewedSlide } = useAppContext();
  const courseId = selectedCourse.id;
  const [liveLecture, setLiveLecture] = useState<LectureDto>();
  const { presentationBlob, noPresentation }: UseLectureTypeExport = useLectureType({ lectureNum: liveLecture?.lectureNum });
  const [newConnectionWarning, setNewConnectionWarning] = useState<boolean>(false);
  const [noPresentationConfirmActionFunc, setNoPresentationConfirmActionFunc] = useState<ConfirmFunctionAndParametersType>();
  const [showNoPresentationDialog, setShowNoPresentationDialog] = useState<DialogYesNoDataType>(dialogInit);

  useEffectOnce(() => {
    getLectures();
  });

  const getLectures = async () => {
    const { data } = await Lecture.getCourseLectures(courseId);
    if (data?.message) {
      const findLiveLecture = data.data.find(isLectureLive);
      setLiveLecture(findLiveLecture);
    }
  };

  const handleOpenTeacherRemoteDialog = () => {
    setShowNoPresentationDialog({ open: true, title: "No presentation has been uploaded. Do you want to proceed?" });
    setNoPresentationConfirmActionFunc({ function: handleOpenTeacherRemote });
  };

  const openTeacherRemote = () => history.push(Routes.TEACHER_REMOTE.replace(":lectureNum", String(liveLecture?.lectureNum)));
  const openPresentation = () => history.push(Routes.LECTURE_PRESENTATION.replace(":lectureNum", String(liveLecture?.lectureNum)));
  const startLecture = (callback: any) => {
    Lecture.startLecture(courseId, liveLecture?.lectureNum)
      .then((res: any) => callback())
      .catch((err: any) => console.log(err));
  };

  const handleOpenTeacherRemote = async () => startLecture(() => openTeacherRemote());
  const handleStartLecture = async () => {
    if (presentationBlob) {
      startLecture(() => {
        // make sure to reset last remembered slide when we start a new lecture
        updateLastViewedSlide(INIT_PAGE_NUM);
        openPresentation();
      });
    } else {
      handleOpenTeacherRemoteDialog();
    }
  };

  const handleEndLecture = async () => {
    await Lecture.endLecture(courseId, liveLecture?.lectureNum);
    RemoteSocket.disconnect();
    getLectures();
  };

  const handleContinuePresentation = () => (presentationBlob ? openPresentation() : openTeacherRemote());

  const closeWarningStartLecture = () => {
    setNewConnectionWarning(false);
    handleStartLecture();
  };

  return {
    liveLecture,
    setLiveLecture,
    handleEndLecture,
    handleContinuePresentation,
    newConnectionWarning,
    setNewConnectionWarning,
    closeWarningStartLecture,
    noPresentationConfirmActionFunc,
    showNoPresentationDialog,
    setShowNoPresentationDialog,
    noPresentation,
  };
}
export type { ClassStatusServiceType };
