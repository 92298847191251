import { useEffect, useState } from "react";
import { useAppContext } from "../context/AuthContext";
import { Lecture } from "../controller";
import { SlideInteractions } from "../controller/SlideInteraction";

interface UseLectureTypeProps {
  lectureNum: number | undefined;
}
interface UseLectureTypeExport {
  presentationBlob: string;
  noPresentation: boolean;
  isVideoInteraction: boolean;
}
export default function useLectureType({ lectureNum }: UseLectureTypeProps): UseLectureTypeExport {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const [presentationBlob, setPresentationBlob] = useState<string>("");
  const [noPresentation, setNoPresentation] = useState<boolean>(false);
  const [isVideoInteraction, setIsVideoInteraction] = useState<boolean>(false);

  useEffect(() => {
    if (courseId && lectureNum) {
      downloadPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, lectureNum]);

  const downloadPresentation = async () => {
    const blob = await Lecture.downloadPresentation(courseId, lectureNum);
    blob && setPresentationBlob(blob);
    existSlideInteraction();
  };

  const existSlideInteraction = () => {
    SlideInteractions.existSlideInteraction(courseId, lectureNum)
      .then(({ data }: any) => {
        const { interactionWithoutPdf, interactionVideo } = data.data;
        setNoPresentation(interactionWithoutPdf);
        setIsVideoInteraction(interactionVideo);
      })
      .catch((err: any) => console.log(err));
  };

  return { presentationBlob, noPresentation, isVideoInteraction };
}
export type { UseLectureTypeProps, UseLectureTypeExport };
