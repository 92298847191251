import { useAppContext } from "../context/AuthContext";
import EnumRole from "./enum/EnumRole";

interface UserRoleType {
  isTeacher: boolean;
  isStudent: boolean;
}

export default function UserRole(): UserRoleType {
  const { role } = useAppContext();
  const isTeacher = role === EnumRole.TEACHER;
  const isStudent = role === EnumRole.STUDENT;

  return { isTeacher, isStudent };
}
