import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trigger } from "../../../../constants/events";
import { Notification } from "../../../../controller";
import DetailsType from "../../../../interfaces/notification/DetailsType";
import NotificationType from "../../../../interfaces/notification/NotificationType";
import EnumNotificationEvent from "../../../../utils/enum/EnumNotificationEvent";
import EnumNotificationTab from "../../../../utils/enum/EnumNotificationTab";
import Routes from "../../../../utils/Routes";
import { DISCUSSION_EVENTS, EVENT_UPDATE_NOTIFICATIONS, useEffectOnce } from "../../../../utils/Utils";

interface NotificationsServiceType {
  allNotifications: AllNotificationType;
  acknowledgeById: (id: number) => void;
  acknowledgeAllByEvents: () => void;
  tabValue: number;
  handleTabChange: (e?: any, newValue?: number) => void;
  handleNotificationClick: (notification: NotificationType) => void;
  visibleReadAll: boolean;
  unreadNotifications: any;
}

interface AllNotificationType {
  questions: NotificationType[];
  answers: NotificationType[];
  discussions: NotificationType[];
  flaggedQ: NotificationType[];
  flaggedA: NotificationType[];
}
interface UnreadNotificationType {
  questionsUnread: number;
  answerUnread: number;
  discussionsUnread: number;
  flaggedQUnread: number;
  flaggedAUnread: number;
}

const getEventsByTab = (tab: number): string[] => {
  switch (tab) {
    case EnumNotificationTab.QUESTIONS:
      return [EnumNotificationEvent.QUESTION_POSTED];
    case EnumNotificationTab.ANSWERS:
      return [EnumNotificationEvent.QUESTION_ANSWERED];
    case EnumNotificationTab.DISCUSSIONS:
      return DISCUSSION_EVENTS;
    case EnumNotificationTab.FLAGGED_Q:
      return [EnumNotificationEvent.QUESTION_FLAGGED];
    case EnumNotificationTab.FLAGGED_A:
      return [EnumNotificationEvent.ANSWER_FLAGGED];
    default:
      return [];
  }
};

const allNotificationInit = { questions: [], answers: [], discussions: [], flaggedQ: [], flaggedA: [] };
const unreadNotificationInit = { questionsUnread: 0, answerUnread: 0, discussionsUnread: 0, flaggedQUnread: 0, flaggedAUnread: 0 };
export default function NotificationsService(): NotificationsServiceType {
  const location: any = useLocation();
  const history = useHistory();
  const tab = location?.state?.tab ?? EnumNotificationTab.QUESTIONS;
  const [allNotifications, setAllNotifications] = useState<AllNotificationType>(allNotificationInit);
  const [unreadNotifications, setUnreadNotifications] = useState<UnreadNotificationType>(unreadNotificationInit);

  const [tabValue, setTabValue] = useState<number>(tab);
  const [visibleReadAll, setVisibleReadAll] = useState<boolean>(false);

  useEffectOnce(() => getAllNotifications());

  useEffect(() => {
    switch (tabValue) {
      case EnumNotificationTab.QUESTIONS:
        setVisibleReadAll(hasSomeUnread(allNotifications?.questions));
        break;
      case EnumNotificationTab.ANSWERS:
        setVisibleReadAll(hasSomeUnread(allNotifications?.answers));
        break;
      case EnumNotificationTab.DISCUSSIONS:
        setVisibleReadAll(hasSomeUnread(allNotifications?.discussions));
        break;
      case EnumNotificationTab.FLAGGED_Q:
        setVisibleReadAll(hasSomeUnread(allNotifications?.flaggedQ));
        break;
      case EnumNotificationTab.FLAGGED_A:
        setVisibleReadAll(hasSomeUnread(allNotifications?.flaggedA));
        break;

      default:
        break;
    }
  }, [tabValue, allNotifications]);

  const hasSomeUnread = (notifications: NotificationType[]) => notifications?.some((n: NotificationType) => !n.acknowledged) ?? false;
  const numberOfUnread = (notifications: NotificationType[]) => notifications?.filter((n: NotificationType) => !n.acknowledged)?.length ?? 0;
  const existsData = (details: DetailsType) => details.lecture && details.question;

  const getAllNotifications = () => {
    Notification.getAllLatest()
      .then(({ data }) => {
        const active = data.data.filter(({ details }: NotificationType) => existsData(details));
        const questions = active.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_POSTED);
        const answers = active.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_ANSWERED);
        const discussions = active.filter(({ event }: NotificationType) => DISCUSSION_EVENTS.includes(event));
        const flaggedQ = active.filter(({ event }: NotificationType) => event === EnumNotificationEvent.QUESTION_FLAGGED);
        const flaggedA = active.filter(({ event }: NotificationType) => event === EnumNotificationEvent.ANSWER_FLAGGED);

        const questionsUnread = numberOfUnread(questions);
        const answerUnread = numberOfUnread(answers);
        const discussionsUnread = numberOfUnread(discussions);
        const flaggedQUnread = numberOfUnread(flaggedQ);
        const flaggedAUnread = numberOfUnread(flaggedA);

        setAllNotifications({
          questions,
          answers,
          discussions,
          flaggedQ,
          flaggedA,
        });
        setUnreadNotifications({
          questionsUnread,
          answerUnread,
          discussionsUnread,
          flaggedQUnread,
          flaggedAUnread,
        });
      })
      .catch((error) => console.log(error));
  };

  const acknowledgeById = async (notificationId: number) => {
    Notification.acknowledgeById(notificationId)
      .then(({ data }) => {
        trigger(EVENT_UPDATE_NOTIFICATIONS);
        getAllNotifications();
      })
      .catch((error) => console.log(error));
  };

  const acknowledgeAllByEvents = () => {
    Notification.acknowledgeAllByEvents(getEventsByTab(tabValue))
      .then(({ data }) => {
        trigger(EVENT_UPDATE_NOTIFICATIONS);
        getAllNotifications();
      })
      .catch((error) => console.log(error));
  };

  const handleTabChange = (e: any, newValue?: number) => setTabValue(newValue!);

  const handleNotificationClick = ({ id: notificationId, acknowledged, details }: NotificationType) => {
    const { lecture, question } = details;
    const { lectureNum } = lecture;
    const { id: questionId } = question;
    if (acknowledged) {
      goToAnswerPage({ lectureNum, questionId });
    } else {
      Notification.acknowledgeById(notificationId)
        .then(({ data }) => goToAnswerPage({ lectureNum, questionId }))
        .catch((error) => console.log(error));
    }
  };

  const goToAnswerPage = ({ lectureNum, questionId }: { lectureNum: number; questionId: number }) => {
    history.replace({
      pathname: Routes.PAGE_QUESTION_ANSWERS.replace(":lectureNum", String(lectureNum)).replace(":questionId", String(questionId)),
      state: {
        prevLocation: location?.pathname,
        goToTab: tab,
      },
    });
  };

  return {
    allNotifications,
    acknowledgeById,
    acknowledgeAllByEvents,
    tabValue,
    handleTabChange,
    handleNotificationClick,
    visibleReadAll,
    unreadNotifications,
  };
}
export type { NotificationsServiceType };
