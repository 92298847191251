import { Button, Grid, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { wsEvents } from "../../../../constants";
import { useAppContext } from "../../../../context/AuthContext";
import { User, WaitingRoomSocket } from "../../../../controller";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import EnumRole from "../../../../utils/enum/EnumRole";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import Routes from "../../../../utils/Routes";
import { useEffectOnce } from "../../../../utils/Utils";

let interval: any;
export const WaitingRoom = () => {
  const { updateSelectedCourse, updateRole } = useAppContext();
  const location: any = useLocation();
  const { isMobile } = useQueryMedia();

  const history = useHistory();
  const baseClass = "waiting-room";
  const [seconds, setSeconds] = useState<number>(5);
  const countRef: any = useRef(seconds);
  countRef.current = seconds;

  const [text, setText] = useState<string>("");
  const [receivedDenied, setReceivedDenied] = useState<boolean>(false);
  const [receivedAccess, setReceivedAccess] = useState<boolean>(false);
  const [alreadyAttending, setAlreadyAttending] = useState<boolean>(false);

  const handleRedirect = () => history.push(User.isLoggedIn() ? Routes.STUDENT_MY_LECTURES : Routes.ABOUT);

  const timer = () => {
    interval = setInterval(() => {
      setSeconds(countRef.current - 1);
      if (countRef.current === 0) {
        setSeconds(0);
        clearInterval(interval);
        handleRedirect();
      }
    }, 1000);
  };

  useEffectOnce(() => {
    window.addEventListener("beforeunload", confirmLeave);
    WaitingRoomSocket.connect({ query: { classId: location?.state?.classId } });

    WaitingRoomSocket.on(wsEvents.recv.ERROR_INVALID_CLASS_ID, handleInvalidClassId);
    WaitingRoomSocket.on(wsEvents.recv.CLASS_ALREADY_ATTENDING, handleAlreadyAttendingClass);
    WaitingRoomSocket.on(wsEvents.recv.ENTERED_WAITING_ROOM, handleEnterWaitingRoom);
    WaitingRoomSocket.on(wsEvents.recv.ACCESS_GRANTED, handleAccessGranted);
    WaitingRoomSocket.on(wsEvents.recv.ACCESS_DENIED, handleAccessDenied);
    WaitingRoomSocket.on(wsEvents.recv.LEFT_WAITING_ROOM, handleLeftWaitingRoom);
    return () => {
      clearInterval(interval);
      WaitingRoomSocket.disconnect();

      WaitingRoomSocket.off(wsEvents.recv.ERROR_INVALID_CLASS_ID, handleInvalidClassId);
      WaitingRoomSocket.off(wsEvents.recv.CLASS_ALREADY_ATTENDING, handleAlreadyAttendingClass);
      WaitingRoomSocket.off(wsEvents.recv.ENTERED_WAITING_ROOM, handleEnterWaitingRoom);
      WaitingRoomSocket.off(wsEvents.recv.ACCESS_GRANTED, handleAccessGranted);
      WaitingRoomSocket.off(wsEvents.recv.ACCESS_DENIED, handleAccessDenied);
      WaitingRoomSocket.off(wsEvents.recv.LEFT_WAITING_ROOM, handleLeftWaitingRoom);
      window.removeEventListener("beforeunload", confirmLeave);
    };
  });

  const handleEnterWaitingRoom = (data: any) => setText("Waiting to approve");

  const handleAccessGranted = (credentials: any, params: any) => {
    if (!params) {
      params = credentials;
    }
    const { anonId, password } = credentials;
    const { courseId, lectureNum } = params;

    updateRole(EnumRole.STUDENT);
    updateSelectedCourse({ id: courseId });
    setReceivedAccess(true);
    // if new user
    if (anonId && password) {
      history.push({ pathname: isMobile ? Routes.STUDENT_DOWNLOAD_PASSWORD_MOBILE : Routes.STUDENT_DOWNLOAD_PASSWORD, state: { anonId: anonId, password: password, lectureNum: lectureNum } });
    } else {
      history.push({
        pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
        state: {
          lectureNum: lectureNum,
          goToTab: EnumTabs.REMOTE,
        },
      });
    }
  };

  const handleAccessDenied = (data: any) => {
    setText("Access denied");
    setReceivedDenied(true);
    timer();
  };
  const handleInvalidClassId = () => {
    setText("Invalid class id");
    setReceivedDenied(true);
    timer();
  };

  const handleLeftWaitingRoom = () => {
    WaitingRoomSocket.disconnect();
    setText("Disconnected from Waiting Room");
  };

  const handleAlreadyAttendingClass = () => {
    setAlreadyAttending(true);
    setText("You are already attending this class");
    timer();
  };

  const confirmLeave = (e: any) => {
    if (receivedDenied || receivedAccess) return true;
    const confirm = window.confirm("If you leave the waiting room, you will have to join the lecture again");
    if (e) {
      e.preventDefault();
    }
    return confirm;
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" className={`${baseClass}`}>
      {!(receivedDenied || alreadyAttending) && (
        <Grid item mb={3}>
          <Typography variant="h5">Please wait, the host will let you soon.</Typography>
        </Grid>
      )}
      <Grid item mb={3}>
        <Typography variant="h5">{text}</Typography>
      </Grid>
      {(receivedDenied || alreadyAttending) && (
        <>
          <Grid item mb={3}>
            <Typography variant="h5">You will be automatically redirectred in:</Typography>
          </Grid>
          <Grid item mb={3}>
            <Typography variant="h5">{countRef.current}</Typography>
          </Grid>
        </>
      )}
      <Grid item>
        <Button variant="contained" className={`${baseClass}__return-button`} onClick={handleRedirect}>
          Return
        </Button>
      </Grid>
    </Grid>
  );
};
