import { Grid } from "@mui/material";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import StudentBreadcrumbs from "../../pages/student-breadcrumbs/StudentBreadcrumbs";
import StudentSidebar from "./StudentSidebar";

export default function StudentLayout({ children }: any) {
  const { isMobile } = useQueryMedia();
  const height = document?.getElementsByClassName("student-sidebar").length > 0 ? document?.getElementsByClassName("student-sidebar")[0].clientHeight : null;

  return (
    <div className="student-layout">
      <Grid container item xs={12} justifyContent="center">
        {!isMobile && (
          <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={2}>
            <Grid item xs={9}>
              <StudentBreadcrumbs />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" alignItems="flex-start" columnSpacing={2}>
          <Grid item md={3} lg={3} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
            <StudentSidebar />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8} minHeight={height ?? 500}>
            {children}
          </Grid>
          <Grid item md={0} lg={1} />
        </Grid>
      </Grid>
    </div>
  );
}
