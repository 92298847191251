import { mdiHumanMaleBoard, mdiMessageCheckOutline } from "@mdi/js";
import Icon from "@mdi/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsIcon from "@mui/icons-material/Settings";
import EnumSidemenuIcon from "../../../utils/enum/EnumSidemenuIcon";
import Routes from "../../../utils/Routes";
import AnswerIcon from "../../shared/icons/AnswerIcon";
import QuestionIcon from "../../shared/icons/QuestionIcon";

export const studentItems1 = [
  { icon: <HomeIcon />, label: "My Courses", to: Routes.STUDENT_MY_COURSE, id: EnumSidemenuIcon.COURSES },
  { icon: <Icon path={mdiHumanMaleBoard} size={1} />, label: "My Lectures", to: Routes.STUDENT_MY_LECTURES, id: EnumSidemenuIcon.LECTURES },
  { icon: <AddCircleIcon />, label: "Join Lecture", to: Routes.OLD_OR_NEW_STUDENT, id: EnumSidemenuIcon.JOIN_LECTURE },
];
export const studentItems2 = [
  { icon: <QuestionIcon />, label: "Questions", to: Routes.STUDENT_MY_QUESTIONS, id: EnumSidemenuIcon.MY_QUESTIONS },
  { icon: <AnswerIcon />, label: "Answers", to: Routes.STUDENT_MY_ANSWERS, id: EnumSidemenuIcon.MY_ANSWERS },
  { icon: <QuestionAnswerIcon />, label: "Discussions", to: Routes.STUDENT_MY_DISCUSSIONS, id: EnumSidemenuIcon.MY_DISCUSSIONS },
  { icon: <NotificationsIcon />, label: "Notifications", to: Routes.STUDENT_NOTIFICATIONS, id: EnumSidemenuIcon.NOTIFICATIONS },
];
export const studentItems3 = [
  { icon: <SettingsIcon />, label: "My Settings", to: Routes.STUDENT_SETTINGS, id: EnumSidemenuIcon.SETTINGS },
  { icon: <HelpIcon />, label: "How it Works", to: Routes.STUDENT_HOW_IT_WORKS, id: EnumSidemenuIcon.HELP },
  { icon: <ExitToAppIcon />, label: "Log out", to: Routes.ABOUT, id: EnumSidemenuIcon.LOGOUT },
];

export const studentItems4 = [{ icon: <Icon path={mdiMessageCheckOutline} size={1} />, label: "Feedback", to: Routes.FEEDBACK, id: EnumSidemenuIcon.FEEDBACK }];
