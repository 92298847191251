import { ACCESS_TOKEN, expressionOrder } from "../constants";
import EnumExpressionsNum from "../utils/enum/EnumExpressionsNum";
//import mapKeys from 'lodash/mapKeys';

export function getAccessToken() {
  return JSON.parse(localStorage.getItem(ACCESS_TOKEN));
}
export function setAccessToken(token) {
  // TODO: Don't store in local storage, use HTTPOnly instead
  localStorage.setItem(ACCESS_TOKEN, token);
}

export function mapExpressions(expressionResponse) {
  if (expressionResponse?.length !== 10) {
    return [];
  }
  const expressionResponseTemp = {
    [expressionResponse[0]]: expressionResponse[1],
    [expressionResponse[2]]: expressionResponse[3],
    [expressionResponse[4]]: expressionResponse[5],
    [expressionResponse[6]]: expressionResponse[7],
    [expressionResponse[8]]: expressionResponse[9],
  };

  const totalExpressions = Object.values(expressionResponseTemp).reduce((acc, currentValue) => acc + parseInt(currentValue), 0);

  //const mappedData = mapKeys(expressionResponse, 'expressionTag');

  // Changed from EXPRESSION to expressions
  const mappedExpressions = Object.entries(EnumExpressionsNum).map(([name, expressionTag]) => {
    //const count = +mappedData[expressionTag]?.count || 0;
    //const isVote = mappedData[expressionTag]?.isVote || false;
    const count = expressionResponseTemp[expressionTag.toString()] || 0;

    return {
      name,
      expressionTag,
      count,
      //isVote: Boolean(isVote),
      percentCount: totalExpressions > 0 ? Number(Number((count / totalExpressions) * 100).toFixed(0)) : 0,
    };
  });

  const keyedExpressions = mappedExpressions.reduce(
    (acc, { name, expressionTag, count, percentCount }) => ({
      ...acc,
      [name]: { expressionTag, count, percentCount, totalExpressions },
    }),
    {}
  );

  return Object.entries(keyedExpressions).sort(([a], [b]) => expressionOrder[a] - expressionOrder[b]);
}
