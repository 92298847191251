import { mdiChevronLeft } from "@mdi/js";
import { AppBar, Box, Button, Dialog, Grid, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import DialogYesNoDataType from "../../../../interfaces/dialog/DialogYesNoDataType";
import Colors from "../../../../utils/Colors";
import LectureNumParams from "../../../../utils/LectureNumParams";
import MessageType from "../../../../utils/MessageType";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT, extractVideoIdFromUrl, TIME_FORMAT_2 } from "../../../../utils/Utils";
import { BackButton } from "../../../shared/components/styledComponents";
import DialogYesNo from "../../../shared/dialog-yes-no/DialogYesNo";
import FileAdd from "../../../shared/icons/FileAdd";
import OutlinedInputCustom from "../../../shared/outlined-input-custom/OutlinedInputCustom";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import SwitchIOS from "../../../shared/switch-ios/SwitchIOS";
import PreviewPresentation from "../preview-presentation/PreviewPresentation";
import { fieldMessage, fieldType } from "./config";

interface ConfirmFunctionAndParameters {
  function: Function;
  parameters?: Array<any>;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface LectureType {
  name?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  lectureNum?: number;
  allowQuestionsDuringLecture?: boolean;
  allowDiscussionsDuringLecture?: boolean;
  urlYoutube?: string;
}
interface LectureErrorType {
  name: boolean;
  date: boolean;
  startTime: boolean;
  endTime: boolean;
  urlYoutube?: boolean;
}

const AddLecture = () => {
  const baseClass = "create-lecture";
  const lectureNum = LectureNumParams();
  const history = useHistory();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [isPreviewVideo, setIsPreviewVideo] = useState<boolean>(false);
  const [presentation, setPresentation] = useState<any>();
  const [file, setFile] = useState<any>();
  const { isTeacherAdmin, showMessage, selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const [showDialog, setShowDialog] = useState<DialogYesNoDataType>({ open: false, title: "" });
  const [confirmActionFunc, setConfirmActionFunc] = useState<ConfirmFunctionAndParameters>();
  const [lecture, setLecture] = useState<LectureType>();
  const [lectureError, setLectureError] = useState<LectureErrorType>({ name: false, date: false, startTime: false, endTime: false });

  const fetchData = async () => {
    if (lectureNum) {
      const lectureTemp = await Lecture.getLecture(courseId, lectureNum);
      setLecture({
        date: lectureTemp ? moment(lectureTemp?.startTime).format(DATE_FORMAT) : moment(new Date()).format(DATE_FORMAT),
        startTime: lectureTemp ? moment(lectureTemp?.startTime).format(TIME_FORMAT_2) : moment(new Date()).format(TIME_FORMAT_2),
        endTime: lectureTemp ? moment(lectureTemp?.endTime).format(TIME_FORMAT_2) : moment(new Date()).add(1, "hour").format(TIME_FORMAT_2),
        name: lectureTemp?.name ?? "",
        lectureNum: lectureTemp?.lectureNum,
        allowQuestionsDuringLecture: lectureTemp?.allowQuestionsDuringLecture,
        allowDiscussionsDuringLecture: lectureTemp?.allowDiscussionsDuringLecture,
        urlYoutube: lectureTemp?.urlYoutube,
      });
      Lecture.getMetaPresentation(courseId, lectureNum)
        .then((res) => {
          setPresentation(res?.data);
          setFile(res?.data);
        })
        .catch((e) => {
          console.warn(e.response?.data);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  const handleFileChange = async (e: any) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      setFile(file);
    }
  };

  const uploadPresentation = async (courseId: number, lectureNum: number, file: any) => {
    await Lecture.uploadPresentation(courseId, lectureNum, file)
      .then((response: any) => setPresentation({ ...presentation, createdAt: response.data.data.createdAt, fileName: response.data.data.originalname }))
      .catch((error: any) => console.log(error));
  };

  const handleOnSubmit = (lectureDate: string, startTime: string, endTime: string, lectureTitle: string, allowQA?: boolean, allowDisc?: boolean) => {
    if (onBlurCheck()) return;
    const start = lectureDate + "T" + startTime;
    const end = lectureDate + "T" + endTime;
    const parseStart = new Date(start).toUTCString();
    const parseEnd = new Date(end).toUTCString();
    const urlYoutube = isTeacherAdmin ? lecture?.urlYoutube : null;

    if (lectureNum) {
      Lecture.updateLecture(courseId, lecture?.lectureNum, lectureTitle, parseStart, parseEnd, allowQA, allowDisc, urlYoutube)
        .then(async ({ data }: any) => {
          await uploadPresentation(courseId, lecture?.lectureNum!, file);
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
            console.error(data);
          }
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    } else {
      Lecture.createLecture(courseId, lectureTitle, parseStart, parseEnd, allowQA, allowDisc, urlYoutube)
        .then(async ({ data }: any) => {
          await uploadPresentation(courseId, data?.data?.lectureNum, file);
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
            console.error(data);
          }
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
  };

  const deleteLecture = (lectureNum: number) => {
    Lecture.deleteLecture(courseId, lectureNum)
      .then(() => {
        showMessage("Lecture deleted", MessageType.SUCCESS);
        history.push(Routes.TEACHER_MY_LECTURES);
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteLecture = () => {
    setShowDialog({ open: true, title: "Are you sure that you want to delete this lecture?", content: <Typography>{lecture?.name ?? lectureNum}</Typography> });
    setConfirmActionFunc({ function: deleteLecture, parameters: [lectureNum] });
  };

  const deletePresentation = () => {
    Lecture.deletePresentation(courseId, lectureNum)
      .then((response: any) => {
        showMessage("Presentation deleted", MessageType.SUCCESS);
        setPresentation(null);
        setFile(null);
      })
      .catch((error: any) => console.error(error));
  };

  const handleDeletePresentation = () => {
    setConfirmActionFunc({ function: deletePresentation });
    setShowDialog({ open: true, title: "Are you sure you want to delete this presentation?", content: <Typography>{presentation?.fileName}</Typography> });
  };

  const openPreview = () => setIsPreview(true);
  const closePreview = () => setIsPreview(false);
  const openPreviewVideo = () => setIsPreviewVideo(true);
  const closePreviewVideo = () => setIsPreviewVideo(false);

  const onBlurCheck = (ft?: string) => {
    if (ft) {
      ft === fieldType.NAME && setLectureError({ ...lectureError, name: !lecture?.name });
      ft === fieldType.DATE && setLectureError({ ...lectureError, date: !lecture?.date });
      ft === fieldType.START && setLectureError({ ...lectureError, startTime: !lecture?.startTime });
      ft === fieldType.END && setLectureError({ ...lectureError, endTime: !lecture?.endTime });
    } else {
      setLectureError({
        name: !lecture?.name,
        date: !lecture?.date,
        startTime: !lecture?.startTime,
        endTime: !lecture?.endTime,
      });
    }
    return !lecture?.name || !lecture?.date || !lecture?.startTime || !lecture?.endTime;
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item>
        <div className={`${baseClass}__heading`}>{lectureNum ? "Edit lecture" : "Add lecture"}</div>
      </Grid>
      <Grid item xs={12} xl={11} lg={11} sm={9} md={9} mt={7}>
        <div className={baseClass}>
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12} xl={5} lg={5} sm={12} md={12} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Lecture title:</div>
              <OutlinedInputCustom
                id="lectureTitle"
                placeholder="Write the title of the lecture here"
                name="lectureTitle"
                value={lecture?.name}
                onChange={(e: any) => {
                  setLecture({ ...lecture, name: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.name}
                onBlur={() => onBlurCheck(fieldType.NAME)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              <div className="error-text">{lectureError?.name && <label>{fieldMessage.NAME}</label>}</div>
            </Grid>
            <Grid container item xs={12} xl={2.5} lg={2.5} sm={12} md={2.5} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Lecture Date:</div>
              <OutlinedInputCustom
                id="lectureDate"
                type="date"
                placeholder="Pick a Start Date"
                name="lectureDate"
                aria-describedby="outlined-pass-helper-text"
                value={lecture?.date}
                onChange={(e: any) => {
                  setLecture({ ...lecture, date: e.target.value ?? "" });
                }}
                onBlur={() => onBlurCheck(fieldType.DATE)}
                fullWidth
                className={`${baseClass}__text-field`}
                error={lectureError?.date}
              />
              <div className="error-text">{lectureError.date && <label>{fieldMessage.DATE}</label>}</div>
            </Grid>
            <Grid container item xs={12} xl={2.25} lg={2.25} sm={12} md={4.25} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Start time:</div>
              <OutlinedInputCustom
                id="startTime"
                type="time"
                placeholder="Pick a Start Date"
                name="startTime"
                value={lecture?.startTime}
                onChange={(e: any) => {
                  setLecture({ ...lecture, startTime: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.startTime}
                onBlur={() => onBlurCheck(fieldType.START)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              <div className="error-text">{lectureError?.startTime && <label>{fieldMessage.START}</label>}</div>
            </Grid>
            <Grid container item xs={12} xl={2.25} lg={2.25} sm={12} md={4.25} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>End time:</div>
              <OutlinedInputCustom
                id="endTime"
                type="time"
                placeholder="Pick a Start Date"
                name="endTime"
                value={lecture?.endTime}
                onChange={(e: any) => {
                  setLecture({ ...lecture, endTime: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.endTime}
                onBlur={() => onBlurCheck(fieldType.END)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              <div className="error-text">{lectureError?.endTime && <label>{fieldMessage.END}</label>}</div>
            </Grid>
          </Grid>
          {isTeacherAdmin && (
            <>
              <Grid spacing={2} container item xs={12} flexDirection="row" mb="37px" alignItems="center">
                <Grid container item xs={12} xl={10} lg={10} justifyContent="center" alignItems="center">
                  <div className={`${baseClass}__title`}>YouTube video url:</div>
                  <OutlinedInputCustom
                    id="urlYoutube"
                    placeholder="Enter a youtube video url"
                    name="urlYoutube"
                    aria-describedby="outlined-pass-helper-text"
                    value={lecture?.urlYoutube}
                    onChange={(e: any) => setLecture({ ...lecture, urlYoutube: e.target.value ?? "" })}
                    fullWidth
                    className={`${baseClass}__text-field`}
                    error={lectureError?.urlYoutube}
                  />
                </Grid>
                {lecture?.urlYoutube && extractVideoIdFromUrl(lecture.urlYoutube) && (
                  <Grid container item xs={12} xl={2} lg={2} justifyContent="center" alignItems="center" alignSelf="end">
                    <Button variant="contained" className="v-btn ac-color-white btn-preview-presentation" onClick={openPreviewVideo}>
                      Preview video
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} />
          {presentation ? (
            <>
              <Grid spacing={2} container item xs={12} flexDirection="row" mb="37px" alignItems="center">
                <Grid container item xs={12} xl={8} lg={8} justifyContent="center" alignItems="center">
                  <div className="presentation-info-text-big">Presentation Uploaded ✓</div>
                  <Button variant="contained" className="v-btn ac-color-white btn-presentation-uploaded " component="label">
                    <input id="file" type="file" name="file" accept="application/pdf" hidden onChange={handleFileChange} />
                    <div className={`${baseClass}__black-title`}>{file?.fileName ?? "Upload presentation"}</div>
                    <FileAdd />
                  </Button>
                  <div className="presentation-info-text">UPLOADED: {moment(presentation?.createdAt).format("hh:mm a DD/MM/YYYY")}</div>
                </Grid>

                <Grid container item xs={12} xl={2} lg={2} justifyContent="center" alignItems="center">
                  <Button variant="contained" className="v-btn ac-color-white btn-preview-presentation" onClick={openPreview}>
                    Preview
                  </Button>
                </Grid>
                <Grid container item xs={12} xl={2} lg={2} justifyContent="center" alignItems="center">
                  <Button variant="contained" className="v-btn ac-color-white btn-delete-presentation" onClick={handleDeletePresentation}>
                    <p>Delete</p>
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container item xs={12} justifyContent="center" alignItems="center" style={{ marginBottom: "37px" }}>
              <Button variant="contained" className="v-btn ac-color-white btn-upload-presentation" component="label">
                <input id="file" type="file" name="file" accept="application/pdf" hidden onChange={handleFileChange} />
                <div className={`${baseClass}__black-title`}>{`${file ? file?.name : "Upload presentation"}`}</div>
                <FileAdd />
              </Button>
            </Grid>
          )}
          <Grid container item justifyContent="center" alignItems="center" style={{ marginBottom: "37px" }}>
            <Grid item xs={6}>
              <Typography className={`${baseClass}__title`}>Allow Q&A</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="subtitle2">No</Typography>
                <SwitchIOS
                  value={lecture?.allowQuestionsDuringLecture}
                  onChange={(e: any, checked: boolean) => setLecture({ ...lecture, allowQuestionsDuringLecture: checked })}
                  activeColor={Colors.GREEN_SOLID}
                  deactiveColor={Colors.RED_SOLID}
                />
                <Typography variant="subtitle2">Yes</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${baseClass}__title`}>Allow discussions</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="subtitle2">No</Typography>
                <SwitchIOS
                  value={lecture?.allowDiscussionsDuringLecture}
                  onChange={(e: any, checked: boolean) => setLecture({ ...lecture, allowDiscussionsDuringLecture: checked })}
                  activeColor={Colors.GREEN_SOLID}
                  deactiveColor={Colors.RED_SOLID}
                />
                <Typography variant="subtitle2">Yes</Typography>
              </Box>
            </Grid>
          </Grid>
          <Button
            onClick={() => handleOnSubmit(lecture?.date!, lecture?.startTime!, lecture?.endTime!, lecture?.name!, lecture?.allowQuestionsDuringLecture, lecture?.allowDiscussionsDuringLecture)}
            variant="contained"
            className="v-btn ac-color-white btn-send"
          >
            {`${lectureNum ? "Save Lecture" : "Save"}`}
          </Button>
          {lecture?.lectureNum && (
            <Button variant="contained" className="v-btn ac-color-white btn-delete" onClick={handleDeleteLecture}>
              Delete Lecture
            </Button>
          )}
        </div>
      </Grid>
      <Dialog fullScreen open={isPreview} onClose={closePreview} TransitionComponent={Transition}>
        <PreviewPresentation handleClose={closePreview} lectureNum={lectureNum} fileName={presentation?.fileName} />
      </Dialog>
      <Dialog maxWidth={"md"} open={isPreviewVideo} onClose={closePreviewVideo} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", backgroundColor: "black" }}>
          <Toolbar>
            <BackButton onClick={closePreviewVideo} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
              Close preview
            </BackButton>
          </Toolbar>
        </AppBar>
        {lecture?.urlYoutube && <YouTube videoId={extractVideoIdFromUrl(lecture.urlYoutube)} onError={(err) => console.log(err)} />}
      </Dialog>
    </Grid>
  );
};

export default AddLecture;
