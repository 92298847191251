import { useEffect, useState } from "react";

interface MyDiscussionTabServiceType {
  showQuestions: number[];
  showQuestionAction: (id: number) => void;
  toogleAllAnswers: () => void;
}

interface MyDiscussionTabServiceProps {
  answersIds: number[];
  showAllAnswers: boolean;
}

export default function MyDiscussionTabService({ answersIds, showAllAnswers: isShowAll }: MyDiscussionTabServiceProps): MyDiscussionTabServiceType {
  const [showQuestions, setShowQuestions] = useState<number[]>([]);
  const [showAllAnswers, setAllShowAnswers] = useState<boolean>(false);

  const showQuestionAction = (id: number) => {
    if (showQuestions.includes(id)) {
      setShowQuestions(showQuestions.filter((key: number) => key !== id));
    } else {
      setShowQuestions([id]);
    }
  };

  const toogleAllAnswers = () => setAllShowAnswers(!showAllAnswers);

  useEffect(() => {
    if (showQuestions.length > 0 && answersIds.length === showQuestions.length) {
      setShowQuestions([]);
    } else {
      setShowQuestions(answersIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllAnswers]);

  useEffect(() => {
    setShowQuestions(isShowAll ? answersIds : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAll]);

  return {
    showQuestions,
    showQuestionAction,
    toogleAllAnswers,
  };
}
export type { MyDiscussionTabServiceType };
