import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, Divider, Grid, IconButton, Typography } from "@mui/material";
import Colors from "../../../utils/Colors";
import EditIcon from "../icons/EditIcon";
import TooltipStyled from "../tooltip/TooltipStyled";

interface GridCardType {
  position?: number;
  title: string;
  subtitle?: string;
  showDots?: boolean;
  showEdit?: boolean;
  showPointer?: boolean;
  headerAction?: (e: any) => void;
  cardAction: () => void;
  name: string;
  active: boolean;
  color?: string;
  isLive?: boolean;
  isFuture?: boolean;
}

const TEXT_LENGTH = 35;

const GridCard = (props: GridCardType) => {
  const { position, title, subtitle, showDots = false, showEdit = false, showPointer = true, headerAction, cardAction, name, active, color, isLive, isFuture } = props;

  const tooltipTextSmall = () => `${name.substring(0, TEXT_LENGTH)}...`;

  const isTooltipTextSmall = name?.length < TEXT_LENGTH;

  return (
    <Card
      className={"ac-card" + (isLive ? "-active" : "") + (active ? " ac-card-active" : "") + (isFuture ? " ac-card-future" : "")}
      sx={{ backgroundColor: `${color} !important`, cursor: showPointer ? "pointer" : "auto" }}
      onClick={cardAction}
    >
      <div className={isLive ? "ac-card-header-live" : ""}>
        <Grid sx={{ height: 28 }} container justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={2} className={"ac-card-header-num" + (isLive ? "-live" : "") + (isFuture && "-future")}>
            <Typography color={isFuture ? Colors.NAVY_SOLID : isLive ? Colors.NAVY_SOLID : "white"} variant="caption">{`#${position}`}</Typography>
          </Grid>
          {isLive && (
            <Grid item xs={4} className="ac-card-header-next">
              Live
            </Grid>
          )}
          <Grid item xs={2}>
            {showDots && headerAction && (
              <IconButton aria-label="edit" id={`menu-${name}`} onClick={headerAction}>
                <MoreVertIcon className={"ac-card-header-icon" + (isLive ? "-live" : "") + (isFuture ? "-future" : "")} />
              </IconButton>
            )}
            {showEdit && headerAction && (
              <IconButton aria-label="edit" id={`menu-${name}`} onClick={headerAction}>
                <EditIcon className="ac-card-header-icon" />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="flex-start" className="ac-card-header-title-container">
          <Grid item>
            <Typography variant="caption" className={"ac-card-header-title" + (isLive ? "-live" : "") + (isFuture ? "-future" : "")}>
              {title} <br />
            </Typography>
            <div className={"ac-card-header-subtitle" + (isLive ? "-live" : "") + (isFuture ? "-future" : "")}>{subtitle}</div>
          </Grid>
        </Grid>
      </div>
      <Divider className={"ac-card__divider" + (isFuture ? "-future" : "")} />
      <CardContent>
        <TooltipStyled tooltipText={isTooltipTextSmall ? "" : name}>
          <Typography color={isFuture ? Colors.BLUE_DIANNE : isLive ? Colors.YELLOW_REACT : "white"}>{isTooltipTextSmall ? name : tooltipTextSmall()}</Typography>
        </TooltipStyled>
      </CardContent>
    </Card>
  );
};
export default GridCard;
