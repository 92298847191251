import SvgIcon from "@mui/material/SvgIcon";

const LectureIcon = ({ className = "", color = "white" }: any) => (
  <SvgIcon viewBox="0 0 25.28 21.38" className={className}>
    <title>Lecture</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill={color} d="M8.39,21.38H4.91A.88.88,0,0,1,4,20.5V9.26h.75V20.5a.13.13,0,0,0,.13.13H8.39a.13.13,0,0,0,.13-.13v-13h.75v13A.88.88,0,0,1,8.39,21.38Z" />
        <path
          fill={color}
          d="M15.43,8.63h-.09L8.6,7.89l.09-.74,6.73.73a.12.12,0,0,0,.11,0,.19.19,0,0,0,0-.1v-1a.13.13,0,0,0-.12-.13L7.17,5.41l.11-.74,8.27,1.22a.89.89,0,0,1,.77.88v1a.92.92,0,0,1-.29.66A.94.94,0,0,1,15.43,8.63Z"
        />
        <path
          fill={color}
          d="M24.31,12.67H12.53a1,1,0,0,1-1-1V9.6h.75v2.1a.22.22,0,0,0,.22.22H24.31a.22.22,0,0,0,.22-.22V3.34a.22.22,0,0,0-.22-.22H12.53a.22.22,0,0,0-.22.22V4h-.75V3.34a1,1,0,0,1,1-1H24.31a1,1,0,0,1,1,1V11.7A1,1,0,0,1,24.31,12.67Z"
        />
        <rect fill={color} x="15.97" y="6.09" width="6.32" height="0.75" transform="translate(-1 4.83) rotate(-14.02)" />
        <rect fill={color} x="6.27" y="15.83" width="0.75" height="5.18" />
        <path
          fill={color}
          d="M2.16,12.61H.79a.77.77,0,0,1-.62-.31A.8.8,0,0,1,0,11.63l0-.09L3,6.15a2.32,2.32,0,0,1,.94-1l2-.5.18.72-2,.51a3.63,3.63,0,0,0-.43.53L.77,11.81l1.39,0a.21.21,0,0,0,.18-.11l1.88-2.9.63.41L3,12.15A1,1,0,0,1,2.16,12.61Z"
        />
        <path fill={color} d="M6.65,5.49A2.75,2.75,0,1,1,9.4,2.75,2.75,2.75,0,0,1,6.65,5.49Zm0-4.74a2,2,0,1,0,2,2A2,2,0,0,0,6.65.75Z" />
        <rect fill={color} x="4.21" y="11.86" width="4.68" height="0.75" />
      </g>
    </g>
  </SvgIcon>
);
export default LectureIcon;
