import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Colors from "../../../utils/Colors";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.LIGHT_BLUE_HEADER,
    color: Colors.WHITE,
    maxWidth: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

export default function TooltipStyled({ children, tooltipText, placement = "top", describeChild, ...props }: any) {
  return tooltipText ? (
    <HtmlTooltip title={<>{tooltipText}</>} placement={placement} describeChild={describeChild} {...props}>
      {children}
    </HtmlTooltip>
  ) : (
    children
  );
}
