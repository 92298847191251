import { Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import CourseDto from "../../../../interfaces/course/CourseDto";
import Colors from "../../../../utils/Colors";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT_STYLE } from "../../../../utils/Utils";
import GridCard from "../../../shared/grid-card/GridCard";
import MyCoursesService, { MyCoursesServiceType } from "./MyCoursesService";

export default function MyCourses() {
  const { updateSelectedCourse, selectedCourse } = useAppContext();
  const history = useHistory();
  const { courses }: MyCoursesServiceType = MyCoursesService();

  return (
    <div className="my-courses">
      <div className="course-card__subtext">{courses?.length ? "Courses" : "There Are Currently No Courses."}</div>
      <Grid container justifyContent={{ xs: "center", sm: "center", md: "flex-start", lg: "flex-start", xl: "flex-start" }} alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
        {courses?.map(({ id, name, startDate, shortName }: CourseDto, index: number) => (
          <Grid key={id} item>
            <GridCard
              position={index + 1}
              title={startDate ? moment(startDate).format(DATE_FORMAT_STYLE) : ""}
              cardAction={() => {
                history.push(`${Routes.STUDENT_MY_LECTURES}`);
                updateSelectedCourse({ id, name, shortName, startDate });
              }}
              name={name}
              active={selectedCourse?.id === id}
              color={Colors.COURSE}
            />
          </Grid>
        ))}
        {courses && courses?.length / 2 !== 0 && <Grid container className="course-card__fake-card" item></Grid>}
      </Grid>
    </div>
  );
}
