import styled from "@emotion/styled";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AppBar, Badge, Box, Button, Grid, IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import Colors from "../../../utils/Colors";
import Routes from "../../../utils/Routes";
import UserRole from "../../../utils/UserRole";
import LogoIcon from "../icons/LogoIcon";
import UserInfo from "../user-info";
import { PAGE_HIDE_LIVE_LECTURE } from "./config";
import HeaderNotification from "./HeaderNotification";
import HeaderService from "./HeaderService";
import HeaderSidebar from "./HeaderSidebar";

const arrayNotForwardProp: Array<string> = ["colorNotif", "visible"];

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop: string) => !arrayNotForwardProp.includes(prop),
})<{ colorNotif?: string; visible?: boolean }>(({ colorNotif, visible }) => ({
  "& .MuiBadge-badge": {
    background: colorNotif,
    opacity: visible ? 1 : 0,
    right: 7,
    top: 10,
    border: `4px solid ${Colors.LIGHT_BLUE_HEADER}`,
    padding: "0 4px",
  },
}));

const Header = () => {
  const { selectedCourse } = useAppContext();
  const existCourse = Boolean(selectedCourse?.id);
  const { isMobile } = useQueryMedia();
  const { pathname } = useLocation();
  const { isStudent } = UserRole();
  const showLiveLecture = isStudent && !PAGE_HIDE_LIVE_LECTURE.includes(pathname) && !pathname.endsWith("/live");
  const { liveUnreadNotifications, openNotificationMenu, closeNotificationMenu, handleTabRoute, anchorEl, liveLecture, handleLiveLecture } = HeaderService();
  const routeToHome = isStudent ? Routes.STUDENT_MY_COURSE : Routes.TEACHER_MY_COURSE;

  return (
    <AppBar position="absolute" className="ac-nav">
      <Grid container item xs={12} alignItems="center">
        <Grid container item xs={4}>
          <Grid container item xs={2} sm={1} md={4.5} lg={4.5} xl={4.5}></Grid>
          <Grid container item xs={10} sm={11} md={7.5} lg={7.5} xl={7.5} alignItems="center">
            <Link to={routeToHome} className="item1 ac-nav__title">
              <LogoIcon className="ac-nav__logo" />
              {!isMobile && (
                <div className="ac-nav__title-text">
                  NON&nbsp;
                  <span className="ac-small">CLASS</span>
                </div>
              )}
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={3.5} justifyContent="center">
          {showLiveLecture && liveLecture && (
            <Button className="ac-nav__live-btn" onClick={handleLiveLecture}>
              Live
            </Button>
          )}
        </Grid>
        <Grid container item xs={4.5}>
          <>
            <Box sx={{ flexGrow: 1, marginRight: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {existCourse && isStudent && <UserInfo />}
              {existCourse && (
                <IconButton onClick={openNotificationMenu}>
                  <StyledBadge colorNotif={Colors.RED_SOLID} badgeContent=" " visible={Boolean(liveUnreadNotifications)}>
                    <NotificationsIcon sx={{ color: Colors.WHITE, fontSize: 34 }} />
                  </StyledBadge>
                </IconButton>
              )}
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <HeaderSidebar />
              </Box>
            </Box>
            {existCourse && <HeaderNotification anchorEl={anchorEl} closeMenu={closeNotificationMenu} liveUnreadNotifications={liveUnreadNotifications} handleTabRoute={handleTabRoute} />}
          </>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;
