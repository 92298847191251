import EnumSegment from "../../../../utils/enum/EnumSegment";

const segmentList = [
  { id: EnumSegment.MAX, label: `${EnumSegment.MAX} segments`},
  { id: EnumSegment.MIDDLE, label: `${EnumSegment.MIDDLE} segments`},
  { id: EnumSegment.MIN, label: `${EnumSegment.MIN} segments`},
];

export { segmentList };

