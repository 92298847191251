import { useAppContext } from "../../../../../context/AuthContext";
import { Lecture } from "../../../../../controller";

interface ClassPermissionServiceType {
  onChangeSwitch: any;
  ALLOW_QUESTIONS: string;
  ALLOW_DISCUSSIONS: string;
}

const ALLOW_QUESTIONS = "allowQuestions";
const ALLOW_DISCUSSIONS = "allowDiscussions";

export default function ClassPermissionService({ liveLecture, setLiveLecture }: any): ClassPermissionServiceType {
  const { selectedCourse } = useAppContext();

  const onChangeSwitch = (e: any, checked: boolean) => {
    const name = e.target.name;
    const isAllowQuestions = name === ALLOW_QUESTIONS;
    const isAllowDiscussions = name === ALLOW_DISCUSSIONS;
    const allowQuestions = isAllowQuestions ? checked : liveLecture?.allowQuestionsDuringLecture;
    const allowDuscussions = isAllowDiscussions ? checked : liveLecture?.allowDiscussionsDuringLecture;

    Lecture.updateLecture(selectedCourse.id, liveLecture?.lectureNum, liveLecture?.name, liveLecture?.startTime, liveLecture?.endTime, allowQuestions, allowDuscussions)
      .then(({ data }: any) => {
        isAllowQuestions && setLiveLecture({ ...liveLecture!, allowQuestionsDuringLecture: checked });
        isAllowDiscussions && setLiveLecture({ ...liveLecture!, allowDiscussionsDuringLecture: checked });
      })
      .catch((err) => console.error(err));
  };

  return { onChangeSwitch, ALLOW_QUESTIONS, ALLOW_DISCUSSIONS };
}
export type { ClassPermissionServiceType };
