import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, OutlinedInputProps, Select, styled } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import OptionItemDto from "../../../interfaces/option-item/OptionItemDto";
import Colors from "../../../utils/Colors";
import TooltipStyled from "../tooltip/TooltipStyled";

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

interface CustomSelectProps {
  title?: string;
  options: Array<OptionItemDto>;
  value: any;
  handleChange: any;
  componentColor?: string;
  setValue: any;
  showAll?: boolean;
  showNone?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
}
const arrayNotForwardProp: Array<string> = ["componentColor"];
interface CustomOutlinePropsNew extends OutlinedInputProps {
  componentColor?: string;
}

const CustomOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => !arrayNotForwardProp.includes(prop.toString()),
})<CustomOutlinePropsNew>(({ componentColor = Colors.FILTER_BLUE }) => ({
  ".MuiSelect-outlined": {
    color: componentColor,
    borderColor: componentColor,
    "&:focus": {
      color: componentColor,
      borderColor: componentColor,
    },
    "&:hover": {
      color: componentColor,
      borderColor: componentColor,
    },
  },
  ".MuiSelect-icon": {
    color: componentColor,
    borderColor: componentColor,
  },
  ".MuiSelect-iconOpen": {
    color: componentColor,
    borderColor: componentColor,
  },
  ".MuiSelect-multiple": {
    color: componentColor,
    borderColor: componentColor,
    "&:focus": {
      color: componentColor,
      borderColor: componentColor,
    },
    "&:hover": {
      color: componentColor,
      borderColor: componentColor,
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    color: componentColor,
    borderColor: componentColor,
    "& legend": {
      fontSize: "10px",
    },
    "&:focus": {
      color: componentColor,
      borderColor: componentColor,
    },
    "&:hover": {
      color: componentColor,
      borderColor: componentColor,
    },
  },
}));

function MultiselectCustom({ title, options, value, setValue, handleChange, showAll, showNone, disabled = false }: CustomSelectProps) {
  const [checkedValues, setCheckedValues] = useState<number[]>([]);

  useEffect(() => {
    setValue(checkedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues]);

  const getLabels = (array: Array<string>) => {
    if (array.length === 0) return title;
    if (array.length > 1) return `Selected (${array.length})`;

    return array.reduce((labels: any, item: string) => {
      let foundCourse = options.find((course: any) => course.id === item);
      if (foundCourse) {
        labels.push(foundCourse.label);
      }
      return labels.join(", ");
    }, []);
  };

  const headerRowButtonsStyle = { borderRadius: "5px", height: "20px", fontSize: "11px" };

  const createHeaderRowButtons = (
    <Grid container item justifyContent="space-around" columnSpacing={1} px={0.5} pb={0.5}>
      {showAll && (
        <Grid item xs={6}>
          <Button variant="contained" size="small" sx={headerRowButtonsStyle} onClick={() => setCheckedValues(options.map((course: any) => course.id))}>
            All
          </Button>
        </Grid>
      )}
      {showNone && (
        <Grid item xs={6}>
          <Button variant="contained" size="small" sx={headerRowButtonsStyle} onClick={() => setCheckedValues([])}>
            None
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const createListItemText = (label: string, description?: string) => (
    <TooltipStyled describeChild tooltipText={description}>
      <ListItemText primaryTypographyProps={{ fontSize: "0.75rem", padding: 0, maxWidth: "15rem", overflow: "hidden", textOverflow: "ellipsis", color: Colors.BLACK }} primary={label} />
    </TooltipStyled>
  );

  const mobMenuItemStyle = { height: `${ITEM_HEIGHT}px`, padding: 1 };
  return (
    <Grid container item xs={11}>
      <FormControl disabled={disabled} sx={{ width: "100%" }} size="small">
        <InputLabel
          id="demo-multiple-checkbox-label"
          sx={{
            color: Colors.FILTER_BLUE,
            fontSize: "0.8rem",
            "&.Mui-focused": {
              color: Colors.FILTER_BLUE,
            },
          }}
        >
          {title}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          multiple
          value={value}
          onChange={handleChange}
          input={<CustomOutlinedInput label={title} placeholder={title} />}
          renderValue={(selected: any) => getLabels(selected)}
          MenuProps={MenuProps}
          sx={{ fontSize: "10px" }}
        >
          {createHeaderRowButtons}
          {options.map(({ id, label, description }: any, i: number) => (
            <MenuItem key={i} value={id} sx={mobMenuItemStyle}>
              {createListItemText(label, description)}
              <Checkbox checked={value.indexOf(id) > -1} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default MultiselectCustom;
