import SvgIcon from "@mui/material/SvgIcon";
import Colors from "../../../utils/Colors";

const FlagFullIcon = ({ edgeLength = "12px", fillColor = Colors.RED_LIGHT }: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.55 18" style={{ stroke: fillColor, width: edgeLength, height: edgeLength }}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="_1280x740" data-name="1280x740">
        <path
          fill={fillColor}
          d="M11.79,6.13l2.66-4.25a.69.69,0,0,0,0-.68.67.67,0,0,0-.58-.34H1.33V.66A.67.67,0,0,0,0,.66V17.34a.67.67,0,0,0,1.33,0V11.39H13.89a.67.67,0,0,0,.58-.34.69.69,0,0,0,0-.68Z"
        />
      </g>
    </g>
  </SvgIcon>
);
export default FlagFullIcon;
