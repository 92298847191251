import { Grid } from "@mui/material";
import QuestionType from "../../../../interfaces/question/QuestionType";
import QuestionCard from "../../../shared/question-card/QuestionCard";

interface MyQuestionTabPropType {
  questions: QuestionType[];
  callbackRefresh: (questionId: number, propName: string, response: any) => void;
  selectedLectures: any;
  selectedCourses: any;
  tabValue: any;
}

const MyQuestionTab = ({ questions, callbackRefresh, selectedLectures, selectedCourses, tabValue }: MyQuestionTabPropType) => (
  <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
    {questions.map((question: QuestionType) => (
      <Grid key={question.id} item xs={12} sx={{ paddingLeft: "0px !important" }} pr={1}>
        <QuestionCard question={question} callbackRefresh={callbackRefresh} selectedLectures={selectedLectures} selectedCourses={selectedCourses} tabValue={tabValue} />
      </Grid>
    ))}
  </Grid>
);

export default MyQuestionTab;
