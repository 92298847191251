import SvgIcon from "@mui/material/SvgIcon";

const LogoIcon = ({ className = "" }: any) => (
  <SvgIcon width="104.57" height="100.037" viewBox="0 0 104.57 100.037" className={className}>
    <path
      id="Path_1"
      d="M2831.218 249.633l-38.418-75.128q-3.7-7-11.382-7a13.124 13.124 0 0 0-6.758 1.885 12.036 12.036 0 0 0-4.766 4.982l-38.413 75.263q-2.42 4.58-2.419 6.867 0 4.846 8.252 8.616 4.836 2.289 7.612 2.29a7.625 7.625 0 0 0 4.553-1.279 9.9 9.9 0 0 0 2.561-2.492q.78-1.211 2.205-3.9l4.23-8.315c.472-1.816 3.587-6.226 3.587-6.226a21.447 21.447 0 0 1 6.281-4.478 30.167 30.167 0 0 1 12.993-2.829v-.015a30.186 30.186 0 0 1 13.015 2.829 21.424 21.424 0 0 1 6.295 4.493s3.105 4.4 3.578 6.235l4.226 8.306q1.421 2.694 2.2 4.039a8.219 8.219 0 0 0 2.561 2.559 7.923 7.923 0 0 0 4.553 1.211q2.774 0 7.611-2.289 8.25-3.9 8.252-8.751.01-2.295-2.409-6.873zm-49.737-24.338a12.314 12.314 0 1 1 12.8-12.306 12.558 12.558 0 0 1-12.8 12.306z"
      data-name="Path 1"
      transform="translate(-2729.067 -167.503)"
    />
  </SvgIcon>
);
export default LogoIcon;
