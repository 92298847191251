import { mdiChevronLeft, mdiEye } from "@mdi/js";
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import LectureNumParams from "../../../../utils/LectureNumParams";
import Routes from "../../../../utils/Routes";
import { EXPRESSION_SLIDE_NUM, useEffectOnce } from "../../../../utils/Utils";
import { BackButton } from "../../../shared/components/styledComponents";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import TeacherBadgeNotification from "../../../shared/teacher-badge-notification/TeacherBadgeNotification";
import Remote from "../../student/live-lecture/tab-remote/Remote";
import ClassId from "../class-status/class-id/ClassId";
import ClassInfoShort from "../class-status/class-info/ClassInfoShort";
import ClassPermission from "../class-status/class-permission/ClassPermission";
import ClassWaitingRoom from "../class-status/class-waiting-room/ClassWaitingRoom";

export default function TeacherRemote() {
  const { selectedCourse } = useAppContext();
  const lectureNum = LectureNumParams();
  const history = useHistory();
  const [lecture, setLecture] = useState<LectureDto | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [showClassStatus, setShowClassStatus] = useState<boolean>(false);
  const [newConnectionWarning, setNewConnectionWarning] = useState<boolean>(false);

  useEffectOnce(() => {
    getLecture();
  });

  const getLecture = () => {
    setLoading(true);
    Lecture.getLecture(selectedCourse.id, lectureNum)
      .then((res) => setLecture(res))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const toggleShowClassStatus = () => {
    if (showClassStatus) {
      setNewConnectionWarning(true);
    } else {
      setShowClassStatus(true);
    }
  };

  const closeWarningStartLecture = () => {
    setNewConnectionWarning(false);
    setShowClassStatus(false);
  };

  const cardArray = [
    { title: "Lecture Code", content: <ClassId lectureNum={lectureNum} sideDisplay={true} /> },
    {
      title: "Waiting room",
      content: <ClassWaitingRoom lectureNum={lectureNum} sideDisplay={true} newConnectionWarning={newConnectionWarning} closeWarningStartLecture={closeWarningStartLecture} />,
    },
    { title: "Class info", content: <ClassInfoShort lectureNum={lectureNum} /> },
    { title: "Permissions", content: <ClassPermission liveLecture={lecture} setLiveLecture={setLecture} sideDisplay={true} /> },
  ];

  return (
    <>
      {loading ? (
        <Typography variant="h6">Lecture loading...</Typography>
      ) : !lecture ? (
        <Typography variant="h6">No lecture found</Typography>
      ) : (
        <>
          <Grid container item xs={showClassStatus ? 10 : 12}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <BackButton onClick={() => history.push(Routes.TEACHER_CLASS_STATUS)} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
                Back
              </BackButton>
            </Grid>
            <Grid container item xs={2} justifyContent="center">
              <TeacherBadgeNotification />
            </Grid>
            <Grid item xs={5} textAlign="right" pr={1}>
              {!showClassStatus && (
                <Button onClick={toggleShowClassStatus} variant="contained" className="presentation-class-status" endIcon={<SvgIconWrapper icon={mdiEye} />}>
                  Class status
                </Button>
              )}
            </Grid>
            <Grid item xs={12} padding={2}>
              <Remote lecture={lecture} currentSlide={EXPRESSION_SLIDE_NUM.WITHOUT_PDF} setCurrentSlide={() => {}} />
            </Grid>
          </Grid>
          <Grid container item xs={2} sx={{ display: showClassStatus ? "block" : "none" }}>
            <Grid item xs={12} alignSelf="flex-start">
              <Grid container direction="column" textAlign="center" justifyContent="center" alignItems="center" rowGap={1}>
                <Button onClick={toggleShowClassStatus} variant="contained" className="presentation-class-status" endIcon={<SvgIconWrapper icon={mdiEye} />}>
                  Class status
                </Button>
                {cardArray.map(({ content }: any, i: number) => (
                  <Grid key={i} item className="class-status-card" xs={12} style={{ width: "100%" }}>
                    <Grid container direction="column">
                      <Grid item padding={1}>
                        {content}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
