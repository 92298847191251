import { useAppContext } from "../../../../context/AuthContext";
import { User } from "../../../../controller";
import ChangePasswordType from "../../../../interfaces/change-password/ChangePasswordType";
import MessageType from "../../../../utils/MessageType";
import { INIT_VALUES } from "./config";

interface SettingsServiceType {
  handleOnSubmit: (val: ChangePasswordType, { resetForm }: any) => void;
}

export default function SettingsService(): SettingsServiceType {
  const { showMessage } = useAppContext();

  const handleOnSubmit = (values: ChangePasswordType, { resetForm }: any) => {
    User.changePassword(values)
      .then(({ data }: any) => {
        if (data?.message) {
          resetForm(INIT_VALUES);
          showMessage(data.message, MessageType.SUCCESS);
        }
      })
      .catch((err: any) => console.error(err));
  };

  return { handleOnSubmit };
}

export type { SettingsServiceType };
