import { http } from "../controller/http";

export const Feedback = {
  getAll() {
    return http.get(`/feedbacks`);
  },
  getById(feedbackId) {
    return http.get(`/feedback/${feedbackId}`);
  },
  createFeedback(body) {
    return http.post(`/feedback`, body);
  },
};
