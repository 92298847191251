const Colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED_LIGHT: "#ff4848",
  RED_SOLID: "#ec1c24", //css constant: $red-solid
  YELLOW_SOLID: "#f0df00", //css constant: $yellow-solid
  GRAY: "gray",
  FILTER_BLUE: "#14c5fb",
  ERROR_RED: "#D32F2F",
  AZURE_RADIANCE: "#0072EF",
  BLUE_DIANNE: "#1f3c52", //css constant: $blue-dianne
  MATISSE: "#1F6C8E",
  PICTON_BLUE: "#409AF2",
  CHATHAMS_BLUE: "#15516c",
  QUESTION: "#086ae9", //css constant: $question-color
  ANSWER: "#b4ddff", //css constant: $answer-color
  GREEN_LIGHT: "#8bc53f", //css constant: $green-light
  GREEN_SOLID: "#22a700", //css constant: $green-solid
  NAVY_SOLID: "#155977", //css constant: $navy-solid
  DARK_BLUE_HEADER: "#006eff", //css constant: $dark-blue-header
  LIGHT_BLUE_HEADER: "#24b3ff", //css constant: $light-blue-header
  YELLOW_REACT: "#ffd000", //css constant: $yellow-react
  PINK_REACT: "#A300BF", //css constant: $pink-react
  COURSE: "#3B9EBD",
  LECTURE_FUTURE: "#E6F4FF",
  LECTURE_LIVE: "#175163",
  DARK_BLUE: "#2C549E",
};

export default Colors;
