import { http } from "../controller/http";

export const Discussion = {
  async getById(courseId, lectureNum, questionId, answerId, discussionId) {
    // TODO@biki should be in data, not message
    const { data, message } = await http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/discussion/${discussionId}`);

    return data || message;
  },
  async getAll(courseId, lectureNum, questionId, answerId) {
    // TODO@biki should be in data, not message
    const { data, message } = await http.get(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/discussions`);
    return data || message;
  },

  startDiscussion(courseId, lectureNum, questionId, answerId, { message }) {
    return http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/discussion`, { message });
  },

  replyToDiscussion(courseId, lectureNum, questionId, answerId, discussionId, { message }) {
    return http.post(`/course/${courseId}/lecture/${lectureNum}/question/${questionId}/answer/${answerId}/discussion/${discussionId}`, { message });
  },
};
