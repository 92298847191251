import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Grid } from "@mui/material";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import DiscussionType from "../../../interfaces/discussion/DiscussionType";
import DiscussionCardService, { DiscussionCardServiceType } from "./DiscussionCardService";

interface DiscussionCardType {
  showStartNew: boolean;
  startNew?: boolean;
  handleStartNew?: () => void;
  discussions: DiscussionType[];
  callbackHandleOnClick: (val: number) => void;
  activeDiscussionId?: number | undefined;
}

const DiscussionCard = ({ showStartNew, startNew, handleStartNew, discussions, callbackHandleOnClick, activeDiscussionId }: DiscussionCardType) => {
  const { activeId, makeRoundData, handleOnClick, handleShowAll, showAll }: DiscussionCardServiceType = DiscussionCardService({ discussions, activeDiscussionId, callbackHandleOnClick });
  const { isMobile } = useQueryMedia();
  return (
    <div className="discussion-card">
      <Grid container item xs={12} columnSpacing={1} justifyContent="left">
        {showStartNew && !startNew && (
          <Grid container item xs={3.8} sm={3} md={2} lg={2} xl={2} mt={1}>
            <Button startIcon={<AddCircleOutlineIcon />} className="discussion-btn" onClick={() => handleStartNew?.()} variant="outlined" size="small" fullWidth>
              {`Start a ${isMobile ? "discuss" : "discussion"}`}
            </Button>
          </Grid>
        )}
        {makeRoundData(discussions).map(({ id, answerPosterPseudonym, discussionStarterPseudonym }: DiscussionType) => (
          <Grid key={id} container item xs={2.8} sm={2.8} md={1.9} lg={1.9} xl={1.9} mt={1}>
            <Button onClick={handleOnClick} className={`discussion-btn${id === activeId ? "-active" : ""}`} id={`${id}`} variant="outlined" size="small" fullWidth>
              {`${answerPosterPseudonym} - ${discussionStarterPseudonym}`}
            </Button>
          </Grid>
        ))}
        {!showAll && (
          <Grid container item xs={2.8} sm={2.8} md={1.9} lg={1.9} xl={1.9} mt={1}>
            <Button className="discussion-btn" onClick={handleShowAll} variant="outlined" size="small" fullWidth>
              {`Show all (${discussions.length})`}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DiscussionCard;
