const GreatIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.0333" height="50.0333" viewBox="0 0 63.233 63.233">
    <defs>
      <linearGradient id="linear-gradient-bravo" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#24b300" />
        <stop offset="1" stopColor="#b1ff55" />
      </linearGradient>
    </defs>
    <g id="Group_117" data-name="Group 117" transform="translate(-2746.195 -5449.439)">
      <g id="Group_114" data-name="Group 114">
        <path id="Path_61" d="M2809.428 5481.056a31.616 31.616 0 1 1-31.617-31.617 31.617 31.617 0 0 1 31.617 31.617z" style={{ fill: "url(#linear-gradient-bravo)" }} data-name="Path 61" />
      </g>
      <g id="Group_116" data-name="Group 116">
        <path id="Path_62" d="M2763.012 5490.209a.988.988 0 0 0-.819 1.366c1.232 3.385 5.347 11.907 15.554 11.907s14.41-8.581 15.681-12a.988.988 0 0 0-.822-1.373z" data-name="Path 62" />
        <g id="Group_115" data-name="Group 115">
          <path id="Path_63" d="M2783.836 5479.444s-.067-5.677 4.99-5.677 4.945 5.633 4.945 5.633" style={{ fill: "#231f20" }} data-name="Path 63" />
          <path id="Path_64" d="M2761.852 5479.444s-.066-5.677 4.99-5.677 4.946 5.633 4.946 5.633" style={{ fill: "#231f20" }} data-name="Path 64" />
        </g>
      </g>
    </g>
  </svg>
);
export default GreatIcon;
