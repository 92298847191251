import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { User } from "../../../../controller";
import Colors from "../../../../utils/Colors";
import EnumRole from "../../../../utils/enum/EnumRole";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import MessageType from "../../../../utils/MessageType";
import Routes from "../../../../utils/Routes";
import ButtonCustom from "../../../shared/button-custom/ButtonCustom";
import CheckboxCustom from "../../../shared/checkbox-custom/CheckboxCustom";

interface LocationType {
  state: { anonId: string; password: string; lectureNum: number };
}

export const DownloadPasswordMobile = () => {
  const { updateAccessToken, updateRole, showMessage } = useAppContext();
  const baseClass = "download-password";
  const history = useHistory();
  const location: LocationType = useLocation();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { anonId, password, lectureNum } = location?.state ?? {};

  const handleLogin = () => {
    if (!anonId || !password) {
      showMessage("You don't have correct credentials to proceed!", MessageType.ERROR);
    }
    User.userLogin(anonId, password)
      .then((res: any) => {
        const data = res?.data;
        if (data?.message) {
          updateAccessToken(data.data);
          updateRole(EnumRole.STUDENT);
          history.push({
            pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
            state: { lectureNum: lectureNum, goToTab: EnumTabs.REMOTE },
          });
        }
      })
      .catch((err: any) => console.log(err));
  };

  const handleCheckbox = () => setConfirm(!confirm);

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" className={`${baseClass}`}>
      <Grid item mb={5}>
        <Typography className={`${baseClass}_second-text`}>
          <div>Please WRITE DOWN your credentials or take a SCREENSHOT.</div>
          <div>When you are done, press the 'Continue' button.</div>
        </Typography>
      </Grid>
      <Grid item mb={2} sx={{ border: "2px solid white" }}>
        <Grid container justifyContent="center" spacing={1} p={1}>
          <Grid item xs={12}>
            Your credentials
          </Grid>
          <Grid item xs={12}>
            AnonID: <span style={{ color: Colors.YELLOW_REACT }}>{anonId}</span>
          </Grid>
          <Grid item xs={12}>
            Password: <span style={{ color: Colors.YELLOW_REACT }}>{password}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mb={2}>
        <CheckboxCustom label="I have read the text above and saved my credentials" checked={confirm} onChange={handleCheckbox} />
      </Grid>
      <Grid item>
        <ButtonCustom disabled={!confirm} gradient="green" label="Continue" textColor="black" fontWeight="800" onClick={handleLogin} />
      </Grid>
    </Grid>
  );
};
