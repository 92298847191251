import { Button } from "@mui/material";
import Colors from "../../../utils/Colors";
import AnswerIcon from "../icons/AnswerIcon";
import NotificationBadge from "../notification-badge/NotificationBadge";

interface AnswerButtonProps {
  onClick: () => void;
  disabled?: boolean;
  label?: string | number;
  smallButton?: boolean;
  height?: string;
  dark?: boolean;
  hasBorder?: boolean;
  hasNotification?: boolean;
}
const AnswerButton = ({ onClick, disabled, label, smallButton, height, dark, hasBorder, hasNotification }: AnswerButtonProps) => (
  <>
    {smallButton ? (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
        className={`button-reaction-button ${dark ? "button-reaction-button__answer_small_dark" : "button-reaction-button__answer_small_light"}`}
        size="small"
        startIcon={<AnswerIcon sideLength={17} fill={Colors.BLUE_DIANNE} />}
      >
        <label className="button-reaction-label" style={{ cursor: disabled ? "auto" : "pointer" }}> {label}</label>
      </Button>
    ) : (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
        className={`button-reaction-button ${dark ? "button-reaction-button__answer_large_dark" : "button-reaction-button__answer_large_light"}`}
        size="small"
        startIcon={<AnswerIcon sideLength={17} fill={Colors.BLUE_DIANNE} />}
        sx={{ minHeight: height ?? "26px", borderColor: hasBorder ? "#264c65 !important" : "inherit" }}
      >
        <NotificationBadge hasNotification={hasNotification} />
        {`Answers (${label})`}
      </Button>
    )}
  </>
);

export default AnswerButton;
