import SvgIcon from "@mui/material/SvgIcon";
import Colors from "../../../utils/Colors";

const FileAdd = ({ className = "" }: any) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 19C13 19.34 13.04 19.67 13.09 20H4C2.9 20 2 19.11 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C21.1 6 22 6.89 22 8V13.81C21.39 13.46 20.72 13.22 20 13.09V8H4V18H13.09C13.04 18.33 13 18.66 13 19ZM20 18V15H18V18H15V20H18V23H20V20H23V18H20Z"
      fill={Colors.BLUE_DIANNE}
    />
  </SvgIcon>
);
export default FileAdd;
