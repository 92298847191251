import SvgIcon from "@mui/material/SvgIcon";

const DiscussonIcon = ({ sideLength = "20px" }) => (
  <SvgIcon sx={{ width: `${sideLength} !important`, height: `${sideLength} !important`, verticalAlign: "-webkit-baseline-middle" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.48 7.46667H7.68C7.696 7.46667 7.72 7.45778 7.752 7.44H7.8V8.66667C7.8 9.02222 7.684 9.33333 7.452 9.6C7.22 9.86667 6.936 10 6.6 10H3.6L1.8 12V10H1.2C0.88 10 0.6 9.86667 0.36 9.6C0.12 9.33333 0 9.02222 0 8.66667V4.66667C0 4.29333 0.12 3.97778 0.36 3.72C0.6 3.46222 0.88 3.33333 1.2 3.33333H3.48V7.46667ZM10.8 0C11.136 0 11.42 0.128889 11.652 0.386667C11.884 0.644445 12 0.96 12 1.33333V5.33333C12 5.68889 11.884 6 11.652 6.26667C11.42 6.53333 11.136 6.66667 10.8 6.66667H10.2V8.66667L8.4 6.66667H4.2V1.33333C4.2 0.96 4.32 0.644445 4.56 0.386667C4.8 0.128889 5.08 0 5.4 0H10.8Z"
      fill="white"
    />
  </SvgIcon>
);
export default DiscussonIcon;
