import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer, IconButton, List, ListItem, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import { Lecture } from "../../../controller";
import MenuItemDto from "../../../interfaces/menu-item/MenuItem";
import Colors from "../../../utils/Colors";
import EnumSidemenuIcon from "../../../utils/enum/EnumSidemenuIcon";
import UserRole from "../../../utils/UserRole";
import { isLectureLive } from "../../../utils/Utils";
import { studentItems1, studentItems2, studentItems3, studentItems4 } from "../../layout/student/config";
import { teacherItems1, teacherItems2, teacherItems3, teacherItems4 } from "../../layout/teacher/config";
import AnswerIcon from "../icons/AnswerIcon";
import LiveIconOff from "../icons/live-icon/LiveIconOff";
import LiveIconOn from "../icons/live-icon/LiveIconOn";
import QuestionIcon from "../icons/QuestionIcon";

export default function HeaderSidebar() {
  const { accessToken, selectedCourse, deleteAccessToken } = useAppContext();
  const [open, setOpen] = useState<boolean>(false);
  const { isTeacher, isStudent } = UserRole();
  const [existLiveClass, setExistLiveClass] = useState<boolean>(false);

  useEffect(() => {
    if (isTeacher && accessToken && selectedCourse?.id) {
      Lecture.getCourseLectures(selectedCourse?.id).then(({ data }: any) => {
        if (data?.message) {
          setExistLiveClass(data.data.some((lecture: any) => isLectureLive(lecture)));
        } else {
          console.error(data);
        }
      });
    }
  }, [accessToken, selectedCourse?.id, isTeacher]);

  const horizontal_line: MenuItemDto = { id: Math.floor(Math.random() * 1000), icon: "", label: "horizontal_line", to: "" };

  const noCourseStudentItems1 = studentItems1.filter(({ id }: MenuItemDto) => !(id === EnumSidemenuIcon.LECTURES && !selectedCourse));

  const studentMenu = selectedCourse
    ? [...studentItems1, horizontal_line, ...studentItems2, horizontal_line, ...studentItems3, horizontal_line, ...studentItems4]
    : [...noCourseStudentItems1, horizontal_line, ...studentItems3, horizontal_line, ...studentItems4];
  const teacherMenu = selectedCourse
    ? [...teacherItems1, horizontal_line, ...teacherItems2, horizontal_line, ...teacherItems3, horizontal_line, ...teacherItems4]
    : [teacherItems1[0], horizontal_line, ...teacherItems3, horizontal_line, ...teacherItems4];
  const menuItems = isStudent ? studentMenu : isTeacher ? teacherMenu : [];

  const routeOnClick = (id: number) => {
    switch (id) {
      case EnumSidemenuIcon.LOGOUT:
        deleteAccessToken();
        break;
      default:
        break;
    }
  };

  const renderIcon = (id: number, icon: any, isActive: boolean) => {
    switch (id) {
      case EnumSidemenuIcon.LIVE_LECTURE:
        return existLiveClass ? <LiveIconOn fill={isActive ? Colors.BLUE_DIANNE : ""} /> : <LiveIconOff fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      case EnumSidemenuIcon.MY_QUESTIONS:
        return <QuestionIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      case EnumSidemenuIcon.MY_ANSWERS:
        return <AnswerIcon fill={isActive ? Colors.BLUE_DIANNE : ""} />;
      default:
        return icon;
    }
  };

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setOpen(isOpen);
  };

  const itemList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List className="header-drawer-list">
        {menuItems.map(({ icon, label, to, id }: any, i: number) => {
          let isActive = false;

          if (label === "horizontal_line") {
            return <div key={i} className="horizontal_line"></div>;
          } else {
            return (
              <ListItem key={i} disablePadding>
                <NavLink
                  to={to}
                  onClick={() => routeOnClick(id)}
                  isActive={(match, location) => {
                    isActive = match?.url === location.pathname;
                    return isActive;
                  }}
                  className={(isActive) => "menu_link" + (isActive ? "__selected" : "")}
                >
                  <ListItemButton>
                    <div className="menu_icon">{renderIcon(id, icon, isActive)}</div>
                    <div className="menu_label">{label}</div>
                  </ListItemButton>
                </NavLink>
              </ListItem>
            );
          }
        })}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer(true)} color="inherit">
        <MenuIcon sx={{ fontSize: 30 }} />
      </IconButton>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: Colors.BLUE_DIANNE,
            color: Colors.WHITE,
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
          },
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {itemList}
      </Drawer>
    </>
  );
}
