export const expressionOrder = {
  rew: 0,
  lost: 1,
  pause: 2,
  great: 3,
  ff: 4,
};

/*
export const EXPRESSION = {
  rew: 5,
  lost: 1,
  pause: 2,
  great: 3,
  ff: 4,
};
*/
