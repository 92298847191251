import SvgIcon from "@mui/material/SvgIcon";

const SendIcon = ({ className = "", fill = "white" }: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.03 18.13" className={className}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={fill}
          d="M.67,18.09l18.69-8a1.1,1.1,0,0,0,0-2L.67,0A.48.48,0,0,0,0,.48v6.1a.49.49,0,0,0,.42.48L12,8.59a.48.48,0,0,1,0,.95L.42,11.08a.49.49,0,0,0-.42.48v6.09A.48.48,0,0,0,.67,18.09Z"
        />
      </g>
    </g>
  </SvgIcon>
);
export default SendIcon;
