import styled from "@emotion/styled";
import { Button } from "@mui/material";
import Colors from "../../../utils/Colors";

export const AddButton = styled(Button)`
  background-color: ${Colors.MATISSE} !important;
  font-size: 12px !important;
  padding: 7px 20px !important;

  &:hover {
    opacity: 0.8;
  }
`;

export const BackButton = styled(Button)`
  color: ${Colors.YELLOW_REACT};
  font-size: 14px;
  &:hover {
    background-color: #534418;
  }
`;
