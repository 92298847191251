//NEW BACKEND

import { http } from "../controller/http";

const baseURL = (courseId, lectureNum) => `/course/${courseId}/lecture/${lectureNum}/slideInteractions`;

export const SlideInteractions = {
  existSlideInteraction(courseId, lectureNum) {
    return http.get(`${baseURL(courseId, lectureNum)}/exist`);
  },
  // Get interaction for all slides
  async getSlideInteractions(courseId, lectureNum) {
    return await http.get(baseURL(courseId, lectureNum));
  },

  async getSlideInteractionsBySlideNum(courseId, lectureNum, slideNum) {
    return await http.get(`${baseURL(courseId, lectureNum)}/${slideNum}`);
  },
};
