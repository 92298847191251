import { Typography } from "@mui/material";
import useExtendLecture from "../../../../../hooks/useExtendLecture";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import EnumLectureState from "../../../../../utils/enum/EnumLectureState";
import LectureNumParams from "../../../../../utils/LectureNumParams";
import { isLectureLive } from "../../../../../utils/Utils";
import DialogYesNo from "../../../../shared/dialog-yes-no/DialogYesNo";
import RemoteItem from "./RemoteItem";
import RemoteService, { RemoteServiceExport } from "./RemoteService";

interface RemoteProps {
  lecture: LectureDto;
  currentSlide: number;
  setCurrentSlide: (val: number) => void;
}

const msgCreatedLecture = "Please wait for the teacher to start the lecture";
const msgEndLecture = "The lecture is not live so you cannot provide feedback at the moment. Please choose the Q&A option if you would like to post a question/answer or enter discussions.";
export default function Remote({ lecture, currentSlide, setCurrentSlide }: RemoteProps) {
  const { expressionData, expressionState, handleExpressionClick, isTeacher }: RemoteServiceExport = RemoteService({ lecture, setCurrentSlide });
  const lectureNum = LectureNumParams();
  const { showDialog, setShowDialog, confirmActionFunc, rejectActionFunc } = useExtendLecture({ lectureNum });

  const displayText = (text: string) => (
    <Typography variant="h6" textAlign="center">
      {text}
    </Typography>
  );
  const createRemoteItem = (expressionTag: string, title: string) => (
    <RemoteItem expressionData={expressionData} onClick={handleExpressionClick} expressionState={expressionState} expressionTag={expressionTag} title={title} />
  );

  return (
    <div className="remote">
      {lecture.state === EnumLectureState.CREATED && displayText(msgCreatedLecture)}
      {(lecture.state === EnumLectureState.ENDED || !isLectureLive(lecture)) && displayText(msgEndLecture)}
      {lecture.state === EnumLectureState.STARTED && isLectureLive(lecture) && (
        <>
          <div className="remote__container">
            <div className="remote__column">
              <div className="remote-item"></div>
              {createRemoteItem(EnumExpressionsText.REW, "rew")}
              <div className="remote-item"></div>
            </div>
            <div className="remote__column" style={{ marginTop: "-1px", paddingBottom: "15px" }}>
              {createRemoteItem(EnumExpressionsText.LOST, "I'm lost")}
              {createRemoteItem(EnumExpressionsText.PAUSE, "Need to think")}
              {createRemoteItem(EnumExpressionsText.GREAT, "Great!")}
            </div>
            <div className="remote__column">
              <div className="remote-item"></div>
              {createRemoteItem(EnumExpressionsText.FF, "ff")}
              <div className="remote-item"></div>
            </div>
          </div>
          {currentSlide > 0 && !isTeacher && <div className="remote__title mt-2">Your feedback on slide {currentSlide}</div>}
        </>
      )}
      <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} rejectAction={rejectActionFunc} />
    </div>
  );
}
