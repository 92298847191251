import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import AnonCard from "../../../shared/anon-card/AnonCard";

export default function PickCard() {
  const baseClass = "pick-card";
  const location: any = useLocation();

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__subtext`}>Select a mask to get a pseudonym</div>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        width={{ xs: "330px", sm: "330px", md: "610px", lg: "610px", xl: "610px" }}
        height={{ xs: "400px", sm: "400px", md: "450px", lg: "450px", xl: "450px" }}
      >
        {Array.from(Array(12).keys()).map((e: any) => {
          return (
            <Grid key={e} item lg={3} xl={3} md={3} sm={4} xs={4} container justifyContent="center">
              <AnonCard classId={location?.state?.classId} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
