import { useEffect, useState } from "react";
import { remoteEvent } from "../constants";
import { useAppContext } from "../context/AuthContext";
import { Lecture, RemoteSocket } from "../controller";
import ConfirmFunctionAndParametersType from "../interfaces/dialog/ConfirmFunctionAndParametersType";
import DialogYesNoDataType from "../interfaces/dialog/DialogYesNoDataType";
import UserRole from "../utils/UserRole";

export default function useExtendLecture({ lectureNum }: { lectureNum: number }) {
  const { selectedCourse } = useAppContext();
  const { isTeacher } = UserRole();
  const [showDialog, setShowDialog] = useState<DialogYesNoDataType>({ open: false, title: "" });
  const [confirmActionFunc, setConfirmActionFunc] = useState<ConfirmFunctionAndParametersType>();
  const [rejectActionFunc, setRejectActionFunc] = useState<ConfirmFunctionAndParametersType>();

  useEffect(() => {
    if (!RemoteSocket.isConnected() || !isTeacher) return;

    RemoteSocket.emit(remoteEvent.send.EXTEND_CLASS);
    RemoteSocket.on(remoteEvent.recv.EXTEND_CLASS_RES, handleExtendClass);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RemoteSocket.isConnected()]);

  const handleExtendClass = ({ endInMins }: any) => {
    const text = endInMins === 0 ? "ended" : `about to end soon (${endInMins}min)`;
    setShowDialog({ open: true, title: `Your lecture is ${text}. Would you like to extend it for 10 minutes?` });
    setConfirmActionFunc({ function: extendClassTime });
    setRejectActionFunc({ function: stopInterval });
  };
  const stopInterval = () => {
    RemoteSocket.emit(remoteEvent.send.EXTEND_CLASS_STOP);
    RemoteSocket.off(remoteEvent.recv.EXTEND_CLASS_RES, handleExtendClass);
  };
  const extendClassTime = () => {
    Lecture.extendLecture(selectedCourse.id, lectureNum)
      .then(stopInterval)
      .catch((err: any) => console.log(err));
  };

  return { showDialog, setShowDialog, confirmActionFunc, rejectActionFunc };
}
