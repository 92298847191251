import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { remoteEvent } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AuthContext";
import { RemoteSocket } from "../../../../../controller";
import ExpressionDto from "../../../../../interfaces/expression/ExpressionDto";
import EnumExpressions from "../../../../../utils/enum/EnumExpressions";
import EnumExpressionsNum from "../../../../../utils/enum/EnumExpressionsNum";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import LectureNumParams from "../../../../../utils/LectureNumParams";
import MessageType from "../../../../../utils/MessageType";
import Routes from "../../../../../utils/Routes";
import UserRole from "../../../../../utils/UserRole";
import { EXPRESSION_SLIDE_NUM } from "../../../../../utils/Utils";
import { mapExpressions } from "../../../../../utilsVue";

interface RemoteServiceExport {
  expressionState: { [key: number]: boolean };
  expressionData: ExpressionDto[];
  handleExpressionClick: any;
  isTeacher: boolean;
}

export default function RemoteService({ lecture, setCurrentSlide }: any): RemoteServiceExport {
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse.id;
  const lectureNum = LectureNumParams();
  const { isTeacher } = UserRole();
  const history = useHistory();

  const initExpression = {
    [EnumExpressionsText.LOST]: false,
    [EnumExpressionsText.PAUSE]: false,
    [EnumExpressionsText.GREAT]: false,
    [EnumExpressionsText.REW]: false,
    [EnumExpressionsText.FF]: false,
  };
  const [expressionState, setExpressionState] = useState<{ [key: string]: boolean }>(initExpression);
  const [allowReactions, setAllowReactions] = useState<boolean>(false);
  const [expressionData, setExpressionData] = useState<ExpressionDto[]>([]);

  useEffect(() => {
    const handleCurrentSlide = (data: any) => setCurrentSlide(data);
    const handleExpressionData = (data: any) => setExpressionData(parseExpressionData(data));
    const handleAllowReactions = (allow: boolean) => setAllowReactions(allow);
    const handleExpressionDeactivated = () => resetRemoteState();
    const handleNoActiveExpression = () => resetRemoteState();
    const handleExpressionOverload = ({ err }: any) => showMessage(err.toString(), MessageType.ERROR);
    const handleIsAttending = ({ err }: any) => err && handleConnectError(err);

    RemoteSocket.connect({ query: { courseId, lectureNum } });
    RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, isTeacher);
    RemoteSocket.emit(remoteEvent.send.IS_ATTENDING, { courseId, lectureNum });
    RemoteSocket.emit(remoteEvent.send.GET_CURRENT_SLIDE);
    RemoteSocket.on(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
    RemoteSocket.on(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
    RemoteSocket.on(remoteEvent.recv.ACTIVE_EXPRESSION, handleActiveExpression);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_DEACTIVATED, handleExpressionDeactivated);
    RemoteSocket.on(remoteEvent.recv.SLIDE_CHANGED, handleSlideChange);
    RemoteSocket.on(remoteEvent.recv.ALLOW_REACTIONS, handleAllowReactions);
    RemoteSocket.on(remoteEvent.recv.NO_ACTIVE_EXPRESSION, handleNoActiveExpression);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
    RemoteSocket.on(remoteEvent.recv.CURRENT_SLIDE, handleCurrentSlide);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_OVERLOAD, handleExpressionOverload);
    RemoteSocket.on(remoteEvent.recv.IS_ATTENDING_RES, handleIsAttending);
    return () => {
      RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, !isTeacher);
      RemoteSocket.off(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
      RemoteSocket.off(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
      RemoteSocket.off(remoteEvent.recv.ACTIVE_EXPRESSION, handleActiveExpression);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_DEACTIVATED, handleExpressionDeactivated);
      RemoteSocket.off(remoteEvent.recv.SLIDE_CHANGED, handleSlideChange);
      RemoteSocket.off(remoteEvent.recv.ALLOW_REACTIONS, handleAllowReactions);
      RemoteSocket.off(remoteEvent.recv.NO_ACTIVE_EXPRESSION, handleNoActiveExpression);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
      RemoteSocket.off(remoteEvent.recv.CURRENT_SLIDE, handleCurrentSlide);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_OVERLOAD, handleExpressionOverload);
      RemoteSocket.off(remoteEvent.recv.IS_ATTENDING_RES, handleIsAttending);
      RemoteSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecture]);

  const parseExpressionData = (data: any) => {
    const arrayOfExp = mapExpressions(data);
    return arrayOfExp.map((item: any) => {
      return { id: item[0], ...item[1] };
    });
  };

  const handleExpressionClick = async (expStr: string) => {
    if (!isTeacher) {
      if (!allowReactions) {
        showMessage("Feedback not accepted. Teacher paused the lecture.", MessageType.ERROR);
      } else {
        const expNum = EnumExpressionsNum[expStr];
        RemoteSocket.emit(remoteEvent.send.PRESS_BUTTON, expNum);
      }
    }
  };

  const handleActiveExpression = ({ expressionTag, expiresAt, expressionDataAll }: any) => {
    setExpressionData(parseExpressionData(expressionDataAll));

    let expressionStateTemp: any = { ...initExpression };

    if (Object.keys(EnumExpressions).includes(expressionTag)) {
      const expressionText: string = EnumExpressions[expressionTag];
      expressionStateTemp[expressionText] = true;
    }
    setExpressionState(expressionStateTemp);
  };

  const handleAccessGranted = () => {
    console.log("GOT: Access Granted");
    RemoteSocket.emit(remoteEvent.send.GET_EXPRESSIONS);
    if (isTeacher) {
      RemoteSocket.emit(remoteEvent.send.SET_SLIDE, EXPRESSION_SLIDE_NUM.WITHOUT_PDF);
    } else {
      RemoteSocket.emit(remoteEvent.send.GET_ACTIVE_EXPRESSION);
    }
  };

  const handleConnectError = (err: any) => {
    const msg = err.toString().replace("Error: ", "");
    history.replace(Routes.OLD_OR_NEW_STUDENT);
    showMessage(msg, MessageType.ERROR);
  };

  const resetRemoteState = () => setExpressionState(initExpression);

  const handleSlideChange = (slideNum: any) => {
    resetRemoteState();
    setCurrentSlide(slideNum);
    RemoteSocket.emit(remoteEvent.send.GET_ACTIVE_EXPRESSION);
  };

  return { expressionState, expressionData, handleExpressionClick, isTeacher };
}
export type { RemoteServiceExport };
