import { http } from "../controller/http";

export const Notification = {
  getAll: () => http.get(`/notifications`), //TODOs not in use
  getAllLatest: () => http.get(`/notifications/latest`),
  getAllNotAcknowledged: () => http.get(`/notifications/notAcknowledged`),
  acknowledgeAllByEvents: (events) => http.put(`/notifications/events`, { events }),
  acknowledgeById: (notificationId) => http.put(`/notification/${notificationId}`),
  acknowledgeByEventsAndQuestionsId: (events, questionsId) => http.post("/notification/questions", { events: events, questionsId: questionsId }),
  acknowledgeByEventsAndAnswersId: (events, answersId) => http.post("/notification/answers", { events: events, answersId: answersId }), //TODOs not in use
  acknowledgeByEventsAndDiscussionsId: (events, discussionsId) => http.post("/notification/discussions", { events: events, discussionsId: discussionsId }),
};
