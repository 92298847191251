import { SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trigger } from "../../../../constants/events";
import { useAppContext } from "../../../../context/AuthContext";
import { Course, Lecture, Question } from "../../../../controller";
import CourseDto from "../../../../interfaces/course/CourseDto";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import OptionItemDto from "../../../../interfaces/option-item/OptionItemDto";
import QuestionType from "../../../../interfaces/question/QuestionType";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import Routes from "../../../../utils/Routes";
import UserRole from "../../../../utils/UserRole";
import { EVENT_UPDATE_USER_INFO, updateStateHelper, useEffectOnce } from "../../../../utils/Utils";
import { optionsSortBy } from "./config";

interface QuestionTabsType {
  my: QuestionType[];
  all: QuestionType[];
  flagged: QuestionType[];
}

interface MyQuestionsServiceType {
  tabValue: number;
  handleTabChange: (e: React.SyntheticEvent, newValue: number) => void;
  sortBy: number;
  handleSortBy: (e: SelectChangeEvent) => void;
  courses: OptionItemDto[];
  lectures: OptionItemDto[];
  handleCoursesChange: (e: any) => void;
  handleLecturesChange: (e: any) => void;
  selectedCourses: number[];
  setSelectedCourses: any;
  selectedLectures: number[];
  setSelectedLectures: any;
  callbackUpdateState: (questionId: number, propName: string, response: any) => void;
  showFilter: boolean;
  toggleFilter: any;
  question: string;
  questions: QuestionTabsType;
  handleSendQuestion: (teacherAsStudent: boolean) => void;
  disabledInput: boolean;
  setQuestion: any;
}

const initQuestions = { my: [], all: [], flagged: [] };
export default function MyQuestionsService(): MyQuestionsServiceType {
  const { selectedCourse: selectedCourseGlobal } = useAppContext();
  const location: any = useLocation();
  const history = useHistory();
  const { isTeacher } = UserRole();
  const { initLectureId, goToTab, locationState } = location?.state ?? {};

  const [tabValue, setTabValue] = useState<number>(goToTab ?? EnumTabs.Q_MY);
  const [courses, setCourses] = useState<OptionItemDto[]>([]);
  const [lectures, setLectures] = useState<any>([]);
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuestionTabsType>(initQuestions);
  const [question, setQuestion] = useState<string>("");

  const [sortBy, setSortBy] = useState<number>(optionsSortBy[0].id);

  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [selectedLectures, setSelectedLectures] = useState<number[]>([]);
  const [initLectureSet, setInitLectureSet] = useState<boolean>(false);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const toggleFilter = () => setShowFilter(!showFilter);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    history.replace({
      pathname: isTeacher ? Routes.TEACHER_MY_QUESTIONS : Routes.STUDENT_MY_QUESTIONS,
      state: { goToTab: newValue },
    });

    setTabValue(newValue);
  };
  const handleSortBy = (e: SelectChangeEvent) => setSortBy(+e.target.value);
  const handleCoursesChange = (e: any) => setSelectedCourses(e.target.value);
  const handleLecturesChange = (e: any) => setSelectedLectures(e.target.value);

  useEffectOnce(() => {
    getAllCourses();
  });

  const getAllCourses = () => {
    Course.getAllCourses()
      .then(({ data }: any) => {
        setSelectedCourses([selectedCourseGlobal.id]);
        setCourses(data.data.map(({ id, name }: CourseDto) => ({ id: id, label: name })));
      })
      .catch((err) => console.error(err.data));
  };

  const handleSendQuestion = (teacherAsStudent: boolean) => {
    const lectureNum = lectures.find((lecture: any) => lecture.id === selectedLectures[0])?.lectureNum;
    const teacherQuestion = isTeacher && !teacherAsStudent;
    Question.postQuestion(selectedCourses[0], lectureNum, question, -1, teacherQuestion, isTeacher)
      .then((res: any) => {
        getQuestionsByLecturesId();
        setQuestion("");
      })
      .catch((err: any) => console.log(err));
  };

  const getLecturesByCourses = useCallback(() => {
    Lecture.getLecturesByCoursesId(selectedCourses)
      .then(({ data }: any) => {
        if (locationState?.prevSelectedLectures.length > 0) {
          setSelectedLectures(locationState?.prevSelectedLectures);
        } else {
          setSelectedLectures(data.data.map(({ id }: LectureDto) => id));
        }
        setLectures(
          data.data.map(({ name, courseId, ...other }: LectureDto) => ({
            label: name,
            description: `${courses.find((course: any) => course.id === courseId)?.label} - ${name}`,
            ...other,
          }))
        );
      })
      .catch((err) => console.error(err.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  const getQuestionsByLecturesId = useCallback(() => {
    Question.getQuestionsByLecturesId(selectedLectures)
      .then(({ data }) => {
        const res = data.data;
        const notFlagged = res.filter((q: QuestionType) => !q.flagged);
        setQuestions({
          my: notFlagged.filter((q: QuestionType) => q.selfPosted),
          all: notFlagged,
          flagged: res.filter((q: QuestionType) => q.flagged),
        });
      })
      .catch((error) => console.log(error));
  }, [selectedLectures]);

  const callbackUpdateState = (questionId?: number, propName?: string, response?: any) => {
    if (questionId && propName && response) {
      const tabName = tabValue === EnumTabs.Q_MY ? "my" : tabValue === EnumTabs.Q_ALL ? "all" : "flagged";
      const tabQuestions: QuestionType[] = [...questions[tabName]];
      const index = tabQuestions.findIndex((q: QuestionType) => q.id === questionId);
      const questionToUpdate: QuestionType = { ...tabQuestions[index] };
      const questionUpdated = updateStateHelper(questionToUpdate, propName, response);
      tabQuestions[index] = questionUpdated;
      setQuestions({ ...questions, [tabName]: tabQuestions });
    } else {
      getQuestionsByLecturesId();
    }
  };

  useEffect(() => {
    if (selectedLectures.length && !initLectureSet && initLectureId) {
      setSelectedLectures([initLectureId]);
      setInitLectureSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLectures]);

  useEffect(() => {
    if (selectedCourses.length) {
      getLecturesByCourses();
    } else {
      setLectures([]);
      setSelectedLectures([]);
    }
  }, [selectedCourses, getLecturesByCourses]);

  useEffect(() => {
    if (selectedLectures.length) {
      getQuestionsByLecturesId();

      if (selectedLectures.length === 1) {
        const lecture = lectures.find((l: any) => l.id === selectedLectures[0]);
        trigger(EVENT_UPDATE_USER_INFO, { courseId: selectedCourses[0], lectureNum: lecture?.lectureNum });

        const allowQuestionsDuringLecture = lecture?.allowQuestionsDuringLecture;
        setDisabledInput(!allowQuestionsDuringLecture);
      } else {
        trigger(EVENT_UPDATE_USER_INFO, { courseId: undefined, lectureNum: undefined });
      }
    } else {
      setQuestions(initQuestions);
      trigger(EVENT_UPDATE_USER_INFO, { courseId: undefined, lectureNum: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLectures]);

  return {
    tabValue,
    handleTabChange,
    courses,
    lectures,
    sortBy,
    handleSortBy,
    selectedCourses,
    handleCoursesChange,
    handleLecturesChange,
    setSelectedCourses,
    selectedLectures,
    setSelectedLectures,
    callbackUpdateState,
    showFilter,
    toggleFilter,
    question,
    questions,
    handleSendQuestion,
    disabledInput,
    setQuestion,
  };
}
export type { MyQuestionsServiceType };

