import { Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import DataInterface from "../../../../interfaces/statistics/AdminStatisticsType";
import { DATE_FORMAT_STYLE_2 } from "../../../../utils/Utils";
import StatisticsAdminService, { StatisticsAdminServiceExport } from "./StatisticsAdminService";

const TypographyColumnTitle = ({ content }: any) => <Typography fontSize={12}>{content}</Typography>;
const TypographyColumnData = ({ content }: any) => <Typography fontSize={12}>{content}</Typography>;

export default function StatisticsAdmin() {
  const { data }: StatisticsAdminServiceExport = StatisticsAdminService();
  const baseClass = "statistics-admin";

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__subtext`}>{"Statistics admin"}</div>
      <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={1} columnSpacing={1} height="620px" overflow={"auto"} pr={1}>
        {data.map(({ lecture, creator, pseudonymCreated, newUsers, questions, answers, discussions }: DataInterface) => (
          <Grid container item xs={12} key={lecture.id}>
            <Card className={`${baseClass}__card`}>
              <Grid container item xs={12} flexDirection="row">
                <Grid container item xs={5} flexDirection="column" borderBottom={"1px solid white"} borderRight={"1px solid white"} alignItems={"center"}>
                  <TypographyColumnTitle content="Lecture" />
                </Grid>
                <Grid container item xs={4.5} flexDirection="column" borderBottom={"1px solid white"} borderRight={"1px solid white"} alignItems={"center"}>
                  <TypographyColumnTitle content="User" />
                </Grid>
                <Grid container item xs={2.5} flexDirection="column" borderBottom={"1px solid white"} alignItems={"center"}>
                  <TypographyColumnTitle content="Other" />
                </Grid>
                <Grid container item xs={5} flexDirection="row" p={1}>
                  <Grid container item xs={6} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnTitle content="Lecture id" />
                    <TypographyColumnTitle content="Lecture num" />
                    <TypographyColumnTitle content="Name" />
                    <TypographyColumnTitle content="Start time" />
                    <TypographyColumnTitle content="End time" />
                    <TypographyColumnTitle content="State" />
                    <TypographyColumnTitle content="Questions during lecture" />
                    <TypographyColumnTitle content="Discussions during lecture" />
                    <TypographyColumnTitle content="Created at" />
                    <TypographyColumnTitle content="Updated at" />
                  </Grid>
                  <Grid container item xs={6} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnData content={lecture.id} />
                    <TypographyColumnData content={lecture.lectureNum} />
                    <TypographyColumnData content={lecture.name} />
                    <TypographyColumnData content={moment(lecture.startTime).format(DATE_FORMAT_STYLE_2)} />
                    <TypographyColumnData content={moment(lecture.endTime).format(DATE_FORMAT_STYLE_2)} />
                    <TypographyColumnData content={lecture.state} />
                    <TypographyColumnData content={lecture.allowQuestionsDuringLecture.toString()} />
                    <TypographyColumnData content={lecture.allowDiscussionsDuringLecture.toString()} />
                    <TypographyColumnData content={moment(lecture.createdAt).format(DATE_FORMAT_STYLE_2)} />
                    <TypographyColumnData content={moment(lecture?.updatedAt).format(DATE_FORMAT_STYLE_2)} />
                  </Grid>
                </Grid>
                <Grid container item xs={4.5} flexDirection="row" p={1}>
                  <Grid container item xs={4} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnTitle content="Username" />
                    <TypographyColumnTitle content="First name" />
                    <TypographyColumnTitle content="Email" />
                    <TypographyColumnTitle content="University" />
                    <TypographyColumnTitle content="Created at" />
                    <TypographyColumnTitle content="Updated at" />
                    <TypographyColumnTitle content="Anon ID" />
                  </Grid>
                  <Grid container item xs={8} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnData content={creator.username} />
                    <TypographyColumnData content={creator.firstName} />
                    <TypographyColumnData content={creator.email} />
                    <TypographyColumnData content={creator.university} />
                    <TypographyColumnData content={moment(creator.createdAt).format(DATE_FORMAT_STYLE_2)} />
                    <TypographyColumnData content={moment(creator?.updatedAt).format(DATE_FORMAT_STYLE_2)} />
                    <TypographyColumnData content={creator.anonId} />
                  </Grid>
                </Grid>
                <Grid container item xs={2.5} flexDirection="row" p={1}>
                  <Grid container item xs={10} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnTitle content="Pseudonym created" />
                    <TypographyColumnTitle content="New users" />
                    <TypographyColumnTitle content="Questions" />
                    <TypographyColumnTitle content="Answers" />
                    <TypographyColumnTitle content="Discussions" />
                  </Grid>
                  <Grid container item xs={2} flexDirection="column" alignItems={"flex-start"}>
                    <TypographyColumnData content={pseudonymCreated} />
                    <TypographyColumnData content={newUsers} />
                    <TypographyColumnData content={questions} />
                    <TypographyColumnData content={answers} />
                    <TypographyColumnData content={discussions} />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
