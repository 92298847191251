import Colors from "../../../utils/Colors";

const LostIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.0333" height="50.0333" viewBox="0 0 63.233 63.233">
    <defs>
      <linearGradient id="linear-gradient-lost" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#f05e15" />
        <stop offset="1" stopColor={Colors.RED_SOLID} />
      </linearGradient>
    </defs>
    <g id="Group_113" data-name="Group 113" transform="translate(-2746.574 -5113.514)">
      <g id="Group_110" data-name="Group 110">
        <path id="Path_60" d="M2809.807 5145.13a31.616 31.616 0 1 1-31.617-31.616 31.618 31.618 0 0 1 31.617 31.616z" style={{ fill: "url(#linear-gradient-lost)" }} data-name="Path 60" />
      </g>
      <g id="Group_112" data-name="Group 112">
        <g id="Group_111" data-name="Group 111">
          <rect id="Rectangle_108" width="5.584" height="13.138" style={{ fill: "#231f20" }} data-name="Rectangle 108" rx="2.501" transform="translate(2765.889 5131.992)" />
          <rect id="Rectangle_109" width="5.584" height="13.138" style={{ fill: "#231f20" }} data-name="Rectangle 109" rx="2.501" transform="translate(2784.908 5131.992)" />
        </g>
        <ellipse id="Ellipse_27" cx="7.786" cy="8.494" style={{ fill: "#231f20" }} data-name="Ellipse 27" rx="7.786" ry="8.494" transform="translate(2770.404 5151.497)" />
      </g>
    </g>
  </svg>
);
export default LostIcon;
