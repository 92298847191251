import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import UserRole from "../../../../utils/UserRole";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import { AntTab, AntTabs, TabPanel, a11yProps } from "../../../shared/my-tabs-custom/MyTabsCustom";
import HowItWorkFAQ from "./HowItWorkFAQ";
import HowItWorkStudent from "./HowItWorkStudent";
import HowItWorkTeacher from "./HowItWorkTeacher";

export default function HowItWorks() {
  const { isTeacher, isStudent } = UserRole();
  const [tabValue, setTabValue] = useState<number>(isStudent ? EnumTabs.H_STD : isTeacher ? EnumTabs.H_TCH : EnumTabs.H_FAQ);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" className="custom-tabs-scrollbar">
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6">How it works</Typography>
      </Grid>
      <Grid item xs={12} sx={{ height: "75vh", overflowY: "auto", paddingRight: 2 }}>
        <TabPanel value={tabValue} index={EnumTabs.H_STD}>
          <HowItWorkStudent />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.H_TCH}>
          <HowItWorkTeacher />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.H_FAQ}>
          <HowItWorkFAQ />
        </TabPanel>
      </Grid>
      <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
        <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant tabs" TabIndicatorProps={{ style: { display: "none" } }}>
          <AntTab label="Student" {...a11yProps(EnumTabs.H_STD)} width={33.33} />
          <AntTab label="Teacher" {...a11yProps(EnumTabs.H_TCH)} width={33.33} />
          <AntTab label="FAQ " {...a11yProps(EnumTabs.H_FAQ)} width={33.33} />
        </AntTabs>
      </Grid>
    </Grid>
  );
}
