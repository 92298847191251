import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../context/AuthContext";
import { Teacher } from "../../../controller";
import MessageType from "../../../utils/MessageType";
import Routes from "../../../utils/Routes";
import OutlinedInputCustom from "../../shared/outlined-input-custom/OutlinedInputCustom";
import { INIT_VALUES, validationSchema } from "./config";

const baseClass = "ac-register-teacher";

function RegisterTeacher() {
  const history = useHistory();
  const { showMessage } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSubmit = ({ username, firstName, lastName, email, password, university }: any) => {
    setLoading(true);
    Teacher.createTeacher(username, firstName, lastName, email, password, university)
      .then(({ data }) => {
        if (data) {
          showMessage(data, MessageType.SUCCESS);
          goToLoginPage();
        }
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR))
      .finally(() => setLoading(false));
  };

  const goToLoginPage = () => history.push(Routes.TEACHER_LOGIN);

  return (
    <Formik initialValues={INIT_VALUES} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {({ handleBlur, values, touched, errors, handleChange }) => (
        <Form>
          <Grid container item xs={12} className={baseClass} justifyContent="center">
            <Grid container item xs={12} justifyContent="center" className={`${baseClass}__title`}>
              Create An Account
            </Grid>
            <Grid container item xs={12} className={`${baseClass}__subtitle`} justifyContent="center" mt={0.5} mb={4}>
              To register as a teacher, you will have to provide a valid school email. <br></br>Upon successful verification, you will be able to join the platform.
            </Grid>
            <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={6}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <OutlinedInputCustom
                  placeholder="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  aria-describedby="outlined-id-helper-text"
                  error={touched.firstName && Boolean(errors.firstName)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.firstName && Boolean(errors.firstName) && <label>{errors.firstName}</label>}</div>
                <OutlinedInputCustom
                  placeholder="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  aria-describedby="outlined-id-helper-text"
                  error={touched.lastName && Boolean(errors.lastName)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.lastName && errors.lastName && <label>{errors.lastName}</label>}</div>
                <OutlinedInputCustom
                  placeholder="Enter your school email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  aria-describedby="outlined-pass-helper-text"
                  error={touched.email && Boolean(errors.email)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.email && errors.email && <label>{errors.email}</label>}</div>
                <OutlinedInputCustom
                  placeholder="School Name"
                  name="university"
                  value={values.university}
                  onChange={handleChange}
                  aria-describedby="outlined-id-helper-text"
                  error={touched.university && Boolean(errors.university)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.university && errors.university && <label>{errors.university}</label>}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{ width: "50%" }}>
                <OutlinedInputCustom
                  placeholder="Username"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  aria-describedby="outlined-pass-helper-text"
                  error={touched.username && Boolean(errors.username)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.username && errors.username && <label>{errors.username}</label>}</div>
                <OutlinedInputCustom
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  aria-describedby="outlined-pass-helper-text"
                  type="password"
                  error={touched.password && Boolean(errors.password)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.password && errors.password && <label>{errors.password}</label>}</div>
                <OutlinedInputCustom
                  placeholder="Confirm password"
                  name="passwordConfirm"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  aria-describedby="outlined-pass-helper-text"
                  type="password"
                  error={touched.password && Boolean(errors.passwordConfirm)}
                  onBlur={handleBlur}
                  fullWidth
                  className="ac-text-field"
                />
                <div className="error-text">{touched.passwordConfirm && errors.passwordConfirm && <label>{errors.passwordConfirm}</label>}</div>
              </Grid>
            </Grid>
            <LoadingButton loading={loading} disabled={loading} loadingPosition="start" type="submit" variant="contained" className="v-btn ac-color-white btn-send">
              Send
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default RegisterTeacher;
